import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Athletes = EgretLoadable({
  loader: () => import("./Athletes")
});

const AthleteDetails = EgretLoadable({
   loader: () => import("../profile/AthleteDetails")
});

const athletesRoutes = [
  // [authRoles.moderator, authRoles.mainCoach, authRoles.coach, authRoles.parent]
  {
    path: "/athlete/list",
    exact: true,
    component: Athletes,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/athlete/:id",
    exact: true,
    component: AthleteDetails,
    auth: userUtilsService.getAllRoles()
  }
];

export default athletesRoutes;
