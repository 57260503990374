import trainingService from "../../shared/services/trainingService";
import i18n from "../../../i18n";
import {ETrainingResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_TRAINING_LIST = "GET_TRAINING_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const TRAINING_LOADING = "TRAINING_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getTrainings(start, end, sportId, teamId, athleteId) {
    return dispatch => {
        return trainingService
            .getTrainings(start, end, sportId, teamId, athleteId)
            .then(( data ) => {
                dispatch({
                    type: GET_TRAINING_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateTrainingProperties(trainingProperties) {
    return dispatch => {
        dispatch({
            type: TRAINING_LOADING
        });
        return trainingService
            .updateTrainingProperties(trainingProperties)
            .then(res => {
                if (trainingProperties.canceled === undefined) {
                    dispatch({
                        type: SAVE_SUCCESS,
                        data: i18n.t(ETrainingResponseCodes[res.message])
                    });
                } else {
                    dispatch({
                        type: SAVE_SUCCESS,
                        data: i18n.t('calendar.actions.cancel')
                    });
                }

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETrainingResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function updateUpcomingTrainingsProperties(trainingProperties) {
    return dispatch => {
        dispatch({
            type: TRAINING_LOADING
        });
        return trainingService
            .updateUpcomingTrainingsProperties(trainingProperties)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ETrainingResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETrainingResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseTrainingMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}

export function initializeTrainingList() {
    return dispatch => {
        dispatch({
            type: GET_TRAINING_LIST,
            data: []
        });
    };
}
