import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class parentRepository {

    /**
     * Get parents
     * @returns {Promise}
     * @param query
     */
    static getParents(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/parent/${helperService.serialize(query)}`
        );
    }

    /**
     * Get parents count
     * @returns {Promise}
     */
    static getParentsCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/parent/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Get parent by id
     * @returns {Promise}
     * @param id
     */
    static getParentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/parent/${id}`
        );
    }

    /**
     * Get parent by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getParentByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/parent/access-user/${id}`
        );
    }

    /**
     * Update parent
     * @returns {Promise}
     */
    static updateParent(parent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/parent/`, {
            method: 'PUT',
            body: JSON.stringify(parent)
        });
    }

    /**
     * Delete parent
     * @returns {Promise}
     */
    static deleteParent(parent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/parent/${parent.id}`, {
            method: 'DELETE'
        });
    }

    /**
     * Update parent properties
     * @returns {Promise}
     */
    static updateParentProperties(parentRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/parent/`, {
            method: 'PATCH',
            body: JSON.stringify(parentRequest)
        });
    }

}
