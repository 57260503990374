import eventExpensesService from "../../shared/services/eventExpensesService";
import eventExpensesServiceMobile from "../../shared/services/eventExpensesServiceMobile";

export const GET_EVENT_EXPENSES_LIST = "GET_EVENT_EXPENSES_LIST";
export const GET_EVENT_EXPENSES_LIST_TIMESTAMP = "GET_EVENT_EXPENSES_LIST_TIMESTAMP";
export const GET_EVENT_EXPENSES_COUNT = "GET_EVENT_EXPENSES_COUNT";
export const GET_EVENT_EXPENSES_COUNT_TIMESTAMP = "GET_EVENT_EXPENSES_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const EVENT_EXPENSES_LOADING = "EVENT_EXPENSES_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getEventExpenses(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EVENT_EXPENSES_LIST_TIMESTAMP,
            data: currentTime
        });
        return eventExpensesServiceMobile
            .getEventExpenses(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EVENT_EXPENSES_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getEventExpensesCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EVENT_EXPENSES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return eventExpensesService
            .getEventExpensesCount(countMap)
            .then(( data ) => {
                dispatch({
                    type: GET_EVENT_EXPENSES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
