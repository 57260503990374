import coachService from "../../shared/services/coachService";
import i18n from "../../../i18n";
import {ECoachResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_COACH_LIST = "GET_COACH_LIST";
export const GET_COACH_LIST_TIMESTAMP = "GET_COACH_LIST_TIMESTAMP";
export const GET_COACHES_COUNT = "GET_COACHES_COUNT";
export const GET_COACHES_COUNT_TIMESTAMP = "GET_COACHES_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const COACH_LOADING = "COACH_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getCoaches(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_COACH_LIST_TIMESTAMP,
            data: currentTime
        });
        return coachService
            .getCoaches(query)
            .then(( data ) => {
                dispatch({
                    type: GET_COACH_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getCoachesCount (countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_COACHES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return coachService
            .getCoachesCount(countMap)
            .then((data) => {
                dispatch({
                    type: GET_COACHES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
        });
    }
}

export function updateCoach(coach) {
    return dispatch => {
        dispatch({
            type: COACH_LOADING
        });
        return coachService
            .updateCoach(coach)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ECoachResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ECoachResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseCoachMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
