import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import Teams from "../team/Teams";


const Coaches = EgretLoadable({
  loader: () => import("./Coaches")
});

const CoachDetails = EgretLoadable({
   loader: () => import("../profile/CoachDetails")
});

const coachRoutes = [
  // [authRoles.moderator, authRoles.mainCoach, authRoles.coach]
  {
    path: "/coach/list",
    exact: true,
    component: Coaches,
    auth: userUtilsService.getClubStaffRoles()
  },
  {
    path: "/coach/:id",
    exact: true,
    component: CoachDetails,
    auth: userUtilsService.getAllRoles()
  },
  {
    path: "/team/list",
    exact: true,
    component: Teams,
    auth: userUtilsService.getAllClubRoles()
  }];

export default coachRoutes;
