import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import {SessionService as sessionService} from "../shared/services/sessionService";
import userService from "../shared/services/userService";

class Auth extends Component {
  state = {
    userPropertiesReceived: false
  };

  constructor(props) {
    super(props);

    const token = sessionService.getToken();
    const {userProperties} = sessionService.decodeToken(token);
    // console.log("userProperties: ", userProperties)
    userService.setUserDetails(userProperties).then(updatedUserProperties => {
      this.props.setUserData(updatedUserProperties);
      this.setState({
        userPropertiesReceived: true
      })
    });
    //this.checkJwtAuth();
  }

  /*checkJwtAuth = () => {
    jwtAuthService.loginWithToken().then(user => {
      this.props.setUserData(user);
    });
  };*/

  render() {
    const { children } = this.props;
    return this.state.userPropertiesReceived && <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
    setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
    mapStateToProps,
    { setUserData }
)(Auth);
