import parentService from "../../shared/services/parentService";
import i18n from "../../../i18n";
import {EParentResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_PARENT_LIST = "GET_PARENT_LIST";
export const GET_PARENT_LIST_TIMESTAMP = "GET_PARENT_LIST_TIMESTAMP";
export const GET_PARENTS_COUNT = "GET_PARENTS_COUNT";
export const GET_PARENTS_COUNT_TIMESTAMP = "GET_PARENTS_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const PARENT_LOADING = "PARENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getParents(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_PARENT_LIST_TIMESTAMP,
            data: currentTime
        });
        return parentService
            .getParents(query)
            .then(( data ) => {
                dispatch({
                    type: GET_PARENT_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getParentsCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_PARENTS_COUNT_TIMESTAMP,
            data: currentTime
        });
        return parentService
            .getParentsCount(countMap)
            .then( (data) => {
                dispatch({
                    type: GET_PARENTS_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateParent(parent) {
    return dispatch => {
        dispatch({
            type: PARENT_LOADING
        });
        return parentService
            .updateParent(parent)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EParentResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EParentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseParentMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
