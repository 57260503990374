import {
    SEND_FEEDBACK, EMAIL_NOT_SENT, EMAIL_SENT
} from "../actions/FeedbackActions";

const initialState = {
    loading: false,
    success: null,
    error: null
};

const FeedbackReducer = function(state = initialState, action) {
    switch (action.type) {
        case SEND_FEEDBACK: {
            return {
                ...state,
                loading: true
            };
        }
        case EMAIL_SENT: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case EMAIL_NOT_SENT: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default FeedbackReducer;
