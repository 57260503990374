import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class sportRepositoryMobile {

    /**
     * Get sports by club id
     * @returns {Promise}
     * @param clubId
     */
    static getSportsByClubId(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/sport/club?clubId=${clubId}`
        );
    }

}
