import Auditable from "../core/Auditable";

class TrainingPlan extends Auditable {
    constructor(data) {
        super(data);
        this.id = data.id;
        this.longName = data.longName;
        this.shortName = data.shortName;
        this.description = data.description;
        this.uom = data.uom;
        this.refValue = data.refValue;
        this.clubId = data.clubId;
        this.creatorRole = data.creatorRole;
        this.orderBestResult = data.orderBestResult;
    }
}

export default TrainingPlan;
