import {
  ATHLETE_OBSERVATION_LOADING,
  ERASE_MESSAGE, GET_ATHLETE_OBSERVATION_LIST, GET_UNREAD_ATHLETE_OBSERVATION_COUNT, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/AthleteObservationActions";

const initialState = {
  success: null,
  error: null,
  athleteObservationList: [],
  observationType: null,
  unreadObservations: 0
};

const AthleteObservationReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATHLETE_OBSERVATION_LIST: {
      return {
        ...state,
        athleteObservationList: action.data,
        observationType: action.observationType
      };
    }
    case GET_UNREAD_ATHLETE_OBSERVATION_COUNT: {
      return {
        ...state,
        unreadObservations: action.data,
      };
    }
    case ATHLETE_OBSERVATION_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default AthleteObservationReducer;
