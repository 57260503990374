import sportService from "../../shared/services/sportService";
import history from "../../../history";
import i18n from "../../../i18n";
import {ESportResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_SPORT_LIST = "GET_SPORT_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const SPORT_LOADING = "SPORT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getSports(query) {
    return dispatch => {
        return sportService
            .getSports(query)
            .then(( data ) => {
                dispatch({
                    type: GET_SPORT_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateSport(sport) {
    return dispatch => {
        dispatch({
            type: SPORT_LOADING
        });
        return sportService
            .updateSport(sport)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ESportResponseCodes[res.message])
                });

                history.push({pathname: "/sport/list"});

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ESportResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addSport(sport) {
    return dispatch => {
        dispatch({
            type: SPORT_LOADING
        });
        return sportService
            .addSport(sport)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ESportResponseCodes[res.message])
                });

                history.push({pathname: "/sport/list"});

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ESportResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
