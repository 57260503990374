import competitionEventService from "../../shared/services/competitionEventService";
import i18n from "../../../i18n";
import {ECompetitionEventResponseCodes} from "../../shared/enums/ERespondeCodes";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const COMPETITION_EVENT_LOADING = "COMPETITION_EVENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function updateCompetitionEvent(competitionEvent) {
    return dispatch => {
        dispatch({
            type: COMPETITION_EVENT_LOADING
        });
        return competitionEventService
            .updateCompetitionEvent(competitionEvent)
            .then(res => {
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ECompetitionEventResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addCompetitionEvent(competitionEvent) {
    return dispatch => {
        dispatch({
            type: COMPETITION_EVENT_LOADING
        });

        if (competitionEvent.individual) {
            if (!competitionEvent.athlete){
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t('calendar.error.athlete.s')
                });
                return Promise.reject();
            }
        } else {
            if (!competitionEvent.team){
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t('calendar.error.team.s')
                });
                return Promise.reject();
            }
        }

        return competitionEventService
            .addCompetitionEvent(competitionEvent)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ECompetitionEventResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                if (error.status === 400 && error.errors && error.errors.length !== 0 ) {
                    dispatch({
                        type: SAVE_ERROR,
                        data: error.errors[0]['defaultMessage']
                    });
                } else {
                    dispatch({
                        type: SAVE_ERROR,
                        data: i18n.t(ECompetitionEventResponseCodes[error.message])
                    });
                }
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
