import React, { Component } from "react";
import {
    Card,
    Grid,
    Button,
    withStyles,
    IconButton,
    Icon,
    MenuItem,
    FormControlLabel,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {EClubStatus} from "../../shared/enums/EClubStatus";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { ro, enGB } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {countries} from "../../shared/templates/Country"
import ReCAPTCHA from "react-google-recaptcha";
import publicService from "../../shared/services/publicService";
import {addClub, eraseMessage, addModifyLogo} from "../../redux/actions/PublicActions";
import history from "../../../history";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {helperService} from "../../shared/services/helperService";
import {ELanguage} from "../../shared/enums/ELanguage";
import ReactFlagsSelect from 'react-flags-select';

const styles = () => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    logo: {
        height: "250px"
    }
});

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

class ClubAdd extends Component {

    state = {
        currentClub: {
            status: 'DEMO',
            address: {
                country: 'Romania'
            },
            facebook: false,
            newsletter: false,
            linkedin: false,
            friendRecommendation: false,
            instagram: false,
            otherSource: false,
            emailLanguage: ''
        },
        sports: [],
        president: null,
        payment: null,
        selectedFile: null,
        clubLogo: null,
        expanded: 'panel',
        currentUser: null,
        isLogoLoading: false,
        selectedSports: [],
        disabledSave: true,
        captcha: false,
        conf: false,
        terms: false,
        loaded: false,
        expired: false,
        newsletter: false,
        linkedin: false,
        friendRecommendation: false,
        instagram: false,
        otherSource: false,
        sources: '',
        selectedLanguage: ''
    };

    constructor(props, ...args) {
        super(props, ...args);
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ loaded: true });
        publicService.getSports().then(res => {
            this.setState({
                ...this.state,
                sports: res,
                selectedLanguage: this.props.i18n.language === 'en' ? 'GB' : this.props.i18n.language.toUpperCase()
            });
        });
    }

    enableSave() {
        if(this.state.captcha && this.state.currentClub.code && this.state.currentClub.name && this.state.currentClub.cui &&
            this.state.currentClub.address.city && this.state.currentClub.contactInfo && this.state.currentClub.contactInfo.firstName &&
            this.state.currentClub.contactInfo.lastName && this.state.currentClub.contactInfo.email &&
            this.state.currentClub.contactInfo.phone &&
            this.state.currentClub.code.replace(/\s/g, '') &&
            this.state.currentClub.name.replace(/\s/g, '') &&
            this.state.currentClub.cui.replace(/\s/g, '') &&
            this.state.currentClub.cis.replace(/\s/g, '') &&
            this.state.currentClub.address.city.replace(/\s/g, '') &&
            this.state.currentClub.contactInfo.firstName.replace(/\s/g, '') &&
            this.state.currentClub.contactInfo.lastName.replace(/\s/g, '') &&
            this.state.currentClub.contactInfo.email.replace(/\s/g, '') &&
            this.state.currentClub.contactInfo.phone.replace(/\s/g, '') &&
            this.state.terms &&
            this.state.conf &&
            this.state.currentClub.emailLanguage !== '') {

            this.setState({
                disabledSave: false
            });
        } else {
            this.setState({
                disabledSave: true
            });
        }
    }

    handleValidationErrors = errors => {
        for (let error of errors) {
            if (error.props.name === 'subscriptionType') {
                this.setState({
                    expanded: 'panel'
                });
            }
        }
        for (let error of errors) {
            if (error.props.name === 'phone') {
                this.setState({
                    expanded: 'panel1'
                });
                break;
            } else if (error.props.name === 'city') {
                this.setState({
                    expanded: 'panel2'
                });
            }
        }
        for (let error of errors) {
            document.getElementById(error.props.name).focus();
            break;
        }
    };

    onFileUpdate  = (selectedFile) =>  {
        this.setState({
            ...this.state,
            selectedFile: selectedFile
        })
    };

    handleExpandedPanelChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    handleSportsChange = event => {
        const selectedValues = event.target.value;
        this.setState({
            ...this.state,
            selectedSports: selectedValues
        });
        this.enableSave();
    };

    handleSubmit = () => {
        const sports = this.state.sports.filter(sport => this.state.selectedSports.includes(sport.id));
        this.state.currentClub.sports = sports;

        this.props.addClub(this.state.currentClub).then(() => {
            history.push({pathname: "/session/signin"});
        });
    };

    handleChange = event => {
        event.persist();
        this.setState(prevState => {
            event.target.value = event.target.name === "name" || event.target.name === "code"?  helperService.upperCase(event.target.value) : event.target.value;

            const currentClub = {...prevState.currentClub, [event.target.name]: event.target.value};
            return { currentClub };
        });
        this.enableSave();
    };

    handleContactInfoChange = event => {
        event.persist();
        this.setState(prevState => {
            event.target.value = event.target.name === "lastName" ?  helperService.upperCase(event.target.value) : event.target.value;

            const currentClub = {...prevState.currentClub,
                contactInfo: {...prevState.currentClub.contactInfo, [event.target.name]: event.target.value}};
            return { currentClub };
        });
        this.enableSave();
    };

    handleAddressChange = event => {
        event.persist();
        this.setState(prevState => {
            let currentClub = {...prevState.currentClub,
                address: {...prevState.currentClub.address, [event.target.name]: event.target.value}};
            return { currentClub };
        });
        this.enableSave();
    };

    handleFriendRecommendationTextFieldChange = event => {
        event.persist();
        let currentClub = this.state.currentClub;
        currentClub.friends = event.target.value;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleOtherSourcesChange = event => {
        event.persist();
        let currentClub = this.state.currentClub;
        currentClub.sources = event.target.value;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleEmailLanguageChange = event => {
        event.persist();
        let currentClub = this.state.currentClub;
        currentClub.emailLanguage = event.target.value;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
        this.enableSave();
    };

    handleNewsletterChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.newsletter = !this.state.currentClub.newsletter;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleLinkedinChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.linkedin = !this.state.currentClub.linkedin;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleFriendRecommendationChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.friendRecommendation = !this.state.currentClub.friendRecommendation;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleFacebookChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.facebook = !this.state.currentClub.facebook;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleInstagramChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.instagram = !this.state.currentClub.instagram;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleOtherSourceChange = event => {
        event.persist();
        const currentClub = this.state.currentClub;
        currentClub.otherSource = !this.state.currentClub.otherSource;
        this.setState({
            ...this.state,
            currentClub: currentClub
        });
    };

    handleTerms = event => {
        event.persist();
        const terms = this.state.terms;
        this.setState({
            ...this.state,
            terms: !terms
        });
        this.enableSave();
    };

    handleConf = event => {
        event.persist();
        const conf = this.state.conf;
        this.setState({
            ...this.state,
            conf: !conf
        });
        this.enableSave();
    };

    handleCaptchaChange = value => {
        let captcha = this.state.captcha;
        this.setState({
            ...this.state,
            captcha: !captcha,
        });
        this.enableSave();
    };

    asyncScriptOnLoad = () => {

    };

    handleLanguageClick = (lng) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lng).then();
    };

    renderLanguage = () => {
        return (
            <ReactFlagsSelect
                countries={["RO", "GB"]}
                showOptionLabel={false}
                showSelectedLabel={false}
                defaultCountry="RO"
                selected={this.state.selectedLanguage}
                selectedSize={18}
                optionsSize={18}
                onSelect={this.onSelectFlag}
                selectButtonClassName="language"
            />
        );

    };

    onSelectFlag = (countryCode) => {
        switch(countryCode) {
            case 'RO':
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
            case 'GB':
                this.handleLanguageClick('en');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'GB'
                });
                break;
            default:
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
        }
    };

    render() {

        let { classes, club, t, i18n } = this.props;

        let {
            code,
            name,
            description,
            cui,
            cis,
            contactInfo,
            address,
            friends,
            sources,
            facebook,
            newsletter,
            linkedin,
            friendRecommendation,
            instagram,
            otherSource,
            emailLanguage
        } = this.state.currentClub;

        const {expanded} = this.state;

        return (
            <div className="m-sm-30">
                <Card elevation={6}>
                    <div className="invoice-viewer py-16">
                        <div className="viewer_actions px-16 mb-20 flex flex-middle flex-space-between">
                            <IconButton onClick ={() => this.props.history.goBack()}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                            <div style={{float: 'right !important'}}>
                                {this.renderLanguage()}
                            </div>
                        </div>

                        <div id="print-area">
                            <div className="viewer__order-info px-16 mb-32">

                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => this.handleValidationErrors(errors)}
                                >

                                        <div className="py-20">
                                            <h4 className="mb-16">{t('club.details')}</h4>

                                                <div style={{display: "flex"}}>
                                                    {(this.state.currentClub.name === undefined ||
                                                        this.state.currentClub.name.replace(/\s/g, '') === '' ) &&
                                                    (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label={t("club.name")}
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="name"
                                                        value={helperService.upperCase(name) || ''}
                                                        validators={["required"]}
                                                        errorMessages={[t('validation.error.required')]}
                                                        id="name"
                                                        InputProps={{ className: "ui-state-disabled" }}
                                                    />
                                                </div>

                                                <div style={{display: "flex"}}>
                                                    {(this.state.currentClub.code === undefined ||
                                                        this.state.currentClub.code.replace(/\s/g, '') === '' ) &&
                                                        (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label={t("club.code")}
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="code"
                                                        value={helperService.upperCase(code) || ''}
                                                        validators={[
                                                            "required",
                                                            "minStringLength: 4",
                                                            "maxStringLength: 25",
                                                        ]}
                                                        errorMessages={[t('validation.error.required')]}
                                                        id="code"
                                                        InputProps={{ className: "ui-state-disabled" }}
                                                    />
                                                </div>

                                            <div style={{display: "flex"}}>
                                                {this.state.selectedSports.length === 0 &&
                                                (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                <FormControl className="mb-16 w-50">
                                                    <InputLabel id="multiple-checkbox-label">{t("club.sport")}</InputLabel>
                                                    <Select
                                                        required
                                                        labelId="multiple-checkbox-label"
                                                        multiple
                                                        value={this.state.selectedSports}
                                                        onChange={this.handleSportsChange}
                                                        input={<Input/>}
                                                        renderValue={(selected) =>
                                                            selected.map((sportId) => {
                                                                const sport = this.state.sports.find(sport => sport.id === sportId);
                                                                if (sport){
                                                                    return sport.name[i18n.language];
                                                                } else {
                                                                    return '';
                                                                }
                                                            }).join(', ')
                                                        }
                                                        id="sport"
                                                        className={"ui-state-disabled"}
                                                    >
                                                        {this.state.sports.map((sport, index) => (
                                                            <MenuItem key={index} value={sport.id}>
                                                                <Checkbox
                                                                    checked={this.state.selectedSports.indexOf(sport.id) > -1}/>
                                                                <ListItemText primary={sport.name[i18n.language]}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={t("form.status")}
                                                select
                                                name="status"
                                                value={EClubStatus.DEMO}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled= {true}
                                            >
                                                {Object.entries(EClubStatus).map(([key,value],i) => (
                                                    <MenuItem key={key} value={value}>
                                                        <div className="ui-state-disabled">
                                                            {value}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </TextValidator>

                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={t("form.description")}
                                                onChange={this.handleChange}
                                                type="text"
                                                name="description"
                                                value={description || ''}
                                                InputProps={{ className: "ui-state-disabled" }}
                                            />

                                            <MuiPickersUtilsProvider locale={i18n.language === 'en'? enGB : ro} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className="mb-16 w-100"
                                                    margin="none"
                                                    id="mui-pickers-date"
                                                    label={<Typography className={"ui-state-disabled"}>{t("club.escDate")}</Typography>}
                                                    inputVariant="standard"
                                                    type="text"
                                                    autoOk={true}
                                                    value={new Date()}
                                                    onChange={this.handleDateChange}
                                                    format="dd-MM-yyyy"
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                    disabled= {true}
                                                    InputProps={{ className: "ui-state-disabled" }}
                                                />
                                            </MuiPickersUtilsProvider>

                                            <div style={{display: "flex"}}>
                                                {(this.state.currentClub.cui === undefined ||
                                                    this.state.currentClub.cui.replace(/\s/g, '') === '' ) &&
                                                    (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label={t("club.vatNo")}
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="cui"
                                                        value={cui || ''}
                                                        InputProps={{ className: "ui-state-disabled" }}
                                                        validators={[
                                                            "minStringLength: 4",
                                                            'matchRegexp:^[0-9]*$'
                                                        ]}
                                                        errorMessages={[
                                                            t('validation.error.minLength',{min:4}),
                                                            t('validation.error.number')
                                                        ]}
                                                    />
                                            </div>

                                            <div style={{display: "flex"}}>
                                                {(this.state.currentClub.cis === undefined ||
                                                    this.state.currentClub.cis.replace(/\s/g, '') === '' ) &&
                                                (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                <TextValidator
                                                    className="mb-16 w-100"
                                                    label={t("club.sportIN")}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                    name="cis"
                                                    value={cis || ''}
                                                    InputProps={{ className: "ui-state-disabled" }}
                                                    validators={[
                                                        "minStringLength: 4"
                                                    ]}
                                                    errorMessages={[
                                                        t('validation.error.minLength',{min:4})
                                                    ]}
                                                />
                                            </div>

                                        </div>

                                    <div className="py-20">
                                        <Accordion expanded={expanded === 'panel1' || expanded === 'panel'} onChange={this.handleExpandedPanelChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{t('form.contactInformation')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={6}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.contactInfo === undefined ||
                                                                this.state.currentClub.contactInfo.lastName === undefined ||
                                                                this.state.currentClub.contactInfo.lastName === null ||
                                                                this.state.currentClub.contactInfo.lastName.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("president.lastName")}
                                                                onChange={this.handleContactInfoChange}
                                                                type="text"
                                                                name="lastName"
                                                                value={contactInfo ? (helperService.upperCase(contactInfo.lastName) || '') : ''}
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                            />
                                                        </div>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.contactInfo === undefined ||
                                                                this.state.currentClub.contactInfo.phone === undefined ||
                                                                this.state.currentClub.contactInfo.phone === null ||
                                                                this.state.currentClub.contactInfo.phone.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("form.phone")}
                                                                onChange={this.handleContactInfoChange}
                                                                type="text"
                                                                name="phone"
                                                                value={contactInfo ? (contactInfo.phone || '') : ''}
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                                validators={[
                                                                    "minStringLength: 4",
                                                                    "maxStringLength: 15",
                                                                    'matchRegexp:^[0-9]*$'
                                                                ]}
                                                                errorMessages={[ t('validation.error.incorrectLength',{min:4,max:15}),
                                                                    t('validation.error.number')
                                                                ]}
                                                            />
                                                        </div>
                                                        <TextValidator
                                                            className="mb-16 w-100"
                                                            label={t("form.webSite")}
                                                            onChange={this.handleContactInfoChange}
                                                            type="text"
                                                            name="webSite"
                                                            value={contactInfo ? (contactInfo.webSite || '') : ''}
                                                            InputProps={{ className: "ui-state-disabled" }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.contactInfo === undefined ||
                                                                this.state.currentClub.contactInfo.firstName === undefined ||
                                                                this.state.currentClub.contactInfo.firstName === null ||
                                                                this.state.currentClub.contactInfo.firstName.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("president.firstName")}
                                                                onChange={this.handleContactInfoChange}
                                                                type="text"
                                                                name="firstName"
                                                                value={contactInfo ? (contactInfo.firstName || '') : ''}
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                            />
                                                        </div>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.contactInfo === undefined ||
                                                                this.state.currentClub.contactInfo.email === undefined ||
                                                                this.state.currentClub.contactInfo.email === null ||
                                                                this.state.currentClub.contactInfo.email.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={contactInfo? contactInfo.email ? t("form.email") : t("president.email") : t("president.email")}
                                                                onChange={this.handleContactInfoChange}
                                                                type="text"
                                                                name="email"
                                                                value={contactInfo ? (contactInfo.email || '') : ''}
                                                                validators={["isEmail"]}
                                                                errorMessages={[
                                                                    t('validation.error.email.invalid')
                                                                ]}
                                                                id="email"
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                            />
                                                        </div>
                                                        <div style={{display: "flex"}}>
                                                        {this.state.currentClub.emailLanguage === '' &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("form.emailLanguage")}
                                                                select
                                                                name="emailLanguage"
                                                                value={emailLanguage || ''}
                                                                onChange={this.handleEmailLanguageChange}
                                                                fullWidth
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                            >
                                                                {Object.entries(ELanguage).map(([key,value]) => (
                                                                    <MenuItem key={key} value={value}>
                                                                        {value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextValidator>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    <div className="py-20">
                                        <Accordion expanded={expanded === 'panel2' || expanded === 'panel'} onChange={this.handleExpandedPanelChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{t('form.addressInformation')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={6}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.address.street === undefined ||
                                                                this.state.currentClub.address.street === null ||
                                                                this.state.currentClub.address.street.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("form.street")}
                                                                onChange={this.handleAddressChange}
                                                                type="text"
                                                                name="street"
                                                                value={address ? (address.street || '') : ''}
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                                validators={["required"]}
                                                                errorMessages={[t('validation.error.required')]}
                                                            />
                                                        </div>

                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.address.city === undefined ||
                                                                this.state.currentClub.address.city === null ||
                                                                this.state.currentClub.address.city.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("form.city")}
                                                                onChange={this.handleAddressChange}
                                                                type="text"
                                                                name="city"
                                                                value={address ? (address.city || '') : ''}
                                                                validators={["required"]}
                                                                errorMessages={[t('validation.error.required')]}
                                                                id="city"
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                            />
                                                        </div>
                                                        <div style={{display: "flex"}}>
                                                            {(this.state.currentClub.address.region === undefined ||
                                                                this.state.currentClub.address.region === null ||
                                                                this.state.currentClub.address.region.replace(/\s/g, '') === '') &&
                                                            (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)}
                                                            <TextValidator
                                                                className="mb-16 w-100"
                                                                label={t("form.region")}
                                                                onChange={this.handleAddressChange}
                                                                type="text"
                                                                name="region"
                                                                value={address ? (address.region || '') : ''}
                                                                InputProps={{ className: "ui-state-disabled" }}
                                                                validators={["required"]}
                                                                errorMessages={[t('validation.error.required')]}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <TextValidator
                                                            className="mb-16 w-100"
                                                            label={t("form.country")}
                                                            select
                                                            name="country"
                                                            value={address ? (address.country || 'Romania') : 'Romania'}
                                                            onChange={this.handleAddressChange}
                                                            fullWidth
                                                            validators={["required"]}
                                                            errorMessages={[t('validation.error.required')]}
                                                            id="country"
                                                        >
                                                            {countries.map(country => (
                                                                <MenuItem key={country.code} value={country.name}>
                                                                    <div className={"ui-state-disabled"}>
                                                                        {country.name}
                                                                    </div>
                                                                </MenuItem>
                                                            ))}
                                                        </TextValidator>
                                                        <TextValidator
                                                            className="mb-16 w-100"
                                                            label={t("form.zipCode")}
                                                            onChange={this.handleAddressChange}
                                                            type="text"
                                                            name="zip"
                                                            value={address ? (address.zip  || '') : ''}
                                                            validators={[
                                                                "matchRegexp:^[0-9]\\d*$"
                                                            ]}
                                                            errorMessages={[t('validation.error.number')]}
                                                            id="zip"
                                                            InputProps={{ className: "ui-state-disabled" }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    <div className="py-20">
                                        <Accordion expanded={expanded === 'panel3' || expanded === 'panel'} onChange={this.handleExpandedPanelChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{t('form.feedbackInformation')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={6}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <FormControlLabel
                                                            name="newsletter"
                                                            onChange={this.handleNewsletterChange}
                                                            control={<Checkbox disabled = {false}/>}
                                                            checked={newsletter}
                                                            label={t('form.newsletter')}
                                                        />

                                                        <div>
                                                            <FormControlLabel
                                                                name="linkedin"
                                                                onChange={this.handleLinkedinChange}
                                                                control={<Checkbox disabled = {false}/>}
                                                                checked={linkedin}
                                                                label={t('form.linkedin')}
                                                            />
                                                        </div>

                                                        <div style={{display: "flex"}}>
                                                            <FormControlLabel
                                                                name="friendRecommendation"
                                                                onChange={this.handleFriendRecommendationChange}
                                                                control={<Checkbox disabled = {false}/>}
                                                                checked={friendRecommendation}
                                                                label={t('form.friendRecommendation')}
                                                            />
                                                            {friendRecommendation && (
                                                                <TextValidator
                                                                    className="mb-16 w-100"
                                                                    label={t("form.name")}
                                                                    onChange={this.handleFriendRecommendationTextFieldChange}
                                                                    type="text"
                                                                    name="friends"
                                                                    value={friends || ''}
                                                                    // validators={["required"]}
                                                                    // errorMessages={[t('validation.error.required')]}
                                                                    id="friends"
                                                                    InputProps={{ className: "ui-state-disabled" }}
                                                                />
                                                            )}
                                                        </div>

                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <FormControlLabel
                                                            name="facebook"
                                                            onChange={this.handleFacebookChange}
                                                            control={<Checkbox disabled = {false}/>}
                                                            checked={facebook}
                                                            label={t('form.facebook')}
                                                        />

                                                        <div>
                                                            <FormControlLabel
                                                                name="instagram"
                                                                onChange={this.handleInstagramChange}
                                                                control={<Checkbox disabled = {false}/>}
                                                                checked={instagram}
                                                                label={t('form.instagram')}
                                                            />
                                                        </div>

                                                        <div style={{display: "flex"}}>
                                                            <FormControlLabel
                                                                name="otherSource"
                                                                onChange={this.handleOtherSourceChange}
                                                                control={<Checkbox disabled = {false}/>}
                                                                checked={otherSource}
                                                                label={t('form.otherSource')}
                                                            />
                                                            {otherSource && (
                                                                <TextValidator
                                                                    className="mb-16 w-100"
                                                                    label={t("form.source")}
                                                                    onChange={this.handleOtherSourcesChange}
                                                                    type="text"
                                                                    name="otherSource"
                                                                    value={sources || ''}
                                                                    validators={["required"]}
                                                                    errorMessages={[t('validation.error.required')]}
                                                                    id="city"
                                                                    InputProps={{ className: "ui-state-disabled" }}
                                                                />
                                                            )}
                                                        </div>

                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    <Grid container spacing={4}>
                                            <Grid item lg={3} md={3} sm={5} xs={12}>
                                                <Grid>
                                                    {!this.state.terms &&
                                                        (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)
                                                    }
                                                    <FormControlLabel
                                                        name="terms"
                                                        onChange={this.handleTerms}
                                                        control={<Checkbox disabled = {false}/>}
                                                        checked={this.state.terms}
                                                        label=""/>
                                                    <a
                                                        href={"/session/terms/" + i18n.language}
                                                        target="_blank"
                                                        style={{'color':'#941bcc'}}>
                                                        {t("club.termsConditions")}
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        <Grid item lg={9} md={9} sm={7} xs={12}>
                                            <Grid>
                                                {!this.state.conf &&
                                                    (<label style={{paddingRight: "10px", color: "red", fontSize: "200%"}}>!</label>)
                                                }
                                                <FormControlLabel
                                                    name="conf"
                                                    onChange={this.handleConf}
                                                    control={<Checkbox disabled = {false}/>}
                                                    checked={this.state.conf}
                                                    label=""/>
                                                <a
                                                    href={"/session/privacy/" + i18n.language}
                                                    style={{'color':'#941bcc'}}
                                                    target="_blank"
                                                >
                                                    {t("club.privacyPolicy")}
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <div style={{"display": "flex", "justifyContent": "center", "paddingTop": "50px"}}>
                                        {this.state.loaded && (
                                            <ReCAPTCHA
                                                style={{ display: "inline-block" }}
                                                ref={this._reCaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleCaptchaChange}
                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />
                                        )}
                                    </div>

                                    <div className={classes.wrapper} style={{"display": "flex", "justifyContent": "center", "paddingTop": "50px"}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={this.state.disabledSave}
                                        >
                                            <span className="capitalize">{t('form.actions.requestAccess')}</span>
                                        </Button>
                                    </div>

                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                    <CustomizedSnackbar message={club.success} severity="success" onClose={this.props.eraseMessage}/>
                    <CustomizedSnackbar message={club.error} severity="error" onClose={this.props.eraseMessage}/>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    club: state.club,
    emailSender: state.emailSender
});
export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            { addClub, eraseMessage, addModifyLogo }
        )(withTranslation()(ClubAdd))
    )
);
