import Payment from "../core/Payment";

class AthletePayment extends Payment{
  constructor(data) {
    super(data);
    this.subscription = data.subscription;
    this.canceled = data.canceled;
  }
}

export default AthletePayment;
