import {testPlanRepository} from "../repositories/testPlanRepository";
import TestPlan from "../models/club/TestPlan";

class TestPlanService {

    addTestPlan = (testPlan, clubId) => {
        return testPlanRepository.addTestPlan(testPlan, clubId);
    };

    deleteTestPlan = (id) => {
        return testPlanRepository.deleteTestPlan(id);
    };

    getTestPlans = (query) => {
        return testPlanRepository.getTestPlans(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new TestPlan(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateTestPlan = (testPlan) => {
        return testPlanRepository.updateTestPlan(testPlan);
    };

}

export default new TestPlanService();
