import UserSummary from "./UserSummary";

class Auditable {
    constructor(data) {
        if (data) {
            this.created = data.created ? new Date(data.created) : null;
            this.createdBy = data.createdBy ? new UserSummary(data.createdBy) : null;
            this.lastModified = data.lastModified ? new Date(data.lastModified) : null;
            this.lastModifiedBy = data.lastModifiedBy ? new UserSummary(data.lastModifiedBy) : null;
        }
    }
}

export default Auditable;
