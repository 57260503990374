import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class paymentRepository {

    /**
     * Get receipt higher number
     * @returns {Promise}
     * @param clubId
     */
    static getHigherReceiptDocumentNumber(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/general/payment/${clubId}`
        );
    }

    /**
     * Get receipt language
     * @returns {Promise}
     */
    static getPaymentReceiptLanguage() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/general/payment/language`
        );
    }

    /**
     * Generate game payment link
     * @returns {Promise}
     * @param athleteId
     * @param competitionEventId
     */
    static generateGamePaymentLink(athleteId, competitionEventId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/competition/link/${athleteId}/${competitionEventId}`
        );
    }

    /**
     * Generate game payment link
     * @returns {Promise}
     * @param athleteId
     * @param competitionId
     */
    static generateGamePaymentLinkUpdated(athleteId, competitionId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/competition/link/updated/${athleteId}/${competitionId}`
        );
    }

    /**
     * Generate camp payment link
     * @returns {Promise}
     * @param eventExpenseId
     */
    static generateCampPaymentLinkByEventExpense(eventExpenseId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/camp/link/${eventExpenseId}`
        );
    }

    /**
     * Generate game payment link
     * @returns {Promise}
     * @param eventExpenseId
     */
    static generateGamePaymentLinkByEventExpense(eventExpenseId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/competition/link/${eventExpenseId}`
        );
    }

    /**
     * Generate camp payment link
     * @returns {Promise}
     * @param athleteId
     * @param campEventId
     */
    static generateCampPaymentLink(athleteId, campEventId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/camp/link/${athleteId}/${campEventId}`
        );
    }

    /**
     * Generate camp payment link
     * @returns {Promise}
     * @param athleteId
     * @param campId
     */
    static generateCampPaymentLinkUpdated(athleteId, campId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/camp/link/updated/${athleteId}/${campId}`
        );
    }

    /**
     * Generate subscription payment link
     * @returns {Promise}
     * @param subscriptionId
     */
    static generateSubscriptionPaymentLink(subscriptionId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/subscription/link/${subscriptionId}`
        );
    }

    /**
     * Generate extra notice payment link
     * @returns {Promise}
     * @param extraPaymentNoticeId
     */
    static generateExtraNoticePaymentLink(extraPaymentNoticeId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/extraPaymentNotice/link/${extraPaymentNoticeId}`
        );
    }

    /**
     * Generate club subscription payment link
     * @returns {Promise}
     * @param clubId
     * @param clubSubscriptionId
     */
    static generateClubSubscriptionPaymentLink(clubId, clubSubscriptionId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/payment/generate/invoice/link/${clubId}/${clubSubscriptionId}`
        );
    }

}
