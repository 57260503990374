export const EVisaStatus = {
    VALID: 'VALID',
    EXPIRED: 'EXPIRED',
    ABOUT_TO_EXPIRE: 'ABOUT_TO_EXPIRE'
};

export const EVisaStatusTranslated = {
    VALID: 'athleteFile.medicalVisaHistory.status.valid',
    EXPIRED: 'athleteFile.medicalVisaHistory.status.expired',
    ABOUT_TO_EXPIRE: 'athleteFile.medicalVisaHistory.status.aboutToExpire'
};
