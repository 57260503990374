import React, { Component } from "react";
import {Dialog, IconButton, Button, Icon, withStyles, Card} from "@material-ui/core";
import "date-fns";
import i18n from "../../../i18n";
import MultipleSelectList from "./MultipleSelectList";
import Loader from "react-loader-spinner";
import {themeColors} from "../../EgretLayout/EgretTheme/themeColors";

const styles = () => ({
    loader: {
        marginTop: "5vh",
        marginBottom: "5vh"
    }
});

class AddDialog extends Component {
    state = {
        selectedItems: []
    };

    componentDidMount() {
        const {getList} = this.props;
        if (getList) {
            getList();
        }
    }

    handleAddAction = () => {
        let list = this.props.list.filter(item => this.state.selectedItems.includes(item.id));
        this.props.handleAddAction(list);
    };

    handleValueChange = event => {
        let target = event.target;
        let tempValues;
        if (target.checked) {
            tempValues = [...this.state.selectedItems, target.name];
            this.setState({
                selectedItems: tempValues
            });
        } else {
            tempValues = this.state.selectedItems.filter(item => item !== target.name);
            this.setState({
                selectedItems: tempValues
            });
        }
    };

    deselectValues = () => {
        // this.setState({
        //     selectedItems: []
        // });
    };

    render() {
        let { selectedItems } = this.state;
        let { open, handleClose, classes, list, listRetrieved,
            title, searchPlaceholder, searchByField, label, small, emptyMessage} = this.props;

        return (
            <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth={true}>
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                    <h4 className="m-0 text-white">{i18n.t('actions.add')} {title}</h4>
                    <IconButton onClick={handleClose}>
                        <Icon className="text-white">clear</Icon>
                    </IconButton>
                </div>

                <div className="p-16">
                    {listRetrieved ? (
                        <MultipleSelectList
                            title = {title}
                            searchPlaceholder = {searchPlaceholder}
                            list = {list}
                            selectedValues = {selectedItems}
                            searchByField = {searchByField}
                            label = {label}
                            small = {small}
                            emptyMessage = {emptyMessage}
                            handleValueChange = {this.handleValueChange}
                            deselectValues = {this.deselectValues}
                        />
                        ) : (
                        <Card elevation={3} style={{innerHeight:'35vw'}} className="position-relative p-16 mb-16 text-center">
                            <Loader
                                className = {classes.loader}
                                type="Oval"
                                color={themeColors.purple1.palette.primary.main}
                                height={100}
                                width={100}
                            />
                        </Card>
                        )
                    }
                    <div className="flex flex-space-between flex-middle pt-16" >
                        {listRetrieved && list.length >0 &&
                        ( <div className="flex flex-space-between flex-middle pt-16" style={{ width: "410px", display: "flex", justifyContent: "space-around"}}>
                            <Button onClick={this.handleAddAction} variant="contained" type="submit" style={{background: "#417505", color: "white"}}>
                            {i18n.t('actions.add')}
                        </Button>
                            <Button onClick={handleClose} variant="contained" style={{background: "#D0021B", color: "white"}}>
                        {i18n.t('actions.letGo')}
                            </Button>
                            </div>
                        )}
                    </div>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    (AddDialog)
);
