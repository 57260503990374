import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Presidents = EgretLoadable({
  loader: () => import("./Presidents")
});

const PresidentDetails = EgretLoadable({
   loader: () => import("../profile/PresidentDetails")
});

const presidentRoutes = [
  {
    path: "/president/list",
    exact: true,
    component: Presidents,
    auth: [authRoles.admin]
  },
  {
    path: "/president/:id",
    exact: true,
    component: PresidentDetails,
    auth: userUtilsService.getAllRoles()
  }
];

export default presidentRoutes;
