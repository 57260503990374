export const EDaysOfWeekEn = {
    MO: 'monday',
    TU: 'tuesday',
    WE: 'wednesday',
    TH: 'thursday',
    FR: 'friday',
    SA: 'saturday',
    SU: 'sunday'
};

export const EDaysOfWeekRo = {
    MO: 'luni',
    TU: 'marti',
    WE: 'miercuri',
    TH: 'joi',
    FR: 'vineri',
    SA: 'sambata',
    SU: 'duminica'
};

export const EDaysTranslated = {
    0: 'day.0',
    1: 'day.1',
    2: 'day.2',
    3: 'day.3',
    4: 'day.4',
    5: 'day.5',
    6: 'day.6'
}
