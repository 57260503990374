import {userRepository} from "../repositories/userRepository";
import {authRoles} from "../../auth/authRoles";
import adminService from "./adminService";
import presidentService from "./presidentService";
import coachService from "./coachService";
import parentService from "./parentService";
import athleteService from "./athleteService";
import CurrentUser from "../models/CurrentUser";

class UserService {
    addModifyProfilePicture = (type, file, id) => {
        return userRepository.addModifyProfilePicture(type, file, id);
    };

    getProfilePicture = (type, id) => {
        return userRepository.getProfilePicture(type, id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    async setUserDetails(user) {
        let prefix = "";
        let userDetails = null;

        if (!user) {
            return Promise.resolve();
        }

        const currentUser = new CurrentUser(user);
        if (currentUser.hasRole(authRoles.admin)) {
            const res = await adminService.getAdminByAccessUserId(currentUser.id);
            if (res.id) {
                prefix = "admin/";
                userDetails = res;
            }
        } else if (currentUser.hasRole(authRoles.moderator)) {
            const res = await presidentService.getPresidentByAccessUserId(currentUser.id);
            if (res.id) {
                prefix = "president/";
                userDetails = res;
            }
        }
        else if (currentUser.isClubAllCoachRole()) {
            const res = await coachService.getCoachByAccessUserId(currentUser.id);
            if (res.id) {
                prefix = "coach/";
                userDetails = res;
            }
        } else if (currentUser.hasRole(authRoles.parent)) {
            const res = await parentService.getParentByAccessUserId(currentUser.id);
            if (res.id) {
                prefix = "parent/";
                userDetails = res;
            }
        } else if (currentUser.hasRole(authRoles.athlete)) {
            const res = await athleteService.getAthleteByAccessUserId(currentUser.id);
            if (res.id) {
                prefix = "athlete/";
                userDetails = res;
            }
        }

        if (userDetails !== null) {
            user.firstName = userDetails.firstName;
            user.lastName = userDetails.lastName;
            user.fullName =  (userDetails.lastName || '') + ' ' + (userDetails.firstName || '');
            user.userDetailsId = `${prefix}${userDetails.id}`;
            user.fileData = userDetails.fileData;
            user.sport = userDetails.sport;
            user.activationDate = userDetails.accessUser.activationDate;
            user.receiveNotifications = userDetails.accessUser.receiveNotifications;
            user.helperHidden = userDetails.accessUser.helperHidden;
            user.isCoach = userDetails.coach;
            user.hideFinancial = userDetails.hideFinancial;
            user.clubDatabaseFilters = userDetails.clubDatabaseFilters;
            user.paymentFilters = userDetails.paymentFilters;
            user.performanceAnalyticsFilters = userDetails.performanceAnalyticsFilters;
        }

        // console.log("setUserDetails: ", user)
        return Promise.resolve(user);
    }

    hideHelper = () => {
        return userRepository.hideHelper()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateScreenFilters = (screenFiltersRequest) => {
        return userRepository.updateScreenFilters(screenFiltersRequest);
    }
}

export default new UserService();
