import Person from "./Person";

class User extends Person {
    constructor(data) {
        super(data);
        if (data) {
            this.id = data.id;
            this.accessUser = data.accessUser;
            this.created = data.created;
            this.createdBy = data.createdBy;
            this.modified = data.modified;
            this.modifiedBy = data.modifiedBy;
            this.fileData = data.fileData;
            this.idNumber = data.idNumber;

            this.lastAthleteIdSelectedFromDashboardSummary = data.lastAthleteIdSelectedFromDashboardSummary;
            this.lastTeamIdSelectedFromDashboardSummary = data.lastTeamIdSelectedFromDashboardSummary;
            this.lastSportSelectedFromCalendar = data.lastSportSelectedFromCalendar;
            this.lastSportSelectedFromDashboardActivities = data.lastSportSelectedFromDashboardActivities;
            this.lastCoachIdSelectedFromDashboardActivities = data.lastCoachIdSelectedFromDashboardActivities;
            this.lastTeamSelectedFromDashboardActivities = data.lastTeamSelectedFromDashboardActivities;
            this.lastSportSelectedFromDashboardSummary = data.lastSportSelectedFromDashboardSummary;
            this.lastTeamIdSelectedFromDashboardActivities = data.lastTeamIdSelectedFromDashboardActivities;

            this.clubDatabaseFilters = data.clubDatabaseFilters;
            this.paymentFilters = data.paymentFilters;
            this.performanceAnalyticsFilters = data.performanceAnalyticsFilters;
            this.invoiceScreenFilters = data.invoiceScreenFilters;
        }
    }
}

export default User;
