/**
 * @name StorageAdapter
 * @description Store Adapter for working with localStorage
 */

export const STORAGE_KEY = {
    TOKEN: 'USER:TOKEN',
    HELPER_DISPLAYED: 'HELPER'
};

export class StorageAdapter {

    static ls = window.localStorage;
    /**
     * Get data from local storage
     */
    static getItem(key) {
        const value = this.ls.getItem(key);
        if (value && value !== 'undefined') {
            return JSON.parse(value);
        }
    }

    /**
     * Save data to local storage
     */
    static setItem(key, value) {
        value = JSON.stringify(value);
        this.ls.setItem(key, value);
        return true;
    }

    /**
     * Delete data from local storage
     */
    static delete(key) {
        this.ls.removeItem(key);
    }

    /**
     * Set cookie
     */
    static setCookie(key, value, time = 0, path = '/', domain = '') {
        let cookie = `${key}=${value};`;
        if (time) {
            const date = new Date();
            date.setTime(date.getTime() + time);
            cookie += ` expires=${date.toUTCString()};`;
        }
        cookie += ` path=${path};`;
        if (domain) {
            cookie += ` domain=${domain};`;
        }
        document.cookie = cookie;
        return this;
    }

    /**
     * Remove cookie
     */
    static removeCookie(key) {
        const date = new Date(0);
        document.cookie = `${key}=; expires=${date.toUTCString()}; path=/;`;
        return this;
    }
}
