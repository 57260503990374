import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class clubPaymentRepository {

    /**
     * Get club Payments
     * @returns {Promise}
     * @param query
     */
    static getClubPayments(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/clubPayment/${helperService.serialize(query)}`
        );
    }

    /**
     * Get club payment by id
     * @returns {Promise}
     * @param id
     */
    static getClubPaymentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/clubPayment/${id}`
        );
    }

    /**
     * Add new club payment
     * @returns {Promise}
     */
    static addClubPayment(clubPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/clubPayment/`, {
            method: 'POST',
            body: JSON.stringify(clubPayment)
        });
    }

    /**
     * Update club payment
     * @returns {Promise}
     */
    static updateClubPayment(clubPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/clubPayment/`, {
            method: 'PUT',
            body: JSON.stringify(clubPayment)
        });
    }

    /**
     * Collect club invoice payment
     * @returns {Promise}
     */
    static collectClubInvoicePayment(clubPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/clubPayment/collectInvoice`, {
            method: 'PATCH',
            body: JSON.stringify(clubPayment)
        });
    }

    /**
     * Delete team
     * @returns {Promise}
     */
    static deleteClubPayment(clubPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/clubPayment/`, {
            method: 'DELETE',
            body: JSON.stringify(clubPayment)
        });
    }

}
