import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const TrainingEventCalendar = EgretLoadable({
    loader: () => import("./TrainingEventCalendar")
});

const CampEventCalendar = EgretLoadable({
    loader: () => import("./CampEventCalendar")
});

const CompetitionEventCalendar = EgretLoadable({
    loader: () => import("./CompetitionEventCalendar")
});

const calendarRoutes = [
    {
        path: "/calendar/training",
        exact: true,
        component: TrainingEventCalendar,
        auth: userUtilsService.getAllRoles()
    },
    {
        path: "/calendar/camp",
        exact: true,
        component: CampEventCalendar,
        auth: userUtilsService.getAllRoles()
    },
    {
        path: "/calendar/game",
        exact: true,
        component: CompetitionEventCalendar,
        auth: userUtilsService.getAllRoles()
    }
];

export default calendarRoutes;
