import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";

const Sport = EgretLoadable({
  loader: () => import("./Sport")
});

const SportDetails = EgretLoadable({
  loader: () => import("./SportDetails")
});


const sportRoutes = [
  {
    path: "/sport/list",
    exact: true,
    component: Sport,
    auth: [authRoles.admin]
  }
  ,
  {
    path: "/sport/:id",
    component: SportDetails,
    auth: [authRoles.admin]
  }
];

export default sportRoutes;
