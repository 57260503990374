import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const EventExpenses = EgretLoadable({
  loader: () => import("./EventExpenses")
});


const eventExpensesRoutes = [
  {
    path: "/eventExpenses/list",
    exact: true,
    component: EventExpenses,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default eventExpensesRoutes;
