import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class feedbackRepository {

    /**
     * Send feedback
     * @returns {Promise}
     */
    static sendFeedback({userId, feedback}) {
        return FetchAdapter.request(`${APP_CONFIG.URL.email}/feedback/`, {
            method: 'POST',
            body: JSON.stringify({userId, feedback})
        });
    }

}
