export const ERole = {
    ROLE_PARENT: 'ROLE_PARENT',
    ROLE_ATHLETE: 'ROLE_ATHLETE',
    ROLE_MAINCOACH: 'ROLE_MAINCOACH',
    ROLE_COMPETITIONMANAGER: 'ROLE_COMPETITIONMANAGER',
    ROLE_COACH: 'ROLE_COACH',
    ROLE_TEAMMANAGER: 'ROLE_TEAMMANAGER',
    ROLE_DOCTOR: 'ROLE_DOCTOR',
    ROLE_PHYSICALTRAINER: 'ROLE_PHYSICALTRAINER',
    ROLE_CLUBMANAGER: 'ROLE_CLUBMANAGER',
    ROLE_MODERATOR: 'ROLE_MODERATOR',
    ROLE_ADMIN: 'ROLE_ADMIN'
};

export const ERoleTranslated = {
    ROLE_PARENT: 'role.parent',
    ROLE_ATHLETE: 'role.athlete',
    ROLE_MAINCOACH: 'role.mainCoach',
    ROLE_COMPETITIONMANAGER: 'role.competitionManager',
    ROLE_COACH: 'role.coach',
    ROLE_TEAMMANAGER: 'role.teamManager',
    ROLE_DOCTOR: 'role.doctor',
    ROLE_PHYSICALTRAINER: 'role.physicalTrainer',
    ROLE_CLUBMANAGER: 'role.clubManager',
    ROLE_MODERATOR: 'role.moderator',
    ROLE_ADMIN: 'role.admin'
};
