import {athleteObservationRepository} from "../repositories/athleteObservationRepository";
import AthleteObservation from "../models/club/AthleteObservation";

class AthleteObservationService {

    getAthleteObservations = (query) => {
        return athleteObservationRepository.getAthleteObservations(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new AthleteObservation(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteObservationsCount = (query) => {
        return athleteObservationRepository.getAthleteObservationsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addAthleteObservation = (athleteObservation) => {
        return athleteObservationRepository.addAthleteObservation(athleteObservation);
    };

    updateAthleteObservation = (athleteObservation) => {
        return athleteObservationRepository.updateAthleteObservation(athleteObservation);
    };

    updateAthleteObservationProperties = (athleteObservationRequest) => {
        return athleteObservationRepository.updateAthleteObservationProperties(athleteObservationRequest);
    };

    deleteAthleteObservation = (athleteObservation) => {
        return athleteObservationRepository.deleteAthleteObservation(athleteObservation);
    };

    getUnreadObservations = () => {
        return athleteObservationRepository.getUnreadObservations();
    };

}

export default new AthleteObservationService();
