import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athleteObservationRepository {

    /**
     * Get athlete observations
     * @returns {Promise}
     * @param query
     */
    static getAthleteObservations(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteObservation/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athlete observations count
     * @returns {Promise}
     */
    static getAthleteObservationsCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteObservation/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Add athlete observation
     * @returns {Promise}
     */
    static addAthleteObservation(athleteObservation) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteObservation`, {
            method: 'POST',
            body: JSON.stringify(athleteObservation)
        });
    }

    /**
     * Update athlete observation
     * @returns {Promise}
     */
    static updateAthleteObservation(athleteObservation) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteObservation`, {
            method: 'PUT',
            body: JSON.stringify(athleteObservation)
        });
    }

    /**
     * Delete athlete observation
     * @returns {Promise}
     */
    static deleteAthleteObservation(athleteObservation) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteObservation/`, {
            method: 'DELETE',
            body: JSON.stringify(athleteObservation)
        });
    }

    /**
     * Update athlete observation properties
     * @returns {Promise}
     */
    static updateAthleteObservationProperties(athleteObservationRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteObservation/`, {
            method: 'PATCH',
            body: JSON.stringify(athleteObservationRequest)
        });
    }

    /**
     * Get unread athlete observations
     * @returns {Promise}
     */
    static getUnreadObservations() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteObservation/unreadObservations`
        );
    }

}
