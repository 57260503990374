import invoiceService from "../../shared/services/invoiceService";


export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const INVOICE_LOADING = "INVOICE_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const GET_INVOICE_LIST_TIMESTAMP = "GET_INVOICE_LIST_TIMESTAMP";
export const GET_INVOICES_COUNT = "GET_INVOICES_COUNT";
export const GET_INVOICES_COUNT_TIMESTAMP = "GET_INVOICES_COUNT_TIMESTAMP";

export function getInvoices(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_INVOICE_LIST_TIMESTAMP,
            data: currentTime
        });
        return invoiceService
            .getInvoices(query)
            .then(( data ) => {
                dispatch({
                    type: GET_INVOICE_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getInvoicesCount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_INVOICES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return invoiceService
            .getInvoicesCount(query)
            .then(( data ) => {
                dispatch({
                    type: GET_INVOICES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
