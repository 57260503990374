import React, {Component} from "react";
import {Dialog, Button} from "@material-ui/core";
import i18n from "../../../i18n";

class NotifyDialog extends Component {

    render() {
        const {
            open,
            onConfirmDialogClose,
            text,
            title = i18n.t('notifyDialog.title.confirm'),
            showConfirm,
            onYesClick,
            proceedLabel = i18n.t('notifyDialog.label.yes'),
            declineLabel = i18n.t('notifyDialog.label.no'),
        } = this.props;
        return (
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={open}
                onClose={onConfirmDialogClose}
            >
                <div className="pt-24 px-20 pb-8">
                    <h4 className="capitalize">{title}</h4>
                    <p>{text}</p>
                    {showConfirm && (
                        <div className="flex flex-space-between pt-8">
                            <Button onClick={onYesClick} variant="contained" style={{background: "#417505", color: "white"}}>
                                {proceedLabel}
                            </Button>
                            <Button
                                onClick={onConfirmDialogClose}
                                variant="contained"
                                style={{background: "#D0021B", color: "white"}}
                            >
                                {declineLabel}
                            </Button>
                        </div>
                    )}
                    {!showConfirm && (
                        <div className="flex flex-space-between pt-8">
                            <Button
                                onClick={onConfirmDialogClose}
                                variant="contained"
                                style={{background: "#D0021B", color: "white"}}
                            >
                                {declineLabel}
                            </Button>
                        </div>

                    )}
                </div>
            </Dialog>
        );
    }
};

export default NotifyDialog;
