import {
    ATHLETE_REGISTER_FILE_LOADING,
    ERASE_MESSAGE,
    GET_ATHLETE_REGISTER_FILE_LIST,
    GET_ATHLETE_REGISTER_FILE_LIST_TIMESTAMP,
    GET_ATHLETE_REGISTER_FILES_COUNT,
    GET_ATHLETE_REGISTER_FILES_COUNT_TIMESTAMP,
    SAVE_ERROR,
    SAVE_SUCCESS
} from "../actions/AthleteRegisterFileActions";

const initialState = {
    success: null,
    error: null,
    athleteRegisterFileList: [],
    getRegisterAthleteFilesTimestamp: null,
    count: 0,
    getAthleteRegisterFilesCountTimestamp: null
};

const AthleteRegisterFileReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_ATHLETE_REGISTER_FILE_LIST_TIMESTAMP: {
            return {
                ...state,
                getRegisterAthleteFilesTimestamp: action.data
            };
        }
        case GET_ATHLETE_REGISTER_FILE_LIST: {
            if (action.data.time === state.getRegisterAthleteFilesTimestamp) {
                return {
                    ...state,
                    athleteRegisterFileList: action.data.list
                };
            } else {
                return state;
            }
        }
        case GET_ATHLETE_REGISTER_FILES_COUNT_TIMESTAMP: {
            return {
                ...state,
                getAthleteRegisterFilesCountTimestamp: action.data
            };
        }
        case GET_ATHLETE_REGISTER_FILES_COUNT: {
            if (action.data.time === state.getAthleteRegisterFilesCountTimestamp) {
                return {
                    ...state,
                    count: action.data.count
                };
            } else {
                return state;
            }
        }
        case ATHLETE_REGISTER_FILE_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case SAVE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case SAVE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case ERASE_MESSAGE: {
            return {
                ...state,
                error: null,
                success: null
            };
        }
        default: {
            return state;
        }
    }
};

export default AthleteRegisterFileReducer;
