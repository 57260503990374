import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class userRepository {
    /**
     * Add profile picture
     * @returns {Promise}
     * @param type
     * @param file
     * @param id
     */
    static addModifyProfilePicture (type, file, id) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/profile-picture?id=${id}&type=${type}`, {
            method: 'POST',
            body: formData
        }, true);
    };

    /**
     * Get profile picture by id
     * @returns {Promise}
     * @param type
     * @param id
     */
    static getProfilePicture(type, id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/profile-picture?id=${id}&type=${type}`,{
                headers: {
                    'Accept': 'image/jpeg'
                }
            }, true
        );
    }

    /**
     * Hide helper
     * @returns {Promise}
     */
    static hideHelper() {
        return FetchAdapter.request(`${APP_CONFIG.URL.um}/hideHelper`, {
            method: 'POST',
        });
    }

    /**
     * Update screen filters for current user
     * @returns {Promise}
     */
    static updateScreenFilters(screenFiltersRequest){
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/user/filters`, {
            method: 'PATCH',
            body: JSON.stringify(screenFiltersRequest)
        });
    }
}
