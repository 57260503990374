import adminService from "../../shared/services/adminService";
import i18n from "../../../i18n";
import {EAdminResponseCodes} from "../../shared/enums/ERespondeCodes";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function updateAdmin(admin) {
    return dispatch => {
        dispatch({
            type: ADMIN_LOADING
        });
        return adminService
            .updateAdmin(admin)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EAdminResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EAdminResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseAdminMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
