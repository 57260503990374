import clubService from "../../shared/services/clubService";
import i18n from "../../../i18n";
import {EClubResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_CLUB_LIST = "GET_CLUB_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const CLUB_LOADING = "CLUB_LOADING";
export const CLUB_NOT_LOADING = "CLUB_NOT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getClubs(query) {
    return dispatch => {
        return clubService
            .getClubs(query)
            .then(( data ) => {
                dispatch({
                    type: GET_CLUB_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateClub(club) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        return clubService
            .updateClub(club)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EClubResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EClubResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addClub(club) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        return clubService
            .addClub(club)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EClubResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EClubResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addModifyLogo(file, clubId) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
         clubService
            .addModifyLogo(file, clubId)
            .then(() => {
                return dispatch({
                    type: CLUB_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: error.message
                });
            });
    };
}
export function addModifyStamp(file, clubId) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        clubService
            .addModifyStamp(file, clubId)
            .then(() => {
                return dispatch({
                    type: CLUB_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: error.message
                });
            });
    };
}

export function addModifyContract(file, clubId) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        clubService
            .addModifyContract(file, clubId)
            .then(() => {
                return dispatch({
                    type: CLUB_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: error.message
                });
            });
    };
}

export function generateInvoices(params) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        clubService
            .generateInvoices(params)
            .then(() => {
                return dispatch({
                    type: CLUB_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: error.message
                });
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
