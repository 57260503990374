class Training {
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.trainingEvent = data.trainingEvent;
        this.trainingDate = data.trainingDate ? new Date(data.trainingDate) : null;
        this.athletesStats = data.athletesStats;
        this.comments = data.comments;

        this.videoStreaming = data.videoStreaming;
        this.timeStart = data.timeStart;
        this.timeStop = data.timeStop;
        this.location = data.location;
        this.individualAthletes = data.individualAthletes;
        this.canceled = data.canceled;

        this.start = this.getDate(this.timeStart ? this.timeStart : this.trainingEvent.timeStart);
        this.end = this.getDate(this.timeStop ? this.timeStop : this.trainingEvent.timeStop);
    }

    getDate(time) {
        let hours = parseInt(time.split(":")[0]);
        const minutes = parseInt(time.split(":")[1]);
        const date = new Date(this.trainingDate.getTime());
        if (time.includes('pm')){
            hours+=12;
        }
        date.setHours(hours, minutes);
        return date;
    }

}

export default Training;
