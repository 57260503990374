import {
  ERASE_MESSAGE, PARENT_LOADING, SAVE_ERROR, SAVE_SUCCESS, GET_PARENT_LIST, GET_PARENT_LIST_TIMESTAMP,
  GET_PARENTS_COUNT, GET_PARENTS_COUNT_TIMESTAMP
} from "../actions/ParentActions";

const initialState = {
  loading: false,
  success: null,
  error: null,
  parentList: [],
  getParentsTimestamp: null,
  count: 0,
  getParentsCountTimestamp: null
};

const ParentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_PARENT_LIST_TIMESTAMP: {
      return {
        ...state,
        getParentsTimestamp: action.data
      };
    }
    case GET_PARENT_LIST: {
      if (action.data.time === state.getParentsTimestamp) {
        return {
          ...state,
          parentList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_PARENTS_COUNT_TIMESTAMP: {
      return {
        ...state,
        getParentsCountTimestamp: action.data
      };
    }
    case GET_PARENTS_COUNT: {
      if (action.data.time === state.getParentsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case PARENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default ParentReducer;
