import React, { Component } from "react";
import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TablePagination,
    Card, TextField, MenuItem, withStyles, LinearProgress, Button, CircularProgress
} from "@material-ui/core";
import teamService from "../../shared/services/teamService";
import {Breadcrumb} from "../../../egret";
import shortid from "shortid";
import {connect} from "react-redux";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {PropTypes} from "prop-types";
import {eraseMessage, getTeamsCount, updateTeamProperties} from "../../redux/actions/TeamActions";
import {getTeams} from "../../redux/actions/TeamActionsMobile";
import CurrentUser from "../../shared/models/CurrentUser";
import FormControl from "@material-ui/core/FormControl";
import clubService from "../../shared/services/clubService";
import {currentUserService} from "../../shared/services/currentUserService";
import {classList} from "../../../utils";
import {ETeamStatus, ETeamStatusTranslated} from "../../shared/enums/ETeamStatus";
import NotifyDialog from "../../shared/components/NotifyDialog";
import {withTranslation} from "react-i18next";
import i18n from "../../../i18n";
import {ERowsPerPage} from "../../shared/enums/ERowsPerPage";
import {helperService} from "../../shared/services/helperService";
import {ERole} from "../../shared/enums/ERole";
import {EUserStatus} from "../../shared/enums/EUserStatus";
import userService from "../../shared/services/userService";
import TeamsScreenFilters from "../../shared/models/club/filters/TeamsScreenFilters";
import {setUserData} from "../../redux/actions/UserActions";
import ClubDatabaseFilters from "../../shared/models/club/filters/ClubDatabaseFilters";
import parentServiceMobile from "../../shared/services/parentServiceMobile";
import SelectDialog from "../../shared/components/SelectDialog";
import UpdateTeamRequest from "../../shared/models/club/UpdateTeamRequest";
import {ESubscriptionTypeStatus} from "../../shared/enums/ESubscriptionTypeStatus";

const ENTER_KEY = 13;

const styles = () => ({
    formControl: {
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        width: '194px',
        marginRight: '16px'
    },
    disabledButton:{
        color: '#bbb !important'
    },
    notchedOutline: {
        borderWidth: '3px',
        borderColor: '#941bcc'
    },
    textCell: {
        textAlign: 'center'
    },
    changeCoach: {
        bottom: '1px',
        padding: '5px',
    },
    changeCoachIcon: {
        color: '#fff',
        fontSize: '20px'
    },
    coachNameTeam: {
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff !important',
        flex: '0 1 80%'
    },
    coachContainer: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        alignItems: 'start'
    }
});

class Teams extends Component {
    state = {
        rowsPerPage: ERowsPerPage.MID,
        page: 0,
        shouldOpenConfirmationDialog: false,
        shouldOpenWarningDialog: false,
        warningText: null,
        query: {
            'name': '',
            'code': '',
            'sport':'All',
            'status': 'All',
            'createdBy.userFullName': '',
            'athleteNames': '',
            'countAthletes': ''
        },
        clubSports: [],
        teamsLoading: false,
        counterLoading: false,
        parentUser: null,
        selectedTeamToModifyCoach: null,
        shouldShowCoachesDialog: false,
        coaches: [],
        coachesRetrieved: false
    };

    componentDidMount() {
        const query = this.props.location && this.props.location.state ? {...this.props.location.state.query} : {...this.state.query};
        if (!query.hasOwnProperty("pagination")) {
            query["pagination"] = JSON.stringify({
                page: 0,
                rowsPerPage: this.state.rowsPerPage,
                sort: {field: "name", order: "ASC"}
            });
        }
        const currentUser = new CurrentUser(this.props.user);
        if (this.props.user.clubDatabaseFilters?.teamsScreenFilters) {
            const teamsFilters = this.props.user.clubDatabaseFilters.teamsScreenFilters;
            query['name'] = teamsFilters.name ? teamsFilters.name : '';
            query['code'] = teamsFilters.code ? teamsFilters.code : '';
            query['sport'] = teamsFilters.sportId ? teamsFilters.sportId : 'All';
            query['status'] = teamsFilters.status ? teamsFilters.status : 'All';
            query['createdBy.userFullName'] = teamsFilters.coachName ? teamsFilters.coachName : '';
            query['athleteNames'] = teamsFilters.athleteName ? teamsFilters.athleteName :'';
            query['countAthletes'] = teamsFilters.athletesCount ? teamsFilters.athletesCount :'';
        }

        this.setState({
            ...this.state,
            query: query,
            page: JSON.parse(query["pagination"]).page,
            rowsPerPage: JSON.parse(query["pagination"]).rowsPerPage,
            currentUser: currentUser
        }, () => this.init());
    }

    init() {
        if (currentUserService.isParent(this.state.currentUser)) {
            parentServiceMobile.getParentByAccessUserId(this.props.user.id)
                .then(parent => {
                    this.setState({
                        ...this.state,
                        parentUser: parent
                    }, () => {
                        this.updatePageData();
                    });
                });
        } else if (currentUserService.isPresidentManager(this.state.currentUser)) {
            this.getClubSports().then(() => this.updatePageData());
        } else {
            this.updatePageData();
        }
    }

    setRowsPerPage = event => {
        let {query} = this.state;
        const page = 0;
        const rowsPerPage = event.target.value;
        query["pagination"] = JSON.stringify({page: 0, rowsPerPage: rowsPerPage, sort: {field: "name", order: "ASC"}});
        this.setState({
            ...this.state,
            rowsPerPage,
            page,
            query
        }, () => {
            this.updatePageData();
        });
    };

    handleChangePage = (event, page) => {
        const {rowsPerPage, query} = this.state;
        query["pagination"] = JSON.stringify({page: page, rowsPerPage: rowsPerPage, sort: {field: "name", order: "ASC"}});
        this.setState({
            ...this.state,
            page,
            query
        }, () => {
            this.updatePageData();
        });
    };

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            shouldOpenConfirmationDialog: false
        });
    };


    getClubSports(){
        return new Promise( (resolve, reject) => {
            if (this.props.user.club) {
                clubService.getClubById(this.props.user.club.id).then(club => {
                    this.setState({
                        ...this.state,
                        clubSports: club.sports,
                    });

                    // check if value for last filtered sport still exists. If not update team filters
                    if (this.props.user.clubDatabaseFilters?.teamsScreenFilters) {
                        const teamsFilters = this.props.user.clubDatabaseFilters.teamsScreenFilters;
                        if (teamsFilters.sportId) {
                            const sportFound = club.sports.find(sport => sport.id === teamsFilters.sportId);
                            if (!sportFound) {
                                const query = this.state.query;
                                query.sport = 'All';
                                this.setState({
                                    ...this.state,
                                    query: query,
                                }, () => {
                                    this.updateTeamsFilters();
                                    resolve();
                                });
                            } else {
                                resolve();
                            }
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }

    handleDeleteTeam = team => {
        this.setState({
            team,
            shouldOpenConfirmationDialog: true
        });
    };

    handleConfirmationResponse = async () => {
        teamService.deleteTeam(this.state.team).then(() => {
            this.handleDialogClose();
            this.updatePageData();
        });

        // const assignedToEvents = await teamService.checkForEvents(this.state.team.teamId);
        // if ((this.state.team.athletes && this.state.team.athletes.length > 0) ||
        //     (this.state.team.coaches && this.state.team.coaches.length > 0) || assignedToEvents) {
        //     let warningText = 'Team is already assigned to ';
        //     if (this.state.team.coaches.length > 0) {
        //         warningText += 'coaches';
        //         warningText += this.state.team.athletes.length > 0 ? ', ' : '';
        //     }
        //     if (this.state.team.athletes.length > 0) {
        //         warningText += 'athletes';
        //         warningText += assignedToEvents ? ', ' : '';
        //     }
        //     if (assignedToEvents) {
        //         warningText += 'events';
        //     }
        //     warningText += '!';
        //     this.setState({
        //         shouldOpenConfirmationDialog: false,
        //         shouldOpenWarningDialog: true,
        //         warningText: i18n.t('team.labels.warningDelete')
        //     });
        // } else {
        //     teamService.deleteTeam(this.state.team).then(() => {
        //         this.handleDialogClose();
        //         this.updatePageData();
        //     });
        // }
    };

    handleViewClick = teamId => {
        const query = helperService.formatQuery(this.state.query);
        this.props.history.push({pathname:`/team/${teamId}`, state: {query:query, path: this.props.location.pathname}})
    };

    handleViewAthleteClick = athleteId => {
        const query = helperService.formatQuery(this.state.query);
        this.props.history.push({pathname:`/athlete/${athleteId}`, state: {query:query, path: this.props.location.pathname}})

        // this.props.history.push(`/athlete/${athleteId}`);
    };

    updatePageData = (resetPage = false) => {
        this.setState({
            ...this.state,
            teamsLoading: true,
            counterLoading: true
        }, () => {

            if (resetPage) {
                this.state.query["pagination"] = JSON.stringify({
                    page: 0,
                    rowsPerPage: this.state.rowsPerPage,
                    sort: {field: "name", order: "ASC"}
                });
                this.state.page = 0;
            }

            const query = {...this.state.query};
            Object.keys(query).forEach((key) => (query[key] === '' || query[key] === 'All') && delete query[key]);
            if(this.state.currentUser && currentUserService.isParentAthlete(this.state.currentUser)){
                query["status"] = ETeamStatus.ACTIVE;
            }

            const countMap = Object.assign({}, query);
            delete countMap["pagination"];

            this.props.getTeams(query).then(() => {
                this.setState({
                    ...this.state,
                    teamsLoading: false
                });
            });

            this.props.getTeamsCount(countMap).then(() => {
                this.setState({
                    ...this.state,
                    counterLoading: false
                });
            });
        });
    };

    updateTeamsFilters = () => {
        if (this.state.query) {
            const query = {...this.state.query};
            const teamsScreenFilters = new TeamsScreenFilters(
                query.name !== '' ? query.name : null,
                query.code !== '' ? query.code : null,
                query.sport !== 'All' ? query.sport : null,
                query.status !== 'All' ? query.status : null,
                query['createdBy.userFullName'] !== '' ? query['createdBy.userFullName'] : null,
                query.countAthletes !== '' ? query.countAthletes : null,
                query.athleteNames !== '' ? query.athleteNames : null,
            );
            userService.updateScreenFilters(teamsScreenFilters).then(() => {
                // update teams filters for userData
                const userDataToUpdate = this.props.user;
                if (!userDataToUpdate.clubDatabaseFilters) {
                    userDataToUpdate.clubDatabaseFilters = new ClubDatabaseFilters();
                }
                userDataToUpdate.clubDatabaseFilters.teamsScreenFilters = teamsScreenFilters;
                this.props.setUserData(userDataToUpdate);
            });
        }
    };

    handleKeyDown = event => {
        event.persist();
        if (event.keyCode === ENTER_KEY) {
            this.updateTeamsFilters();
            this.updatePageData(true);
        }
    };

    handleChange = event => {
        event.persist();
        const query = {...this.state.query};
        const value = event.target.value;
        if (event.target.name === 'countAthletes') {
            if (value.match(/^\d+$/) || value === '') {
                query[event.target.name] = value;
                this.setState({query});
            }
        } else {
            query[event.target.name] = value;
            this.setState({query});
        }
    };

    handleSelectChange = event => {
        event.persist();
        const query = {...this.state.query};
        query[event.target.name] = event.target.value;
        this.setState({
            ...this.state,
            query
        }, () => {
            this.updateTeamsFilters();
            this.updatePageData(true);
        });
    };

    eraseFilters = () => {
        const query = {...this.state.query,
            'name': '',
            'code': '',
            'sport': 'All',
            'status': 'All',
            'createdBy.userFullName': '',
            'athleteNames': '',
            'countAthletes': ''
        };
        this.setState({
            ...this.state,
            query: query
        }, () => {
            this.updateTeamsFilters();
            this.updatePageData(true);
        });
    };

    isDeleteEnabled = (team) => {
        return (currentUserService.isPresidentManager(this.state.currentUser) || currentUserService.isMainCoach(this.state.currentUser) || currentUserService.isCoach(this.state.currentUser))
            && (team.status === ETeamStatus.CANCELED)
    };

    handleWarningDialogClose = () => {
        this.setState({
            shouldOpenWarningDialog: false
        });
    };

    handleWarningProceedResponse = () => {
        teamService.deleteTeam(this.state.team).then(() => {
            this.setState({
                shouldOpenWarningDialog: false
            });
            this.updatePageData();
        });
    };

    getAthletesFromTeam = (team) => {
        let athleteIds = [];
        let athletes;
        this.state.parentUser.athletes.forEach(athlete => {
            athleteIds.push(athlete.id);
        });
        athletes = team.athletes.filter(athlete => athleteIds.indexOf(athlete.id) > -1);
        return athletes;
    };

    getCoachNameFromTeam = (team) => {
        const coach = this.getCoachFromTeam(team);
        if (coach) {
            return (coach.firstName || '') + ' ' + (coach.lastName || '');
        }
        return '';
    };

    getCoachFromTeam = (team) => {
        if (team.selectedListCoachId) {
            let selectedCoach = team.coaches.find(coach => coach.status === EUserStatus.ACTIVE && coach.id === team.selectedListCoachId);
            if (selectedCoach) {
                return selectedCoach;
            }
        }
        let mainCoaches = team.coaches.filter(coach => coach.roleName === ERole.ROLE_MAINCOACH && coach.status === EUserStatus.ACTIVE).sort();
        if(mainCoaches.length > 0) {
            return mainCoaches[0];
        } else {
            let coaches = team.coaches.filter(coach => coach.status === EUserStatus.ACTIVE).sort();
            if(coaches.length > 0) {
                return coaches[0];
            }
        }
        return null;
    };

    goToUserDetails(team) {
        const query = helperService.formatQuery(this.state.query);
        const coach = this.getCoachFromTeam(team);
        if (coach) {
            this.props.history.push({pathname:`/coach/${coach.id}`, state: {query:query, path: this.props.location.pathname}});
        }
        return '';
    }

    /**
     * Set the coach that will be displayed in the list
     * @param team
     */
    setDisplayedCoachForTeam(team) {
        this.setState({
            ...this.state,
            selectedTeamToModifyCoach: team,
            shouldShowCoachesDialog: true
        });
    }

    isTeamEditable = (team) => {
        return (currentUserService.isPresidentManager(this.state.currentUser) || currentUserService.isMainCoach(this.state.currentUser)) && team.status === ETeamStatus.ACTIVE;
    };

    getCoachesToDisplay() {
        this.setState({
            ...this.state,
            coaches: [],
            coachesRetrieved: false
        }, () => {
            const teamCoaches = this.state.selectedTeamToModifyCoach ? this.state.selectedTeamToModifyCoach.coaches.filter(coach => coach.status === EUserStatus.ACTIVE) : [];

            this.setState({
                ...this.state,
                coaches: teamCoaches.sort((a,b) => a.lastName.localeCompare(b.lastName)),
                coachesRetrieved: true
            });
        });
    };

    handleCoachesDialogClose = () => {
        this.setState({
            ...this.state,
            shouldShowCoachesDialog: false
        });
    };

    changeTeamCoach = (coach) => {
        const currentCoach = this.getCoachFromTeam(this.state.selectedTeamToModifyCoach);
        if (!currentCoach || currentCoach.id !== coach.id) {
            const teamRequest = new UpdateTeamRequest({
                id: this.state.selectedTeamToModifyCoach.teamId,
                selectedListCoachId: coach.id
            });

            this.props.updateTeamProperties(teamRequest, i18n.t('responseCodes.team.success.coachFromTeamChanged')).then(() => {
                this.updatePageData();
            });
        }
        this.handleCoachesDialogClose();
    };

    render() {
        let {
            rowsPerPage,
            page,
            shouldOpenConfirmationDialog,
            shouldOpenWarningDialog
        } = this.state;

        let {teamList} = this.props.team || [];
        const {classes, t, i18n} = this.props;

        return (
            <div className="m-sm-30">
                <div  className="mb-sm-30">
                    <Breadcrumb routeSegments={[{ name: t('team.title'), path: "/team/list" }]} />
                </div>
                {(currentUserService.isPresidentManager(this.state.currentUser) || currentUserService.isMainCoach(this.state.currentUser)) && (
                    <div className="mb-sm-16">
                        <Button
                            className="mb-16"
                            variant="contained"
                            color="secondary"
                            onClick={() => this.props.history.push(`/team/add`)}
                        >
                            {t('team.labels.addTeam')}
                        </Button>
                        <span style={{marginLeft:'10px'}}>
                            <Button
                                className="mb-16"
                                variant="contained"
                                color="primary"
                            >
                                <a href={"https://www.youtube.com/watch?v=lmf-Hx6IYGc"}
                                   target="_blank" rel="noopener noreferrer">{t('menu.tutorial')}</a>
                            </Button>
                        </span>
                    </div>
                )}
                <div className="flex flex-middle mb-16">
                    <IconButton
                        title={t('table.labels.applyFilters')}
                        onClick={() => this.updatePageData()}>
                        <Icon color="primary">search</Icon>
                    </IconButton>
                    <IconButton
                        title={t('table.labels.eraseFilters')}
                        onClick={() => this.eraseFilters()}>
                        <Icon color="error">close</Icon>
                    </IconButton>
                </div>

                    <Card className="w-100 overflow-auto" elevation={2}>
                    <Table className="crud-table" style={{ whiteSpace: "normal", minWidth: "750px" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="textCell flex flex-middle mb-16">
                                        {t('table.head.team.name')}
                                    </div>
                                    <div className="flex flex-middle mb-16">
                                        <TextField
                                            variant="outlined"
                                            name="name"
                                            placeholder = {t('form.name')}
                                            value = {this.state.query.name || ''}
                                            className="mr-32"
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                style: {
                                                    padding: "9px 10px"
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: this.state.query.name ? classes.notchedOutline : ''
                                                }
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-middle mb-16">
                                        {t('table.head.team.code')}
                                    </div>
                                    <div className="flex flex-middle mb-16">
                                        <TextField
                                            variant="outlined"
                                            name="code"
                                            placeholder = {t('form.code')}
                                            value = {this.state.query.code}
                                            className="mr-32"
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                style: {
                                                    padding: "9px 10px"
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: this.state.query.code ? classes.notchedOutline : ''
                                                }
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                {currentUserService.isPresidentManager(this.state.currentUser) && (
                                    <TableCell >
                                    <div className="flex flex-middle mb-16">
                                        {t('table.head.team.sport')}
                                    </div>
                                    <div className="flex flex-middle mb-16">
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant="outlined"
                                                name="sport"
                                                value = {this.state.query["sport"]}
                                                onChange={this.handleSelectChange}
                                                className="select"
                                                select
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: this.state.query["sport"] && this.state.query["sport"] !== 'All' ? classes.notchedOutline : ''
                                                    },
                                                    style: {
                                                        marginRight: '12px',
                                                        height: '35px'
                                                    }
                                                }}
                                            >
                                                <MenuItem value="All">
                                                    {t('table.head.options.all')}
                                                </MenuItem>
                                                {this.state.clubSports.map((sport, i) => (
                                                    <MenuItem value={sport.id} key={i}>
                                                        {sport.name[i18n.language]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </div>
                                </TableCell>
                                )}
                                {currentUserService.isClubStaff(this.state.currentUser) && (
                                <TableCell>
                                    <div className="flex flex-middle mb-16">
                                        {t('table.head.club.status')}
                                    </div>
                                    <div className="flex flex-middle mb-16">
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant="outlined"
                                                name="status"
                                                value = {this.state.query.status}
                                                onChange={this.handleSelectChange}
                                                className="select"
                                                select
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: this.state.query.status && this.state.query.status !== 'All' ? classes.notchedOutline : ''
                                                    },
                                                    style: {
                                                        marginRight: '12px',
                                                        height: '35px'
                                                    }
                                                }}
                                            >
                                                <MenuItem value="All">
                                                    {t('table.head.options.all')}
                                                </MenuItem>
                                                { Object.entries(ETeamStatus).map(([key, value], index) => (
                                                    <MenuItem value={key} key={index}>
                                                        <small
                                                            className={classList({
                                                                "border-radius-4   px-8 py-2": true,
                                                                "bg-green": key === ETeamStatus.ACTIVE,
                                                                "bg-secondary": key === ETeamStatus.PAUSED,
                                                                "bg-light-dark": key === ETeamStatus.CANCELED
                                                            })}
                                                        >
                                                            {t(ETeamStatusTranslated[key])}
                                                        </small>

                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                        </FormControl>
                                    </div>
                                </TableCell>
                                )}
                                {!currentUserService.isParent(this.state.currentUser) && (
                                    <TableCell>
                                        <div className="flex flex-middle mb-16">
                                            {t('table.head.team.createdBy')}
                                        </div>
                                        <div className="flex flex-middle mb-16">
                                            <TextField
                                                variant="outlined"
                                                name="createdBy.userFullName"
                                                value = {this.state.query["createdBy.userFullName"]}
                                                className="mr-32"
                                                placeholder={t('team.searchName')}
                                                onKeyDown={this.handleKeyDown}
                                                onChange={this.handleChange}
                                                inputProps={{
                                                    style: {
                                                        padding: "9px 10px"
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: this.state.query["createdBy.userFullName"] ? classes.notchedOutline : ''
                                                    }
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                )}
                                {!currentUserService.isParent(this.state.currentUser) && (
                                    <TableCell>
                                        <div className="flex flex-middle mb-16">
                                            {t('table.head.team.countAthletes')}
                                        </div>
                                        <div className="flex flex-middle mb-16">
                                            <TextField
                                                variant="outlined"
                                                name="countAthletes"
                                                placeholder = {t('table.head.team.countAthletes')}
                                                value = {this.state.query.countAthletes || ''}
                                                className="mr-32"
                                                onKeyDown={this.handleKeyDown}
                                                onChange={this.handleChange}
                                                inputProps={{
                                                    style: {
                                                        padding: "9px 10px"
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: this.state.query.countAthletes ? classes.notchedOutline : ''
                                                    }
                                                }}
                                            />
                                        </div>

                                    </TableCell>
                                    )}
                                {currentUserService.isParent(this.state.currentUser) && (
                                    <TableCell style={{width: '20%'}}>
                                        <div className="flex flex-middle mb-16">
                                            {t('table.head.team.athleteNames')}
                                        </div>
                                        <div className="flex flex-middle mb-16">
                                            <TextField
                                                variant="outlined"
                                                name="athleteNames"
                                                placeholder = {t('table.head.team.athleteNames')}
                                                value = {this.state.query.athleteNames || ''}
                                                className="mr-32"
                                                onKeyDown={this.handleKeyDown}
                                                onChange={this.handleChange}
                                                inputProps={{
                                                    style: {
                                                        padding: "9px 10px"
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: this.state.query.name ? classes.notchedOutline : ''
                                                    }
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                )}

                                <TableCell align="center">
                                    <div className="flex flex-middle mb-16"/>
                                    { this.state.counterLoading &&  (<div><CircularProgress /> </div>)}
                                    { !this.state.counterLoading &&  (
                                        <div
                                            style={{fontWeight: 'bold', color:'#941bcc', fontSize: '25px', padding: "20px 0 10px"}}
                                        >
                                            { this.props.team.count }
                                        </div>
                                    )}
                                </TableCell>

                            </TableRow>
                            {currentUserService.isPresidentManager(this.state.currentUser) && (
                                <tr><td colSpan="7"> { this.state.teamsLoading && <LinearProgress />}</td></tr>
                            )}
                            {!currentUserService.isPresidentManager(this.state.currentUser) && !currentUserService.isParentAthlete(this.state.currentUser) && (
                                <tr><td colSpan="6"> { this.state.teamsLoading && <LinearProgress />}</td></tr>
                            )}
                            {currentUserService.isAthlete(this.state.currentUser) && (
                                <tr><td colSpan="5"> { this.state.teamsLoading && <LinearProgress />}</td></tr>
                            )}
                            {currentUserService.isParent(this.state.currentUser) && (
                                <tr><td colSpan="4"> { this.state.teamsLoading && <LinearProgress />}</td></tr>
                            )}
                        </TableHead>
                        <TableBody>
                            {teamList
                                .map((team) => (
                                    <TableRow key={shortid.generate()}>
                                        <TableCell className="pl-sm-24 capitalize " align="left" style={ currentUserService.isParentAthlete(this.state.currentUser) ? {'backgroundColor':team.color, height: '40px'} : {'backgroundColor':team.color}}>
                                                <a className="text-white"
                                                   style={{cursor:'pointer'}}
                                                   onClick={() => this.handleViewClick(team.teamId)}
                                                >
                                                    {team.name}
                                                </a>
                                        </TableCell>
                                        <TableCell className="pl-sm-24 capitalize" align="left" style={{'backgroundColor':team.color}}>
                                            <a className="text-white"
                                               style={{cursor:'pointer'}}
                                               onClick={() => this.handleViewClick(team.teamId)}
                                            >
                                                {' '}{team.code}
                                            </a>
                                        </TableCell>
                                        {currentUserService.isPresidentManager(this.state.currentUser) && (
                                            <TableCell className="pl-sm-24 capitalize text-white" align="left" style={{'backgroundColor':team.color}}>
                                            {team.sportName[i18n.language]}
                                        </TableCell>
                                        )}
                                        {currentUserService.isClubStaff(this.state.currentUser) && (
                                        <TableCell className="pl-0" style={{'backgroundColor':team.color}}>
                                            <a className="text-white"
                                               style={{cursor:'pointer'}}
                                               onClick={() => this.handleViewClick(team.teamId)}
                                            >
                                            <small
                                                className={classList({
                                                    "border-radius-4  text-white px-8 py-2": true,
                                                    "bg-green": team.status === ETeamStatus.ACTIVE,
                                                    "bg-secondary": team.status === ETeamStatus.PAUSED,
                                                    "bg-light-dark": team.status === ETeamStatus.CANCELED
                                                })}
                                            >
                                                {t(ETeamStatusTranslated[team.status])}
                                            </small>
                                            </a>
                                        </TableCell>
                                        )}
                                        {!currentUserService.isParent(this.state.currentUser) && (
                                            <TableCell className="overflowHidden pr-4" align="left" style={{'backgroundColor':team.color}}>
                                                {team.coaches && team.coaches[0] ?
                                                    <div className={classes.coachContainer}>

                                                        <a className={classes.coachNameTeam}
                                                           onClick={() => this.goToUserDetails(team)}>
                                                            {this.getCoachNameFromTeam(team)}
                                                        </a>
                                                        {this.isTeamEditable(team) && this.getCoachNameFromTeam(team) !== '' && (
                                                            <IconButton className={classes.changeCoach} title={t('team.changeCoach.label')}
                                                                        onClick={() => this.setDisplayedCoachForTeam(team)}>
                                                                <Icon className={classes.changeCoachIcon}>manage_accounts</Icon>
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                    : ''
                                                }
                                            </TableCell>
                                        )}
                                        {!currentUserService.isParent(this.state.currentUser) && (
                                            <TableCell className="overflowHidden pr-4" align="center" style={{'backgroundColor':team.color}}>
                                                <a className="text-white"
                                                   style={{cursor:'pointer'}}
                                                   onClick={() => this.handleViewClick(team.teamId)}
                                                >
                                                    {' '}{team.athletes.length}
                                                </a>

                                            </TableCell>
                                        )}
                                        {currentUserService.isParent(this.state.currentUser) && (
                                            <TableCell className="pl-sm-24 capitalize text-white" align="left" style={{'backgroundColor':team.color}}>
                                                {this.state.parentUser && this.getAthletesFromTeam(team).map(athlete => (
                                                    <a key={athlete.id}
                                                       className="text-white"
                                                       style={{cursor:'pointer'}}
                                                       onClick={() => this.handleViewAthleteClick(athlete.id)}
                                                    >
                                                        {' '}{athlete.firstName}
                                                    </a>
                                                ))}
                                            </TableCell>
                                        )}

                                        {(!currentUserService.isPresidentManager(this.state.currentUser) && !currentUserService.isMainCoach(this.state.currentUser)) && (
                                        <TableCell className="pl-sm-24 capitalize text-white" align="center" style={{'backgroundColor':team.color}}>
                                            <span title={t('actions.viewDetails')}>
                                                <IconButton
                                                    className="text-primary"
                                                    onClick={() => this.handleViewClick(team.teamId)}>
                                                    <Icon>arrow_forward_ios</Icon>
                                                </IconButton>
                                            </span>
                                        </TableCell>
                                        )}

                                        {(currentUserService.isPresidentManager(this.state.currentUser) || currentUserService.isMainCoach(this.state.currentUser)) && (
                                        <TableCell className="pl-sm-24 capitalize text-white" align="center" style={{'backgroundColor':team.color}}>
                                                <span title={team.status !== ETeamStatus.CANCELED ? t('subscriptionType.labels.deleteSubscription'): t('subscriptionType.labels.delete')}>
                                                    <IconButton
                                                        style={{color: "red", pointerEvents: 'auto'}}
                                                        classes={{disabled: classes.disabledButton}}
                                                        disabled={team.status !== ETeamStatus.CANCELED}
                                                        onClick={() => this.handleDeleteTeam(team)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </span>
                                        </TableCell>
                                        )}

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    { !this.state.counterLoading && (
                        <TablePagination
                            className="px-16"
                            rowsPerPageOptions={[ERowsPerPage.MIN, ERowsPerPage.MID, ERowsPerPage.MAX]}
                            component="div"
                            count={this.props.team.count}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={ t('table.pagination.title') }
                            page={page}
                            backIconButtonProps={{
                                "aria-label": t('table.pagination.previous')
                            }}
                            nextIconButtonProps={{
                                "aria-label": t('table.pagination.next')
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    )}

                    {shouldOpenConfirmationDialog && (
                        <NotifyDialog
                            showConfirm={true}
                            open={shouldOpenConfirmationDialog}
                            onConfirmDialogClose={this.handleDialogClose}
                            onYesClick={this.handleConfirmationResponse}
                            text={t('team.confirmation.delete', {"name": this.state.team.name})}
                        />
                    )}
                    {shouldOpenWarningDialog && this.state.warningText && (
                        <NotifyDialog
                            open={shouldOpenWarningDialog}
                            onConfirmDialogClose={this.handleWarningDialogClose}
                            onYesClick={this.handleWarningProceedResponse}
                            text={this.state.warningText}
                            title = {t('notifyDialog.title.warning')}
                            proceedLabel = {t('notifyDialog.label.proceed')}
                            declineLabel = {t('notifyDialog.label.cancel')}
                        />
                    )}
                        {this.state.shouldShowCoachesDialog && (
                            <SelectDialog
                                list = {this.state.coaches}
                                listRetrieved = {this.state.coachesRetrieved}
                                title = {t('team.changeCoach.title', {team: this.state.selectedTeamToModifyCoach? this.state.selectedTeamToModifyCoach.name : ''})}
                                label = {'fullName'}
                                small = {'roleName'}
                                emptyMessage = {t('team.noCoaches')}
                                getList = {this.getCoachesToDisplay.bind(this)}
                                handleClose={this.handleCoachesDialogClose}
                                handleModifyAction = {this.changeTeamCoach}
                                selected={this.state.selectedTeamToModifyCoach ? this.getCoachFromTeam(this.state.selectedTeamToModifyCoach) : null}
                                open={this.state.shouldShowCoachesDialog}
                            />
                        )}
                    <CustomizedSnackbar message={this.props.team.success} severity="success" onClose={this.props.eraseMessage}/>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getTeams: PropTypes.func.isRequired,
    team: state.team,
    user: state.user
});

export default withStyles(styles, { withTheme: true })(
    connect(
        mapStateToProps,
        { getTeams, getTeamsCount, eraseMessage, setUserData, updateTeamProperties}
    )(withTranslation()(Teams))
);

