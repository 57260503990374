import Auditable from "../core/Auditable";

class AthleteObservation extends Auditable {
    constructor(data) {
        super(data);
        this.id = data.id;
        this.athleteFile = data.athleteFile;
        this.type = data.type;
        this.status = data.status;
        this.observationMessage = data.observationMessage;
        this.author = data.author;
        this.dataIds = data.dataIds;
    }
}

export default AthleteObservation;
