import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class eventExpensesRepository {

    /**
     * Get eventExpenses
     * @returns {Promise}
     * @param query
     */
    static getEventExpenses(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/${helperService.serialize(query)}`
        );
    }

    static getEventExpensesMobile(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/mobile/${helperService.serialize(query)}`
        );
    }

    /**
     * Get eventExpenses by id
     * @returns {Promise}
     * @param id
     */
    static getEventExpensesById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/${id}`
        );
    }


    /**
     * Delete eventExpenses type
     * @returns {Promise}
     */
    static deleteEventExpenses(eventExpenses) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventExpenses/`, {
            method: 'DELETE',
            body: JSON.stringify(eventExpenses)
        });
    }

    /**
     * Cancel event payment
     * @returns {Promise}
     */
    static cancelEventPayment(eventPaymentId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventPayment/cancel/${eventPaymentId}`, {
            method: 'PUT'
        });
    }

    /**
     * Get event expenses count
     * @returns {Promise}
     */
    static getEventExpensesCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Cancel event expenses
     * @returns {Promise}
     */
    static cancelEventExpenses(eventExpensesId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventExpenses/cancel/${eventExpensesId}`, {
            method: 'PUT'
        });
    }

    /**
     * Download Event Expenses receipt
     * @returns {Promise}
     * @param query
     */
    static downloadAllEventExpensesReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/eventExpenses/receipt/generation/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Get event expenses total amount
     * @returns {Promise}
     */
    static getEventExpensesTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/totalAmount/${helperService.serialize(query)}`
        );
    }
}
