import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class sportRepository {

    /**
     * Get sports
     * @returns {Promise}
     */
    static getSports(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/sport/${helperService.serialize(query)}`
        );
    }

    /**
     * Get sport by id
     * @returns {Promise}
     * @param id
     */
    static getSportById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/sport/${id}`
        );
    }

    /**
     * Add new Sport
     * @returns {Promise}
     */
    static addSport(sport) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/sport/`, {
            method: 'POST',
            body: JSON.stringify(sport)
        });
    }

    /**
     * Update Sport
     * @returns {Promise}
     */
    static updateSport(sport) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/sport/`, {
            method: 'PUT',
            body: JSON.stringify(sport)
        });
    }

    /**
     * Delete Sport
     * @returns {Promise}
     */
    static deleteSport(sport) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/sport/`, {
            method: 'DELETE',
            body: JSON.stringify(sport)
        });
    }

}
