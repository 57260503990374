import subscriptionService from "../../shared/services/subscriptionService";

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_SUBSCRIPTION_LIST_TIMESTAMP = "GET_SUBSCRIPTION_LIST_TIMESTAMP";
export const GET_SUBSCRIPTIONS_COUNT = "GET_SUBSCRIPTIONS_COUNT";
export const GET_SUBSCRIPTIONS_COUNT_TIMESTAMP = "GET_SUBSCRIPTIONS_COUNT_TIMESTAMP";
export const SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";

export function getSubscription(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_SUBSCRIPTION_LIST_TIMESTAMP,
            data: currentTime
        });
        return subscriptionService
            .getSubscription(query)
            .then(( data ) => {
                dispatch({
                    type: GET_SUBSCRIPTION_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getSubscriptionCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_SUBSCRIPTIONS_COUNT_TIMESTAMP,
            data: currentTime
        });
        return subscriptionService
            .getSubscriptionCount(countMap)
            .then(( data ) => {
                dispatch({
                    type: GET_SUBSCRIPTIONS_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
