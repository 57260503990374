import {
  ERASE_MESSAGE, ATHLETE_LOADING, SAVE_ERROR, SAVE_SUCCESS, GET_ATHLETE_LIST, GET_ATHLETE_LIST_TIMESTAMP,
  GET_ATHLETES_COUNT, GET_ATHLETES_COUNT_TIMESTAMP
} from "../actions/AthleteActions";

const initialState = {
  loading: false,
  success: null,
  error: null,
  athleteList: [],
  getAthletesTimestamp: null,
  count: 0,
  getAthletesCountTimestamp: null
};

const AthleteReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATHLETE_LIST_TIMESTAMP: {
      return {
        ...state,
        getAthletesTimestamp: action.data
      };
    }
    case GET_ATHLETE_LIST: {
      if (action.data.time === state.getAthletesTimestamp) {
        return {
          ...state,
          athleteList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_ATHLETES_COUNT_TIMESTAMP: {
      return {
        ...state,
        getAthletesCountTimestamp: action.data
      };
    }
    case GET_ATHLETES_COUNT: {
      if (action.data.time === state.getAthletesCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case ATHLETE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default AthleteReducer;
