import React, { Component } from "react";
import {Button, Dialog, Icon, IconButton, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import i18n from "../../../../i18n";

const styles = () => ({
    option:{
        fontSize: '16px'
    }
});

class DeletePlanDialog extends Component {

    state = {
        selectedValue: null
    };

    handleChange = event => {
        event.persist();
        this.setState({
            selectedValue: event.target.value
        })
    };

    render () {
        let { open, handleClose, onYesClick, t } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                    <h4 className="m-0 text-white">{this.props.title}</h4>
                    <IconButton onClick={handleClose}>
                        <Icon className="text-white">clear</Icon>
                    </IconButton>
                </div>
                <div className="p-16">
                    <p>{this.props.content}</p>
                    <div className="flex flex-space-between pt-8">
                        <Button onClick={onYesClick} variant="contained" style={{background: "#417505", color: "white"}}>
                            {i18n.t('notifyDialog.label.yes')}
                        </Button>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            style={{background: "#D0021B", color: "white"}}
                        >
                            {i18n.t('notifyDialog.label.no')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
        (withTranslation()(DeletePlanDialog))
);

