import TrainingPlan from "./TrainingPlan";

class Document extends TrainingPlan {
    constructor(data) {
        super(data);
        this.visibleForUsers = data.visibleForUsers;
        this.comment = data.comment;
        this.link = data.link;
    }
}

export default Document;