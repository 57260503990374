import {authRoles} from "../../auth/authRoles";
import {EUserType} from "../enums/EUserType";

export class userUtilsService {

    static getRoleName = (role, t) =>{
        switch (role) {
            case authRoles.moderator:
                return t('role.moderator');
            case authRoles.clubManager:
                return t('role.clubManager');
            case authRoles.mainCoach:
                return t('role.mainCoach');
            case authRoles.competitionManager:
                return t('role.competitionManager');
            case authRoles.teamManager:
                return t('role.teamManager');
            case authRoles.coach:
                return t('role.coach');
            case authRoles.doctor:
                return t('role.doctor');
            case authRoles.physicalTrainer:
                return t('role.physicalTrainer');
            case authRoles.athlete:
                return t('role.athlete');
            case authRoles.parent:
                return t('role.parent');
            default:
                return '';
        }

    };

    static getUserTypeForRole = (role) =>{
        switch (role) {
            case authRoles.moderator:
                return EUserType.moderator;
            case authRoles.clubManager:
                return EUserType.clubManager;
            case authRoles.mainCoach:
                return EUserType.mainCoach;
            case authRoles.competitionManager:
                return EUserType.competitionManager;
            case authRoles.teamManager:
                return EUserType.teamManager;
            case authRoles.coach:
                return EUserType.coach;
            case authRoles.doctor:
                return EUserType.doctor;
            case authRoles.physicalTrainer:
                return EUserType.physicalTrainer;
            case authRoles.athlete:
                return EUserType.athlete;
            case authRoles.parent:
                return EUserType.parent;
            default:
                return '';
        }

    };

    static getInviteDefaultUserType = (role) => {

        switch (role) {
            case authRoles.admin:
                return EUserType.moderator;
            case authRoles.moderator:
                return EUserType.mainCoach;
            case authRoles.clubManager:
                return EUserType.mainCoach;
            case authRoles.mainCoach:
                return EUserType.coach;
            case authRoles.competitionManager:
                return EUserType.coach;
            case authRoles.teamManager:
                return EUserType.athlete;
            case authRoles.coach:
                return EUserType.athlete;
            default:
                return '';
        }
    };

    static getInviteUserTypes = (role) => {

        switch (role) {
            case authRoles.admin:
                return [EUserType.moderator];
            case authRoles.moderator:
                return [EUserType.clubManager, EUserType.mainCoach, EUserType.competitionManager, EUserType.coach, EUserType.teamManager, EUserType.doctor, EUserType.physicalTrainer,  EUserType.athlete, EUserType.parent];
            case authRoles.clubManager:
                return [EUserType.mainCoach, EUserType.competitionManager, EUserType.coach, EUserType.teamManager, EUserType.doctor, EUserType.physicalTrainer,  EUserType.athlete, EUserType.parent];
            case authRoles.mainCoach:
                return [EUserType.coach, EUserType.teamManager, EUserType.doctor, EUserType.physicalTrainer,  EUserType.athlete, EUserType.parent];
            case authRoles.competitionManager:
                return [EUserType.coach, EUserType.teamManager, EUserType.doctor, EUserType.physicalTrainer,  EUserType.athlete, EUserType.parent];
            case authRoles.teamManager:
                return [EUserType.athlete, EUserType.parent];
            case authRoles.coach:
                return [EUserType.athlete, EUserType.parent];
            default:
                return [];
        }
    };

    static getPresidentUserType = () => {
        return [EUserType.moderator, EUserType.clubManager]
    };

    static getMainCoachUserType = () => {
        return [EUserType.mainCoach, EUserType.competitionManager]
    };

    static getCoachUserType = () => {
        return [EUserType.coach, EUserType.teamManager, EUserType.doctor, EUserType.physicalTrainer]
    }

    static getAthleteUserType = () => {
        return [EUserType.athlete]
    }

    static getAdminRole = () => {
        return [authRoles.admin]
    };

    static getAllRoles = () => {
        return [authRoles.admin, authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer, authRoles.parent, authRoles.athlete];
    };

    static getAllClubRoles = () => {
        return [authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer, authRoles.parent, authRoles.athlete];
    };

    static getClubAllExceptAthleteRoles = () => {
        return [authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer, authRoles.parent];
    };

    static getClubSportRoles = () => {
        return [authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager];
    };

    static getClubInviteRoles = () => {
        return [authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager];
    };

    static getClubStaffRoles = () => {
        return [authRoles.moderator, authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer];
    };

    static getClubAllCoachRoles = () => {
        return [authRoles.clubManager, authRoles.mainCoach, authRoles.competitionManager, authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer];
    };

    static getClubCoachRoles = () => {
        return [authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer];
    };

    static getClubCoachTeamRoles = () => {
        return [authRoles.coach, authRoles.teamManager];
    };

    static getClubMainCoachRoles = () => {
        return [authRoles.mainCoach, authRoles.competitionManager];
    };

    static getClubPresidentManagerRoles = () => {
        return [authRoles.moderator, authRoles.clubManager];
    };

    static getClubPresidentRole = () => {
        return [authRoles.moderator];
    };

    static getClubManagerRole = () => {
        return [authRoles.clubManager];
    };

    static getClubAthleteParentRoles = () => {
        return [authRoles.parent, authRoles.athlete];
    };

    static getClubPhysicalTrainer =() => {
        return [authRoles.physicalTrainer];
    };

    static getClubParentRole = () => {
        return [authRoles.parent]
    };

    static isMainCoachOrCoachRole = (role) => {
        return [authRoles.mainCoach, authRoles.coach].includes(role);
    };

    static rolePriority = (role) => {
        switch (role) {
            case authRoles.admin:
                return 0;
            case authRoles.moderator:
                return 1;
            case authRoles.clubManager:
                return 2;
            case authRoles.mainCoach:
                return 3;
            case authRoles.competitionManager:
                return 3;
            case authRoles.teamManager:
                return 4;
            case authRoles.coach:
                return 4;
            case authRoles.doctor:
                return 4;
            case authRoles.physicalTrainer:
                return 4;
            case authRoles.parent:
                return 5;
            case authRoles.athlete:
                return 6;

            default:
                return 100;
        }
    }

    static isParentOrAthleteRole = (role) => {
        return [authRoles.parent, authRoles.athlete].includes(role);
    };

    static isCoachRole = (role) => {
        return [authRoles.coach, authRoles.teamManager, authRoles.doctor, authRoles.physicalTrainer].includes(role);
    };

    static isCoachTeamRole = (role) => {
        return [authRoles.coach, authRoles.teamManager].includes(role);
    };

    static isPhysicalTrainer = (role) => {
        return [authRoles.physicalTrainer].includes(role);
    };
}
