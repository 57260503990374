import React, { Component } from "react";
import {Dialog, IconButton, Button, Icon, withStyles, CircularProgress} from "@material-ui/core";
import "date-fns";
import i18n from "../../../i18n";
import Grid from "@material-ui/core/Grid";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

const styles = () => ({
    loader: {
        marginTop: "5vh",
        marginBottom: "5vh"
    },
    wrapper: {
        position: "relative",
        textAlign: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class AddTestResultsDialog extends Component {
    state = {
        valueData: null
    };

    componentDidMount() {
        if(this.props.testResultDataToEdit !== null) {
            this.setState({
                valueData: this.props.testResultDataToEdit.value
            });
        }
    }

    handleAddAction = () => {
        this.props.addValueData(this.state.valueData);
    };

    handleDataChange = event => {
        event.persist();

        this.setState({
            ...this.state,
            valueData: event.target.value
        });
    };

    render() {
        let {valueData} = this.state;
        let { classes, open, handleClose, loading} = this.props;

        return (
            <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth={true}>
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                    <h4 className="m-0 text-white"> {i18n.t(this.props.testResultDataToEdit !== null ? 'actions.edit' : 'actions.add')} {i18n.t('menu.test.result.title')}</h4>
                    <IconButton onClick={handleClose}>
                        <Icon className="text-white">clear</Icon>
                    </IconButton>
                </div>

                <div className="p-16">
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleAddAction}
                    >
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextValidator
                                className="mb-16 w-100"
                                label={i18n.t("form.value")}
                                onChange={this.handleDataChange}
                                type="text"
                                name="valueData"
                                value={valueData ? (valueData || '') : ''}
                                validators={[
                                    "matchRegexp:^(?:[1-9]\\d*|0)?(?:\\.\\d+)?$"
                                ]}
                                errorMessages={[i18n.t('validation.error.double')]}
                            />
                        </Grid>
                    </Grid>
                    <div className="flex flex-space-between flex-middle">
                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                            {this.props.buttonLabel ? this.props.buttonLabel : i18n.t('actions.add')}
                            {(loading) && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Button>
                    </div>
                    </ValidatorForm>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    (AddTestResultsDialog)
);
