import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athleteRegisterFileRepository {
    /**
     * Get athleteRegisterFiles
     * @returns {Promise}
     * @param query
     */
    static getAthleteRegisterFiles(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteRegisterFile/mobile/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athleteRegisterFile by id
     * @returns {Promise}
     * @param id
     */
    static getAthleteRegisterFileById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteRegisterFile/${id}`
        );
    }

    /**
     * Get athleteRegisterFile by athlete id
     * @returns {Promise}
     * @param athleteId
     */
    static getAthleteRegisterFileByAthleteId(athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteRegisterFile/athlete/${athleteId}`
        );
    }

    /**
     * Get athleteRegisterFiles count
     * @returns {Promise}
     */
    static getAthleteRegisterFilesCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteRegisterFile/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Update athleteRegisterFile
     * @returns {Promise}
     */
    static updateAthleteRegisterFile(athleteRegisterFile) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteRegisterFile`, {
            method: 'PUT',
            body: JSON.stringify(athleteRegisterFile)
        });
    }

}

