class Token {
    constructor(data) {
        if(data) {
            this.exp = data.exp;
            this.iat = data.iat;
            this.sub = data.sub;
            this.userProperties = data.userProperties;
        }
    }
}

export default Token;
