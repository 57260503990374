import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const ClubFinancialHistory = EgretLoadable({
  loader: () => import("./ClubFinancialHistory")
});


const clubFinancialHistoryRoutes = [
  {
    path: "/club/financialHistory/:id",
    component: ClubFinancialHistory,
    exact: true,
    auth: userUtilsService.getClubPresidentManagerRoles()
  },
  {
    path: "/club/financialHistory/addBonus",
    // component: ClubAddBonus,
    exact: true,
    auth: userUtilsService.getClubPresidentManagerRoles()
  }
];

export default clubFinancialHistoryRoutes;
