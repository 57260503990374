export const ERowsPerPage = {
    MIN: 5,
    MID: 10,
    MAX: 20
};

export const ERowsPerPageBigList = {
    MIN: 20,
    MID: 40,
    MAX: 100
};

