import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class teamRepositoryMobile {

    /**
     * Get teams
     * @returns {Promise}
     * @param query
     */
    static getTeams(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/mobile/${helperService.serialize(query)}`
        );
    }

    /**
     * Get team by id
     * @returns {Promise}
     * @param id
     */
    static getTeamById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/mobile/${id}`
        );
    }

    /**
     * Get team by club id
     * @returns {Promise}
     * @param id
     * @param summary
     */
    static getTeamsByClubId(id, summary) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/club/mobile/${id}?summary=${summary}`,{}, false
        );
    }
}
