import {
    EXTRA_NOTIFICATION_LOADING,
    ERASE_MESSAGE, GET_EXTRA_NOTIFICATION_LIST, SAVE_ERROR, SAVE_SUCCESS, DELETE_SUCCESS, DELETE_ERROR
} from "../actions/ExtraNotificationActions";

const initialState = {
    success: null,
    error: null,
    extraNotificationsList: [],
};

const ExtraNotificationReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_EXTRA_NOTIFICATION_LIST: {
            return {
                ...state,
                extraNotificationsList: action.data
            };
        }
        case EXTRA_NOTIFICATION_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case SAVE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case SAVE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case DELETE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case DELETE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case ERASE_MESSAGE: {
            return {
                ...state,
                error: null,
                success: null
            };
        }
        default: {
            return state;
        }
    }
};

export default ExtraNotificationReducer;
