import User from "../core/User";
import {helperService} from "../../services/helperService";

class Parent extends User {
    constructor(data) {
        super(data);
        if(data.athletes)
            this.athletes = data.athletes;
        if (data.birthDate) {
            this.birthDate = helperService.formatDateMongo(data.birthDate);
        }
        if (data.enrollmentDate) {
            this.enrollmentDate = helperService.formatDateMongo(data.enrollmentDate);
        }
    }
}

export default Parent;
