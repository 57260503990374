import {coachRepository} from "../repositories/coachRepository";
import {ETeamStatus} from "../enums/ETeamStatus";

class CoachService {

    getCoaches = (query) => {
        return coachRepository.getCoaches(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getCoachesCount = (query) => {
        return coachRepository.getCoachesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getCoachById = (id) => {
        return coachRepository.getCoachById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getCoachByAccessUserId = (id) => {
        return coachRepository.getCoachByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateCoach = (coach) => {
        return coachRepository.updateCoach(coach);
    };

    deleteCoach = (coach) => {
        return coachRepository.deleteCoach(coach);
    };

    updateMainCoachOption = (id, mainCoach) => {
        return coachRepository.updateMainCoachOption(id, mainCoach);
    };

    updateCoachProperties = (coachRequest) => {
        return coachRepository.updateCoachProperties(coachRequest).catch(error => {
            window.location.reload();
        });
    };

    updateTeamsForClubManager = (coaches, teams, sport) => {
        for(let coach of coaches){
            if(coach.clubManager){
                let sportTeams = [];
                for(const team of teams) {
                    if(team.status ===ETeamStatus.ACTIVE && team.sport.id === sport.id){
                        sportTeams.push(team);
                    }
                    coach.teams = sportTeams;
                }
            }
        }
        return coaches;
    }

}

export default new CoachService();
