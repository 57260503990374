import {sportsArenaRepository} from "../repositories/sportsArenaRepository";

class SportsArenaService {

    getSportsArena = (query) => {
        return sportsArenaRepository.getSportsArena(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSportsArenaById = (id) => {
        return sportsArenaRepository.getSportsArenaById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addSportsArena = (sportsArena) => {
        return sportsArenaRepository.addSportsArena(sportsArena);
    };

    updateSportsArena = (sportsArena) => {
        return sportsArenaRepository.updateSportsArena(sportsArena);
    };

    deleteSportsArena = (sportsArena) => {
        return sportsArenaRepository.deleteSportsArena(sportsArena);
    };

    checkForEvents = (id) => {
        return sportsArenaRepository.checkForEvents(id);
    };

}

export default new SportsArenaService();
