import Auditable from "../core/Auditable";

class MedicalVisa extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.appointmentDate = new Date(data.appointmentDate);
            this.expirationDate = new Date(data.expirationDate);
            this.medicalVisaStatus = data.medicalVisaStatus;
        }
    }
}

export default MedicalVisa;

