import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class testPlanRepository {

    /**
     * Add test plan
     * @returns {Promise}
     * @param testPlan
     * @param clubId
     */
    static addTestPlan(testPlan, clubId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/testPlan?clubId=` + clubId, {
            method: 'POST',
            body: JSON.stringify(testPlan)
        });
    };

    /**
     * Delete test plan
     * @returns {Promise}
     */
    static deleteTestPlan(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/testPlan?id=` + id, {
            method: 'DELETE'
        });
    }

    /**
     * Get test plans
     * @returns {Promise}
     * @param query
     */
    static getTestPlans(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/testPlan/${helperService.serialize(query)}`
        );
    }

    /**
     * Update test plan
     * @returns {Promise}
     */
    static updateTestPlan(testPlan) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/testPlan`, {
            method: 'PATCH',
            body: JSON.stringify(testPlan)
        });
    }

}
