import {feedbackRepository} from "../repositories/feedbackRepository";

class FeedbackService {

    sendFeedback = (userId, feedback) => {
        return feedbackRepository.sendFeedback({userId, feedback})
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new FeedbackService();
