import TrainingPlan from "./TrainingPlan";

class ExtraNotification extends TrainingPlan {
    constructor(data) {
        super(data);
        this.visibleForUsers = data.visibleForUsers;
        this.visibleForTeamIds = data.visibleForTeamIds;
        this.comment = data.comment;
        this.notificationSent = data.notificationSent;
    }
}

export default ExtraNotification;