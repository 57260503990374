import publicService from "../../shared/services/publicService";
import i18n from "../../../i18n";
import {EClubResponseCodes, EResponseCodes} from "../../shared/enums/ERespondeCodes";
import clubService from "../../shared/services/clubService";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const CLUB_LOADING = "CLUB_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const CLUB_NOT_LOADING = "CLUB_NOT_LOADING";

export function addClub(club) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        return publicService
            .addClub(club)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EClubResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addModifyLogo(file, clubId) {
    return dispatch => {
        dispatch({
            type: CLUB_LOADING
        });
        clubService
            .addModifyLogo(file, clubId)
            .then(() => {
                return dispatch({
                    type: CLUB_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: error.message
                });
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
