import {eventExpensesRepository} from "../repositories/eventExpensesRepository";
import {subscriptionRepository} from "../repositories/subscriptionRepository";

class EventExpensesService {

    getEventExpenses = (query) => {
        return eventExpensesRepository.getEventExpenses(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getEventExpensesById = (id) => {
        return eventExpensesRepository.getEventExpensesById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    deleteEventExpenses = (eventExpenses) => {
        return eventExpensesRepository.deleteEventExpenses(eventExpenses);
    };

    cancelEventPayment = (eventPaymentId) => {
        return eventExpensesRepository.cancelEventPayment(eventPaymentId);
    };

    getEventExpensesCount = (query) => {
        return eventExpensesRepository.getEventExpensesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    cancelEventExpenses(eventExpensesId) {
        return eventExpensesRepository.cancelEventExpenses(eventExpensesId);
    };
    getEventExpensesTotalAmount = (query) => {
        return eventExpensesRepository.getEventExpensesTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export default new EventExpensesService();
