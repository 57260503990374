import ContactInfo from "./ContactInfo";

class Person extends ContactInfo {
    constructor(data){
        super(data);
        if (data) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.sex = data.sex;
            this.cnp = data.cnp;
            this.nationality = data.nationality;
        }
    }
}

export default Person;
