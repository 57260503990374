import {
  ERASE_MESSAGE, GET_TEAM_LIST, GET_TEAM_LIST_TIMESTAMP,
  TEAM_LOADING, SAVE_ERROR, SAVE_SUCCESS, TEAM_NOT_LOADING,
  GET_TEAM_COUNT_TIMESTAMP, GET_TEAM_COUNT,
} from "../actions/TeamActions";

const initialState = {
  teamList: [],
  loading: false,
  success: null,
  error: null,
  getTeamsTimestamp: null,
  count: 0,
  getTeamsCountTimestamp: null
};

const TeamReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_LIST_TIMESTAMP: {
      return {
        ...state,
        getTeamsTimestamp: action.data
      };
    }
    case GET_TEAM_LIST: {
      if (action.data.time === state.getTeamsTimestamp) {
        return {
          ...state,
          teamList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_TEAM_COUNT_TIMESTAMP: {
      return {
        ...state,
        getTeamsCountTimestamp: action.data
      };
    }
    case GET_TEAM_COUNT: {
      if (action.data.time === state.getTeamsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case TEAM_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    case TEAM_NOT_LOADING: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default TeamReducer;
