import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import NotificationPdfReader from "./NotificationPdfReader";

const ExtraNotifications = EgretLoadable({
    loader: () => import("./ExtraNotifications")
});

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const extraNotificationsRoutes = [
    {
        path: "/office/notification",
        exact: true,
        component: ExtraNotifications,
        auth: userUtilsService.getAllClubRoles()
    },
    {
        path: "/office/notification/pdf/:id",
        component: NotificationPdfReader,
        auth: userUtilsService.getAllClubRoles(),
        settings
    }
];

export default extraNotificationsRoutes;
