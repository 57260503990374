import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";


const Activities = EgretLoadable({
  loader: () => import("./Activities")
});

const Summary = EgretLoadable({
  loader: () => import("./Summary")
});

const dashboardRoutes = [
  {
    path : "/dashboard/activities",
    component: Activities,
    auth: userUtilsService.getAllRoles()
  },
  {
    path: "/dashboard/summary",
    component: Summary,
    auth: userUtilsService.getAllRoles()
  }
];

export default dashboardRoutes;
