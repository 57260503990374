class GameEquipment {
    team = null;
    colors = null;
    gameNumber = null;
    equipmentStatus = null;
    date = null;
    size = null;
    cost = null;
    currency = null;
    constructor(data) {
        this.team = data.team;
        this.colors = data.colors;
        this.gameNumber = data.gameNumber;
        this.equipmentStatus = data.equipmentStatus;
        this.date = data.date;
        this.size = data.size;
        this.cost = data.cost;
        this.currency = data.currency;
    }
}

export default GameEquipment;
