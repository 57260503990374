import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const TrainingPlan = EgretLoadable({
  loader: () => import("./TestPlan")
});

const testPlanRoutes = [
  {
    path: "/plan/tests",
    exact: true,
    component: TrainingPlan,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default testPlanRoutes;
