import Popover from "@material-ui/core/Popover";
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Icon, IconButton} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
    content: {
        padding: '8px'
    },
    help: {
        color: 'white'
    },
    icon: {
        position: 'relative',
        top: '7px',
        paddingRight: '5px'
    },
    link: {
        color: 'hotpink'
    }
}));

function HelpInfo(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const {helpDetails, t} = props;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick} className={classes.help}>
                <Icon>help</Icon>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.content}>
                    <div className="p-8">
                        <span>
                            <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(helpDetails.content)}}/>
                        </span>
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default (withTranslation()(HelpInfo)
);
