import {sportRepositoryMobile} from "../repositories/sportRepositoryMobile";

class SportServiceMobile {

    getSportsByClubId = (clubId) => {
        return sportRepositoryMobile.getSportsByClubId(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new SportServiceMobile();
