import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const SubscriptionType = EgretLoadable({
  loader: () => import("./SubscriptionType")
});

const SubscriptionTypeDetails = EgretLoadable({
  loader: () => import("./SubscriptionTypeDetails")
});


const subscriptionTypeRoutes = [
  {
    path: "/subscriptionType/list",
    exact: true,
    component: SubscriptionType,
    auth: userUtilsService.getClubStaffRoles()
    //[authRoles.moderator, authRoles.mainCoach, authRoles.coach]
  },
  {
    path: "/subscriptionType/:id",
    component: SubscriptionTypeDetails,
    auth: userUtilsService.getClubStaffRoles()
    //[authRoles.moderator, authRoles.mainCoach, authRoles.coach]
  }
];

export default subscriptionTypeRoutes;
