import React, { Component } from "react";
import { Button } from "@material-ui/core";
import i18n from "../../../i18n";

class NotFound extends Component {
  state = {};

  render() {
    return (
        <div>
          <br></br>
          <br></br>
          <br></br>
          <div className="flex flex-center w-100 h-50vh">
            <div> <h2 className="text-primary">{i18n.t('404Message')}</h2></div>
          </div>

          <div className="flex flex-center flex-middle w-100 h-50vh">
            <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
              <img className="mb-32" src="/assets/images/illustrations/404.svg" alt="" />
              <Button
                className="capitalize"
                variant="contained"
                color="primary"
                onClick={() => this.props.history.goBack()}
              >
                {i18n.t('back')}
              </Button>
            </div>
          </div>

        </div>
    );
  }
}

export default NotFound;
