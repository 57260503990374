import React, {Component} from "react";
import {Dialog, FormControlLabel, Grid, Icon, IconButton, Switch, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {PaperComponent} from "../../shared/components/PaperComponent";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {purple} from "@material-ui/core/colors";

const styles = theme => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});
const PurpleSwitch = withStyles((theme) => ({
    root: {
        width: 80,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        color: purple[500],
        '&$checked': {
            transform: 'translateX(54px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: purple[500],
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: purple[500],
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `none`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


class AddTestPlanDialog extends Component {

    state = {
        testPlan: {
            orderBestResult: true
        }
    };

    componentDidMount() {
        if(this.props.editMode === true) {
            if(this.props.testPlan.orderBestResult === null){
                this.setState({
                    ...this.state,
                    testPlan: {
                        ...this.props.testPlan,
                        orderBestResult: true
                    },
                })
            }
            else
                this.setState({
                    ...this.state,
                    testPlan: this.props.testPlan
                })
        }
    }

    handleChange = event => {
        event.persist();
        const testPlan = {...this.state.testPlan};
        testPlan[event.target.name] = event.target.value;
        this.setState({testPlan});
    };
    handleOrderBestResultChange = () =>  {
        this.setState({
            ...this.state,
            testPlan: {...this.state.testPlan, orderBestResult: !this.state.testPlan.orderBestResult}
        });
    };
    render () {

        const {t, open, handleClose, classes, handleSave, handleUpdate} = this.props;

        return (
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth = "md" PaperComponent={PaperComponent}>
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary fixed-content" style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <h4 className="m-0 text-white">{this.props.editMode ? t('menu.test.plan.testPlan') + ' ' + this.props.testPlan.longName : t('menu.test.plan.addPlan.title')}</h4>
                    <div className="flex flex-space-between flex-middle">
                        <IconButton onClick={handleClose}>
                            <Icon className="text-white">clear</Icon>
                        </IconButton>
                    </div>
                </div>
                <DialogContent className="p-0">
                    <div className="p-16">
                        <ValidatorForm
                            ref="form"
                            onSubmit={() => {
                                if(this.props.editMode === true) {
                                    handleUpdate(this.state.testPlan)
                                } else {
                                    handleSave(this.state.testPlan)
                                }
                            }}
                            onError={this.handleError}
                        >
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("menu.test.plan.addPlan.longName")}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="longName"
                                        value={this.state.testPlan.longName || ''}
                                        validators={["required"]}
                                        errorMessages={[t('validation.error.required')]}
                                        id="name"
                                        InputProps={{ className: "ui-state-disabled" }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("menu.test.plan.addPlan.shortName")}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="shortName"
                                        value={this.state.testPlan.shortName || ''}
                                        validators={["required"]}
                                        errorMessages={[t('validation.error.required')]}
                                        id="name"
                                        InputProps={{ className: "ui-state-disabled" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("menu.test.plan.addPlan.description")}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="description"
                                        value={this.state.testPlan.description || ''}
                                        validators={["required"]}
                                        errorMessages={[t('validation.error.required')]}
                                        id="name"
                                        InputProps={{ className: "ui-state-disabled" }}
                                        multiline={true}
                                        rows={1}
                                        rowsMax={4}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("menu.test.plan.addPlan.uom")}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="uom"
                                        value={this.state.testPlan.uom || ''}
                                        validators={["required"]}
                                        errorMessages={[t('validation.error.required')]}
                                        id="name"
                                        InputProps={{ className: "ui-state-disabled" }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("menu.test.plan.addPlan.refValue")}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="refValue"
                                        value={this.state.testPlan.refValue || ''}
                                        validators={[ "required",  "matchRegexp:^(?:[1-9]\\d*|0)?(?:\\.\\d+)?$"]}
                                        errorMessages={[t('validation.error.required'), t('validation.error.double')]}
                                        id="name"
                                        InputProps={{ className: "ui-state-disabled" }}
                                    />
                                </Grid>
                                <Typography style={{marginLeft: "10%"}}>
                                    <label>{t('menu.test.plan.addPlan.orderBestResult')}</label>
                                    <Grid component="label" container alignItems="center" justify="center" spacing={1}>
                                        <Grid item>{t('notifyDialog.label.no')}</Grid>
                                        <Grid item>
                                                <FormControlLabel
                                                    control={<PurpleSwitch
                                                        checked={this.state.testPlan.orderBestResult} onChange={this.handleOrderBestResultChange}
                                                    />}
                                                    label={<Typography style={{color: "black"}}>{t('notifyDialog.label.yes')}</Typography>}
                                                />
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>

                            <div className={classes.wrapper} style={{"display": "flex", "justifyContent": "center", "paddingTop": "50px"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    <span className="capitalize">{t('form.actions.save')}</span>
                                </Button>
                            </div>
                        </ValidatorForm>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    team: state.team
});

export default withStyles(styles, { withTheme: true })(
    connect(
        mapStateToProps,
        {}
    )(withTranslation()(AddTestPlanDialog))
);
