import {
  SET_USER_DATA, REMOVE_USER_DATA, USER_LOGGED_OUT,
  PROFILE_PICTURE_LOADING, PROFILE_PICTURE_NOT_LOADING, PROFILE_PICTURE_ERROR, ERASE_ERROR_MESSAGE, USER_ACTIVATION_SUCCESS, USER_ACTIVATION_ERROR, ERASE_SUCCESS_MESSAGE
} from "../actions/UserActions";

const initialState = {
  loading: false,
  error: null
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case PROFILE_PICTURE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case PROFILE_PICTURE_NOT_LOADING: {
      return {
        ...state,
        loading: false
      };
    }
    case PROFILE_PICTURE_ERROR: {
      return {
        ...state,
        error: action.data,
        loading: false
      };
    }
    case ERASE_ERROR_MESSAGE: {
      return {
        ...state,
        error: null
      }
    }
    case USER_ACTIVATION_SUCCESS: {
      return {
        ...state,
        success: action.data
      }
    }
    case USER_ACTIVATION_ERROR: {
      return {
        ...state,
        error: action.data
      }
    }
    case ERASE_SUCCESS_MESSAGE: {
      return {
        ...state,
        success: null
      }
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
