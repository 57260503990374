import athleteObservationService from "../../shared/services/athleteObservationService";
import i18n from "../../../i18n";
import {EAthleteObservationResponseCodes} from "../../shared/enums/ERespondeCodes";
export const GET_ATHLETE_OBSERVATION_LIST = "GET_ATHLETE_OBSERVATION_LIST";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const ATHLETE_OBSERVATION_LOADING = "ATHLETE_OBSERVATION_LOADING";
export const GET_UNREAD_ATHLETE_OBSERVATION_COUNT = "GET_UNREAD_ATHLETE_OBSERVATION_COUNT";

export function getAthleteObservations(query) {
    return dispatch => {
        return athleteObservationService
            .getAthleteObservations(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_OBSERVATION_LIST,
                    data: data,
                    observationType: query["type"]
                });
                return Promise.resolve();
            });
    }
}


export function addAthleteObservation(athleteObservation) {
    return dispatch => {
        dispatch({
            type: ATHLETE_OBSERVATION_LOADING
        });

        return athleteObservationService
            .addAthleteObservation(athleteObservation)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EAthleteObservationResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EAthleteObservationResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}


export function updateAthleteObservation(athleteObservation) {
    return dispatch => {
        dispatch({
            type: ATHLETE_OBSERVATION_LOADING
        });
        return athleteObservationService
            .updateAthleteObservation(athleteObservation)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EAthleteObservationResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EAthleteObservationResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function initializeList() {
    return dispatch => {
        dispatch({
            type: GET_ATHLETE_OBSERVATION_LIST,
            data: []
        });
    };
}

export function getUnreadObservationsCount() {
    return dispatch => {
        return athleteObservationService
            .getUnreadObservations()
            .then(( data ) => {
                dispatch({
                    type: GET_UNREAD_ATHLETE_OBSERVATION_COUNT,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function eraseAthleteObservationMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
