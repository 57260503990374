import history from "../../../history.js";
import authService from "../../shared/services/authService";
import userService from "../../shared/services/userService";
import umService from "../../shared/services/umService";
import i18n from "../../../i18n";
import {EResponseCodes} from "../../shared/enums/ERespondeCodes";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const PROFILE_PICTURE_LOADING = "PROFILE_PICTURE_LOADING";
export const PROFILE_PICTURE_NOT_LOADING = "PROFILE_PICTURE_NOT_LOADING";
export const PROFILE_PICTURE_ERROR = "PROFILE_PICTURE_ERROR";
export const ERASE_ERROR_MESSAGE = "ERASE_ERROR_MESSAGE";
export const USER_ACTIVATION_SUCCESS = "USER_ACTIVATION_SUCCESS";
export const USER_ACTIVATION_ERROR = "USER_ACTIVATION_ERROR";
export const ERASE_SUCCESS_MESSAGE = "ERASE_SUCCESS_MESSAGE";


export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    authService.logout();

    history.push({
      pathname: "/session/signin"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

export function addModifyProfilePicture(type, file, id) {
  return dispatch => {
    dispatch({
      type: PROFILE_PICTURE_LOADING
    });
    userService
        .addModifyProfilePicture(type, file, id)
        .then(() => {
          return dispatch({
            type: PROFILE_PICTURE_NOT_LOADING
          });
        })
        .catch(error => {
          return dispatch({
            type: PROFILE_PICTURE_ERROR,
            data: error.message
          });
        });
  };
}

export function activateUserManually(userIdToActivate) {
  return dispatch => {
    return umService
        .activateUserManually(userIdToActivate)
        .then((res) => {
          dispatch({
            type: USER_ACTIVATION_SUCCESS,
            data: i18n.t(EResponseCodes[res.message])
          });
          return Promise.resolve();
        })
        .catch(error => {
          dispatch({
            type: USER_ACTIVATION_ERROR,
            data: i18n.t(EResponseCodes[error.message])
          });
          return Promise.reject();
        });
  };
}

export function eraseUserSuccessMessage() {
  return dispatch => {
    dispatch({
      type: ERASE_SUCCESS_MESSAGE
    });
  };
}

export function eraseErrorMessage() {
  return dispatch => {
    dispatch({
      type: ERASE_ERROR_MESSAGE
    });
  };
}
