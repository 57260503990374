import clubPaymentService from "../../shared/services/clubPaymentService";
import history from "../../../history";
import i18n from "../../../i18n";
import {EPaymentResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_CLUB_PAYMENT_LIST = "GET_CLUB_PAYMENT_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const CLUB_PAYMENT_LOADING = "CLUB_PAYMENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getClubPayments(query) {
    return dispatch => {
        return clubPaymentService
            .getClubPayments(query)
            .then(( data ) => {
                dispatch({
                    type: GET_CLUB_PAYMENT_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateClubPayment(clubPayment) {
    return dispatch => {
        dispatch({
            type: CLUB_PAYMENT_LOADING
        });
        return clubPaymentService
            .updateClubPayment(clubPayment)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                // history.push({pathname: "/payment/list"});
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addClubPayment(clubPayment) {
    return dispatch => {
        dispatch({
            type: CLUB_PAYMENT_LOADING
        });
        return clubPaymentService
            .addClubPayment(clubPayment)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
