import {subscriptionTypeRepository} from "../repositories/subscriptionTypeRepository";

class SubscriptionTypeService {

    getSubscriptionType = (query) => {
        return subscriptionTypeRepository.getSubscriptionType(query)
            .then((res) => {
                return res.sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSubscriptionTypeById = (id) => {
        return subscriptionTypeRepository.getSubscriptionTypeById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addSubscriptionType = (subscriptionType) => {
        return subscriptionTypeRepository.addSubscriptionType(subscriptionType);
    };

    updateSubscriptionType = (subscriptionType) => {
        return subscriptionTypeRepository.updateSubscriptionType(subscriptionType);
    };

    deleteSubscriptionType = (subscriptionType) => {
        return subscriptionTypeRepository.deleteSubscriptionType(subscriptionType);
    };

    countSubscriptionTypeActiveUsers = subscriptionTypeId => {
        return subscriptionTypeRepository.countSubscriptionTypeActiveUsers(subscriptionTypeId);
    };


}

export default new SubscriptionTypeService();
