import authService from "../../shared/services/authService";
import { setUserData } from "./UserActions";
import history from "../../../history.js";
import i18n from "../../../i18n.js";
import userService from "../../shared/services/userService";
import {userUtilsService} from "../../shared/services/userUtilsService";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const ERASE_ERROR = "ERASE_ERROR";

export function auth({ email, password, keepLogin }) {
    return dispatch => {
        dispatch({
            type: LOGIN_LOADING
        });
        const credentials = {
            email: email,
            password: password,
            keepLogin: keepLogin
        };
        authService
            .auth(credentials)
            .then(user => {
                userService.setUserDetails(user).then(updatedUser => {
                    dispatch(setUserData(updatedUser));

                    if(userUtilsService.isParentOrAthleteRole(updatedUser.role) || userUtilsService.isCoachRole(updatedUser.role)) {
                        history.push({
                            pathname: "/dashboard/summary"
                        });
                    }else {
                        history.push({
                            pathname: "/"
                        });
                    }

                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                });
            })
            .catch(error => {
                let message = i18n.t('validation.error.general');
                if (error.message === 'Error: Unauthorized') {
                    message = i18n.t('validation.error.credentials');
                } else if (error.message === 'Error: User is not active!') {
                    message = i18n.t('responseCodes.user.error.notActive');
                }
                return dispatch({
                    type: LOGIN_ERROR,
                    data: message
                });
            });
    };
}

export function eraseLoginError() {
    return dispatch => {
        dispatch({
            type: ERASE_ERROR
        });
    };
}
