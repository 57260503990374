import {authRoles} from "../../auth/authRoles";

export class currentUserService {

    static isAdmin(currentUser) {
        return currentUser && currentUser.hasRole(authRoles.admin);
    }

    static isPresident(currentUser) {
        return currentUser && currentUser.isClubPresidentRole();
    }

    static isClubManager(currentUser) {
        return currentUser && currentUser.isClubManagerRole();
    }

    static isPresidentManager(currentUser) {
        return currentUser && currentUser.isClubPresidentManagerRole();
    }

    static isCoach(currentUser) {
        return currentUser && currentUser.isClubCoachRole();
    }

    static isMainCoach(currentUser) {
        return currentUser && currentUser.isClubMainCoachRole();
    }

    static isCoachTeamManager(currentUser) {
        return currentUser && currentUser.isClubCoachTeamRole();
    }

    static isClubStaff(currentUser) {
        return currentUser && currentUser.isClubStaffRole();
    }

    static isClubInviteRole(currentUser) {
        return currentUser && currentUser.isClubInviteRole();
    }

    static isPhysicalTrainer(currentUser) {
        return currentUser && currentUser.hasRole(authRoles.physicalTrainer);
    }

    static isParent(currentUser) {
        return currentUser && currentUser.hasRole(authRoles.parent);
    }

    static isAthlete(currentUser) {
        return currentUser && currentUser.hasRole(authRoles.athlete);
    }

    static isParentAthleteOrCoach(currentUser) {
        return this.isParent(currentUser) || currentUserService.isAthlete(currentUser) || currentUserService.isCoach(currentUser);
    }

    static isParentAthlete(currentUser) {
        return this.isParent(currentUser) || currentUserService.isAthlete(currentUser);
    }

    static isClubAllRoles(currentUser) {
        return currentUser && currentUser.isClubAllRoles();
    }

    static isClubAllExceptAthleteRole(currentUser) {
        return currentUser && currentUser.isClubAllExceptAthleteRole();
    }

}
