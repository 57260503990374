import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";

const ClubSubscriptionType = EgretLoadable({
  loader: () => import("./ClubSubscriptionType")
});

const ClubSubscriptionTypeDetails = EgretLoadable({
  loader: () => import("./ClubSubscriptionTypeDetails")
});


const clubSubscriptionTypeRoutes = [
  {
    path: "/clubSubscriptionType/list",
    exact: true,
    component: ClubSubscriptionType,
    auth: [authRoles.admin]
  },
  {
    path: "/clubSubscriptionType/:id",
    component: ClubSubscriptionTypeDetails,
    auth: [authRoles.admin]
  }
];

export default clubSubscriptionTypeRoutes;
