import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class publicRepository {

    /**
     * Get sports
     * @returns {Promise}
     */
    static getSports(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/public/sport/${helperService.serialize(query)}`
        );
    }

    /**
     * Add new club
     * @returns {Promise}
     */
    static addClub(club) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/public/club/`, {
            method: 'POST',
            body: JSON.stringify(club)
        });
    }
}
