import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class eventPaymentRepository {

    /**
     * Get event Payments
     * @returns {Promise}
     * @param query
     */
    static getEventPayments(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventPayment/${helperService.serialize(query)}`
        );
    }

    /**
     * Get event payment by id
     * @returns {Promise}
     * @param id
     */
    static getEventPaymentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventPayment/${id}`
        );
    }


    /**
     * Add new event payment
     * @returns {Promise}
     */
    static addEventPayment(eventPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventPayment/`, {
            method: 'POST',
            body: JSON.stringify(eventPayment)
        });
    }

    /**
     * Update event payment
     * @returns {Promise}
     */
    static updateEventPayment(eventPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventPayment/`, {
            method: 'PUT',
            body: JSON.stringify(eventPayment)
        });
    }

    /**
     * Delete team
     * @returns {Promise}
     */
    static deleteEventPayment(eventPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/eventPayment/`, {
            method: 'DELETE',
            body: JSON.stringify(eventPayment)
        });
    }

    /**
     * Download receipt
     * @returns {Promise}
     */
    static downloadEventPaymentReceipt(eventPaymentId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/event/receipt/generation/${eventPaymentId}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Event Payments
     * @returns {Promise}
     * @param query
     */
    static downloadAllEventPaymentReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/event/receipt/generation/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Get event payments count
     * @returns {Promise}
     */
    static getEventPaymentsCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventPayment/count/${helperService.serialize(query)}`
        );
    }
    /**
     * Get event payments total amount
     * @returns {Promise}
     */
    static getEventPaymentsTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventPayment/totalAmount/${helperService.serialize(query)}`
        );
    }
}
