export const EMonths = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
};

export const EMonthsRo = {
    1: "Ianuarie",
    2: "Februarie",
    3: "Martie",
    4: "Aprilie",
    5: "Mai",
    6: "Iunie",
    7: "Iulie",
    8: "August",
    9: "Septembrie",
    10: "Octombrie",
    11: "Noiembrie",
    12: "Decembrie"
};

export const EMonthsTranslated = {
    1: 'month.1',
    2: 'month.2',
    3: 'month.3',
    4: 'month.4',
    5: 'month.5',
    6: 'month.6',
    7: 'month.7',
    8: 'month.8',
    9: 'month.9',
    10: 'month.10',
    11: 'month.11',
    12: 'month.12'
};
