import {campEventRepository} from "../repositories/campEventRepository";

class CampEventService {

    addCampEvent = (campEvent) => {
        return campEventRepository.addCampEvent(campEvent);
    };

    updateCampEvent = (campEvent) => {
        return campEventRepository.updateCampEvent(campEvent);
    };

    deleteCampEvent = (campEvent) => {
        return campEventRepository.deleteCampEvent(campEvent);
    };

}

export default new CampEventService();
