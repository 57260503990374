import {teamRepositoryMobile} from "../repositories/teamRepositoryMobile";

class TeamServiceMobile {

    getTeams = (query) => {
        return teamRepositoryMobile.getTeams(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamById = (id) => {
        return teamRepositoryMobile.getTeamById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamsByClubId = (id, summary = false) => {
        return teamRepositoryMobile.getTeamsByClubId(id, summary)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new TeamServiceMobile();
