export const EResponseCodes = {
    NO_ROLE: 'responseCodes.invitation.error.noRole',
    PRESIDENT_ALREADY_SET:'responseCodes.invitation.error.presidentSet',
    NO_SPORT:'responseCodes.invitation.error.noSport',
    NO_TEAMS:'responseCodes.invitation.error.noTeams',
    NO_ATHLETES:'responseCodes.invitation.error.noAthletes',
    INSERT_USER_ERR:'responseCodes.invitation.error.insertUserErr',
    INVITATION_SENT:'responseCodes.invitation.success.sent',
    INVITATION_NOT_SENT:'responseCodes.invitation.error.notSent',

    EMAIL_NOT_SENT:'responseCodes.email.error.notSent',
    EMAIL_SENT:'responseCodes.email.success.sent',
    EMAIL_NOT_FOUND:'responseCodes.email.error.notFound',

    EMAIL_ALREADY_IN_USE: 'responseCodes.user.error.emailInUse',
    CODE_ALREADY_IN_USE: 'responseCodes.user.error.codeInUse',
    CLUB_NAME_ALREADY_IN_USE: 'responseCodes.user.error.clubNameInUse',
    CIS_ALREADY_IN_USE: 'responseCodes.user.error.cisInUse',
    CIF_ALREADY_IN_USE: 'responseCodes.user.error.cifInUse',
    NON_EXISTENT_USER: 'responseCodes.user.error.nonExistentUser',
    USER_NOT_ACTIVE: 'responseCodes.user.error.notActive',
    REGISTER_USER_SUCCESS: 'responseCodes.user.success.register',
    NOT_PENDING_USER: 'responseCodes.user.error.notPendingAccount',
    UPDATE_USER_SUCCESS: 'responseCodes.user.success.update',
    UPDATE_USER_ERROR: 'responseCodes.user.error.update',
    UPDATE_PASSWORD_SUCCESS: 'responseCodes.user.success.passwordUpdated',
    UPDATE_PASSWORD_ERROR: 'responseCodes.user.error.passwordUpdated',
    UNMATCHED_PASSWORDS: 'responseCodes.user.error.unmatchedPasswords',
    TOKEN_NOT_FOUND: 'responseCodes.user.error.tokenNotFound',
    ALL_FIELDS_MANDATORY: 'responseCodes.user.error.allFieldsMandatory',
    MANUAL_ACTIVATION_SUCCESS: 'responseCodes.user.success.manualActivation',
    MANUAL_ACTIVATION_ERROR: 'responseCodes.user.error.manualActivation',
    PAYMENT_REMINDER_NOTIFICATION_SEND: 'responseCodes.notification.paymentReminder.success',
};

export const EClubResponseCodes = {
    CLUB_NOT_FOUND: 'responseCodes.club.error.notFound',
    CODE_ALREADY_EXISTS: 'responseCodes.club.error.duplicateCode',
    UPDATE_CLUB_SUCCESS: 'responseCodes.club.success.update',
    UPDATE_CLUB_ERROR: 'responseCodes.club.error.update',
    REGISTER_CLUB_SUCCESS: 'responseCodes.club.success.register',
    CLUB_ALREADY_EXISTS: 'responseCodes.club.error.alreadyExists'
};

export const ESportResponseCodes = {
    SPORT_NOT_FOUND: 'responseCodes.sport.error.notFound',
    UPDATE_SPORT_SUCCESS: 'responseCodes.sport.success.update',
    UPDATE_SPORT_ERROR: 'responseCodes.sport.error.update',
    REGISTER_SPORT_SUCCESS: 'responseCodes.sport.success.register',
    SPORT_ALREADY_EXISTS: 'responseCodes.sport.error.alreadyExists'
};

export const ESportsArenaResponseCodes = {
    SPORTS_ARENA_NOT_FOUND: 'responseCodes.sportsArena.error.notFound',
    CODE_ALREADY_EXISTS: 'responseCodes.sportsArena.error.duplicateCode',
    UPDATE_SPORTS_ARENA_SUCCESS: 'responseCodes.sportsArena.success.update',
    UPDATE_SPORTS_ARENA_ERROR: 'responseCodes.sportsArena.error.update',
    REGISTER_SPORTS_ARENA_SUCCESS: 'responseCodes.sportsArena.success.register',
    SPORTS_ARENA_ALREADY_EXISTS: 'responseCodes.sportsArena.error.alreadyExists'
};

export const ESubscriptionResponseCodes = {
    ID_ALREADY_EXISTS: 'responseCodes.subscription.error.duplicateId',
    UPDATE_SUBSCRIPTION_SUCCESS: 'responseCodes.subscription.success.update',
    UPDATE_SUBSCRIPTION_ERROR: 'responseCodes.subscription.error.update',
    REGISTER_SUBSCRIPTION_SUCCESS: 'responseCodes.subscription.success.register',
    SUBSCRIPTION_ALREADY_EXISTS: 'responseCodes.subscription.error.alreadyExists',
    CANCEL_SUBSCRIPTION_SUCCESS: 'responseCodes.subscription.success.cancel',
    DELETE_SUBSCRIPTION_SUCCESS: 'responseCodes.subscription.success.delete',
    DELETE_SUBSCRIPTION_ERROR: 'responseCodes.subscription.error.delete,'
};

export const ESubscriptionTypeResponseCodes = {
    SUBSCRIPTION_TYPE_NOT_FOUND: 'responseCodes.subscriptionType.error.notFound',
    CODE_ALREADY_EXISTS: 'responseCodes.subscriptionType.error.duplicateCode',
    UPDATE_SUBSCRIPTION_TYPE_SUCCESS: 'responseCodes.subscriptionType.success.update',
    UPDATE_SUBSCRIPTION_TYPE_ERROR: 'responseCodes.subscriptionType.error.update',
    REGISTER_SUBSCRIPTION_TYPE_SUCCESS: 'responseCodes.subscriptionType.success.register',
    SUBSCRIPTION_TYPE_ALREADY_EXISTS: 'responseCodes.subscriptionType.error.alreadyExists',
};

export const EExtraPaymentNoticeResponseCodes = {
    NOTICE_NOT_FOUND: 'responseCodes.extraPaymentNotice.error.notFound',
    REGISTER_NOTICE_SUCCESS: 'responseCodes.extraPaymentNotice.success.register',
    DELETE_EXTRAPAYMENTNOTICE_SUCCESS: 'responseCodes.extraPaymentNotice.success.delete'
};


export const EEventExpensesResponseCodes = {
    CANCEL_EVENT_EXPENSES_SUCCESS: 'responseCodes.eventExpenses.success.cancel'
};

export const ETeamResponseCodes = {
    TEAM_NOT_FOUND: 'responseCodes.team.error.notFound',
    TEAM_CODE_ALREADY_EXISTS: 'responseCodes.team.error.duplicateCode',
    UPDATE_TEAM_SUCCESS: 'responseCodes.team.success.update',
    UPDATE_TEAM_ERROR: 'responseCodes.team.error.update',
    REGISTER_TEAM_SUCCESS: 'responseCodes.team.success.register',
    INSERT_TEAM_ERROR: 'responseCodes.team.error.insert'
};

export const EAdminResponseCodes = {
    UPDATE_ADMIN_SUCCESS: 'responseCodes.admin.success.update',
    UPDATE_ADMIN_ERROR: 'responseCodes.admin.error.update',
    REGISTER_ADMIN_SUCCESS: 'responseCodes.admin.success.register',
    INSERT_ADMIN_ERROR: 'responseCodes.admin.error.insert'
};

export const EAthleteResponseCodes = {
    ATHLETE_NOT_FOUND: 'responseCodes.athlete.error.notFound',
    UPDATE_ATHLETE_SUCCESS: 'responseCodes.athlete.success.update',
    UPDATE_ATHLETE_ERROR: 'responseCodes.athlete.error.update',
    REGISTER_ATHLETE_SUCCESS: 'responseCodes.athlete.success.register',
    INSERT_ATHLETE_ERROR: 'responseCodes.athlete.error.insert'
};

export const EAthleteFileResponseCodes = {
    ATHLETE_FILE_NOT_FOUND: 'responseCodes.athleteFile.error.notFound',
    UPDATE_ATHLETE_FILE_SUCCESS: 'responseCodes.athleteFile.success.update',
    UPDATE_ATHLETE_FILE_ERROR: 'responseCodes.athleteFile.error.update'
};

export const EAthleteRegisterFileResponseCodes = {
    ATHLETE_REGISTER_FILE_NOT_FOUND: 'responseCodes.athleteRegisterFile.error.notFound',
    UPDATE_ATHLETE_REGISTER_FILE_SUCCESS: 'responseCodes.athleteRegisterFile.success.update',
    UPDATE_ATHLETE_REGISTER_FILE_ERROR: 'responseCodes.athleteRegisterFile.error.update'
};

export const EAthleteObservationResponseCodes = {
    ATHLETE_OBSERVATION_NOT_FOUND: 'responseCodes.athleteObservation.error.notFound',
    ADD_ATHLETE_OBSERVATION_SUCCESS: 'responseCodes.athleteObservation.success.add',
    ADD_ATHLETE_OBSERVATION_ERROR: 'responseCodes.athleteObservation.error.add',
    UPDATE_ATHLETE_OBSERVATION_SUCCESS: 'responseCodes.athleteObservation.success.update',
    UPDATE_ATHLETE_OBSERVATION_ERROR: 'responseCodes.athleteObservation.error.update'
};
export const EExtraNotificationResponseCodes = {
    EXTRA_NOTIFICATION_NOT_FOUND: 'responseCodes.extraNotification.error.notFound',
    ADD_EXTRA_NOTIFICATION_SUCCESS: 'responseCodes.extraNotification.success.add',
    ADD_EXTRA_NOTIFICATION_ERROR: 'responseCodes.extraNotification.error.add',
    UPDATE_EXTRA_NOTIFICATION_SUCCESS: 'responseCodes.extraNotification.success.add',
    UPDATE_EXTRA_NOTIFICATION_ERROR: 'responseCodes.extraNotification.error.add',
    DELETE_EXTRA_NOTIFICATION_SUCCESS: 'responseCodes.extraNotification.success.delete',
    DELETE_EXTRA_NOTIFICATION_ERROR: 'responseCodes.extraNotification.error.delete'
};

export const ECoachResponseCodes = {
    COACH_NOT_FOUND: 'responseCodes.coach.error.notFound',
    UPDATE_COACH_SUCCESS: 'responseCodes.coach.success.update',
    UPDATE_COACH_ERROR: 'responseCodes.coach.error.update',
    REGISTER_COACH_SUCCESS: 'responseCodes.coach.success.register',
    INSERT_COACH_ERROR: 'responseCodes.coach.error.insert'
};

export const EParentResponseCodes = {
    PARENT_NOT_FOUND: 'responseCodes.parent.error.notFound',
    UPDATE_PARENT_SUCCESS: 'responseCodes.parent.success.update',
    UPDATE_PARENT_ERROR: 'responseCodes.parent.error.update',
    REGISTER_PARENT_SUCCESS: 'responseCodes.parent.success.register',
    INSERT_PARENT_ERROR: 'responseCodes.parent.error.insert'
};

export const EPresidentResponseCodes = {
    PRESIDENT_NOT_FOUND: 'responseCodes.president.error.notFound',
    UPDATE_PRESIDENT_SUCCESS: 'responseCodes.president.success.update',
    UPDATE_PRESIDENT_ERROR: 'responseCodes.president.error.update',
    REGISTER_PRESIDENT_SUCCESS: 'responseCodes.president.success.register',
    INSERT_PRESIDENT_ERROR: 'responseCodes.president.error.insert'
};

export const EPaymentResponseCodes = {
    PAYMENT_NOT_FOUND: 'responseCodes.payment.error.notFound',
    UPDATE_PAYMENT_SUCCESS: 'responseCodes.payment.success.update',
    UPDATE_PAYMENT_ERROR: 'responseCodes.payment.error.update',
    REGISTER_PAYMENT_SUCCESS: 'responseCodes.payment.success.register',
    PAYMENT_ALREADY_EXISTS: 'responseCodes.payment.error.alreadyExists',
    DELETE_PAYMENT_SUCCESS: 'responseCodes.payment.delete.success',
    DELETE_PAYMENT_ERROR: 'responseCodes.payment.delete.error'
};

export const ECampResponseCodes = {
    CAMP_NOT_FOUND: 'responseCodes.camp.error.notFound',
    UPDATE_CAMP_SUCCESS: 'responseCodes.camp.success.update',
    UPDATE_CAMP_ERROR: 'responseCodes.camp.error.update'
};

export const ECampEventResponseCodes = {
    CAMP_EVENT_NOT_FOUND: 'responseCodes.campEvent.error.notFound',
    UPDATE_CAMP_EVENT_SUCCESS: 'responseCodes.campEvent.success.update',
    UPDATE_CAMP_EVENT_ERROR: 'responseCodes.campEvent.error.update',
    ADD_CAMP_EVENT_SUCCESS: 'responseCodes.campEvent.success.add',
    ADD_CAMP_EVENT_ERROR: 'responseCodes.campEvent.error.add',
    TEAMS_NOT_PROVIDED: 'responseCodes.campEvent.error.noTeams'
};

export const ECompetitionResponseCodes = {
    COMPETITION_NOT_FOUND: 'responseCodes.competition.error.notFound',
    UPDATE_COMPETITION_SUCCESS: 'responseCodes.competition.success.update',
    UPDATE_COMPETITION_ERROR: 'responseCodes.competition.error.update'
};

export const ECompetitionEventResponseCodes = {
    COMPETITION_EVENT_NOT_FOUND: 'responseCodes.competitionEvent.error.notFound',
    UPDATE_COMPETITION_EVENT_SUCCESS: 'responseCodes.competitionEvent.success.update',
    UPDATE_COMPETITION_EVENT_ERROR: 'responseCodes.competitionEvent.error.update',
    ADD_COMPETITION_EVENT_SUCCESS: 'responseCodes.competitionEvent.success.add',
    ADD_COMPETITION_EVENT_ERROR: 'responseCodes.competitionEvent.error.add',
    TEAM_NOT_PROVIDED: 'responseCodes.competitionEvent.error.noTeam',
    ATHLETE_NOT_PROVIDED: 'responseCodes.competitionEvent.error.noAthlete'
};

export const ETrainingResponseCodes = {
    TRAINING_NOT_FOUND: 'responseCodes.training.error.notFound',
    UPDATE_TRAINING_SUCCESS: 'responseCodes.training.success.update',
    UPDATE_TRAINING_ERROR: 'responseCodes.training.error.update'
};

export const ETrainingEventResponseCodes = {
    TRAINING_EVENT_NOT_FOUND: 'responseCodes.trainingEvent.error.notFound',
    UPDATE_TRAINING_EVENT_SUCCESS: 'responseCodes.trainingEvent.success.update',
    UPDATE_TRAINING_EVENT_ERROR: 'responseCodes.trainingEvent.error.update',
    ADD_TRAINING_EVENT_SUCCESS: 'responseCodes.trainingEvent.success.add',
    ADD_TRAINING_EVENT_ERROR: 'responseCodes.trainingEvent.error.add',
    TEAM_NOT_PROVIDED: 'responseCodes.trainingEvent.error.noTeam'
};
