export const ETeamStatus = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    CANCELED: 'CANCELED'
};

export const ETeamStatusTranslated = {
    ACTIVE: 'team.status.active',
    PAUSED: 'team.status.paused',
    CANCELED: 'team.status.canceled'
};
