class MonthlyAverageGradeResponse {

    constructor(data) {
        this.eventsNr = data.eventsNr;
        this.avgGrade = data.avgGrade;
    }

}

export default MonthlyAverageGradeResponse;
