export const EUserType = {
    admin: 'admin',
    moderator: 'moderator',
    clubManager: 'clubManager',
    coach: 'coach',
    teamManager: 'teamManager',
    doctor: 'doctor',
    physicalTrainer: 'physicalTrainer',
    mainCoach: 'mainCoach',
    competitionManager: 'competitionManager',
    parent: 'parent',
    athlete: 'athlete'
};

export const EUserTypeTranslated = {
    admin: 'role.admin',
    moderator: 'role.moderator',
    clubManager: 'role.clubManager',
    mainCoach: 'role.mainCoach',
    competitionManager: 'role.competitionManager',
    coach: 'role.coach',
    teamManager: 'role.teamManager',
    doctor: 'role.doctor',
    physicalTrainer: 'role.physicalTrainer',
    parent: 'role.parent',
    athlete: 'role.athlete'
};
