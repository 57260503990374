
class TeamsScreenFilters {
    type = 'Teams';

    constructor(name,
                code,
                sportId,
                status,
                coachName,
                athletesCount,
                athleteName) {
        this.name = name;
        this.code = code;
        this.sportId = sportId;
        this.status = status;
        this.coachName = coachName;
        this.athletesCount = athletesCount;
        this.athleteName = athleteName;
    }
}

export default TeamsScreenFilters;
