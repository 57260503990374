import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const EventPayments = EgretLoadable({
  loader: () => import("./EventPayments")
});

const EventPaymentDetails = EgretLoadable({
  loader: () => import("./EventPaymentDetails")
});

const eventPaymentRoutes = [
  {
    path: "/eventPayment/list",
    exact: true,
    component: EventPayments,
    auth: userUtilsService.getClubInviteRoles()
  },
  {
    path: "/eventPayment/:id",
    component: EventPaymentDetails,
    auth: userUtilsService.getClubInviteRoles()
  }
];

export default eventPaymentRoutes;
