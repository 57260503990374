import { EgretLoadable } from "../../../egret";
import {authRoles, getAllRoles} from "../../auth/authRoles";
import {userUtilsService} from "../../shared/services/userUtilsService";

const AdminDetails = EgretLoadable({
  loader: () => import("./AdminDetails")
});

const PresidentDetails = EgretLoadable({
   loader: () => import("./PresidentDetails")
});

const CoachDetails = EgretLoadable({
  loader: () => import("./CoachDetails")
});

const ParentDetails = EgretLoadable({
  loader: () => import("./ParentDetails")
});

const AthleteDetails = EgretLoadable({
  loader: () => import("./AthleteDetails")
});

const ChangePassword = EgretLoadable({
  loader: () => import("./ChangePassword")
});

const presidentRoutes = [
  {
    path: "/profile/admin/:id",
    exact: true,
    component: AdminDetails,
    auth: [authRoles.admin]
  },
  {
    path: "/profile/president/:id",
    exact: true,
    component: PresidentDetails,
    auth: [authRoles.moderator]
  },
  {
    path: "/profile/coach/:id",
    exact: true,
    component: CoachDetails,
    auth: userUtilsService.getClubAllCoachRoles()
    //[authRoles.coach, authRoles.mainCoach]
  },
  {
    path: "/profile/parent/:id",
    exact: true,
    component: ParentDetails,
    auth: [authRoles.parent]
  },
  {
    path: "/profile/athlete/:id",
    exact: true,
    component: AthleteDetails,
    auth: [authRoles.athlete]
  },
  {
    path: "/profile/change-password",
    exact: true,
    component: ChangePassword,
    auth: userUtilsService.getAllRoles()
  }
];

export default presidentRoutes;
