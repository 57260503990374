class Camp {
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.campEvent = data.campEvent;
        this.athletesStats = data.athletesStats;
        this.comments = data.comments;
        this.finalResult = data.finalResult;
        this.place = data.place;

        this.start = this.getStartDate(this.campEvent.dateStart);
        this.end = this.getStopDate(this.campEvent.dateStop);
    }

    getStartDate(d) {
        const date = this.getDate(new Date(d));
        date.setHours(0, 0);
        return date;
    }

    getStopDate(d) {
        const date = this.getDate(new Date(d));
        date.setHours(23, 59);
        return date;
    }

    getDate(date) {
        const utcStr = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return new Date(utcStr);
    }
}

export default Camp;
