import {APP_CONFIG} from "../../appConfig";
import {FetchAdapter} from "../adapters/fetchAdapter";
import {SessionService as sessionService, SessionService} from "./sessionService";

class AuthService {

    auth = async (credentials) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.auth}/signin`, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then((jwtResponse) => {
                const {userProperties} = sessionService.decodeToken(jwtResponse.accessToken);
                SessionService.saveToken(jwtResponse.accessToken);
                SessionService.setCookie(jwtResponse.accessToken);
                return userProperties;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    register = async (userProperties) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.auth}/signup`, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userProperties)
        });
    };

    logout = () => {
        SessionService.deleteToken();
        SessionService.deleteHelperDisplayed();
        SessionService.removeCookie();
    };

}

export default new AuthService();
