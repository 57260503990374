import athleteService from "../../shared/services/athleteService";
import i18n from "../../../i18n";
import {EAthleteResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_ATHLETE_LIST = "GET_ATHLETE_LIST";
export const GET_ATHLETE_LIST_TIMESTAMP = "GET_ATHLETE_LIST_TIMESTAMP";
export const GET_ATHLETES_COUNT= "GET_ATHLETES_COUNT";
export const GET_ATHLETES_COUNT_TIMESTAMP = "GET_ATHLETES_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const ATHLETE_LOADING = "ATHLETE_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getAthletes(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_LIST_TIMESTAMP,
            data: currentTime
        });
        return athleteService
            .getAthletes(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getAthletesCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return athleteService
            .getAthletesCount(countMap)
            .then((data) => {
                dispatch({
                    type: GET_ATHLETES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateAthlete(athlete) {
    return dispatch => {
        dispatch({
            type: ATHLETE_LOADING
        });
        return athleteService
            .updateAthlete(athlete)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EAthleteResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EAthleteResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseAthleteMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
