import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class extraPaymentRepository {

    /**
     * Get extra payment notices
     * @returns {Promise}
     * @param query
     */
    static getExtraPaymentNotices(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/notice/${helperService.serialize(query)}`
        );
    }

    /**
     * Get extra payment notice by id
     * @returns {Promise}
     * @param id
     */
    static getExtraPaymentNoticeById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/notice/${id}`
        );
    }

    /**
     * Add new extra payment notice
     * @returns {Promise}
     */
    static addExtraPaymentNotice(extraPaymentNotice) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment/notice/`, {
            method: 'POST',
            body: JSON.stringify(extraPaymentNotice)
        });
    }

    /**
     * Delete extra payment notice
     * @returns {Promise}
     */
    static deleteExtraPaymentNotice(extraPaymentNoticeModel) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment/notice/`, {
            method: 'DELETE',
            body: JSON.stringify(extraPaymentNoticeModel)
        });
    }

    /**
     * Get extra payment notices count
     * @returns {Promise}
     */
    static getExtraPaymentNoticesCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/notice/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Send payment reminder
     * @param extraPaymentNoticeId
     * @returns {Promise<unknown>}
     */
    static sendExtraPaymentReminder(extraPaymentNoticeId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/notification/extraPayment/notice/payment-reminder/${extraPaymentNoticeId}`, {
            method: 'POST'
        });
    }

    /**
     * Get extra payment by id
     * @returns {Promise}
     * @param id
     */
    static getExtraPaymentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/${id}`
        );
    }

    /**
     * Add new extra payment
     * @returns {Promise}
     */
    static addExtraPayment(athleteExtraPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment`, {
            method: 'POST',
            body: JSON.stringify(athleteExtraPayment)
        });
    }

    /**
     * Delete extra payment notice
     * @returns {Promise}
     */
    static deleteExtraPayment(extraPaymentNotice) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment`, {
            method: 'DELETE',
            body: JSON.stringify(extraPaymentNotice)
        });
    }

    /**
     * Update extra payment
     * @returns {Promise}
     */
    static updateExtraPayment(extraPayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment/`, {
            method: 'PUT',
            body: JSON.stringify(extraPayment)
        });
    }

    /**
     * Get athlete extra payment
     * @returns {Promise}
     * @param query
     */
    static getExtraPayments(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athlete extra payment notices
     * @returns {Promise}
     */
    static getExtraPaymentsCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Download receipt
     * @returns {Promise}
     */
    static downloadAthleteExtraPaymentReceipt(athletePaymentId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/athlete/receipt/extra/${athletePaymentId}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download All Extra Payments
     * @returns {Promise}
     * @param query
     */
    static downloadAllExtraPaymentReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/athlete/receipt/extra/generation/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download All Extra Payment Notices receipts
     * @returns {Promise}
     * @param query
     */
    static downloadAllExtraPaymentNoticeReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/athlete/receipt/extra/notice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Get extra payments total amount
     * @returns {Promise}
     */
    static getExtraPaymentsTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/totalAmount/${helperService.serialize(query)}`
        );
    }
    /**
     * Get extra payments notice total amount
     * @returns {Promise}
     */
    static getExtraPaymentsNoticeTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraPayment/notice/totalAmount/${helperService.serialize(query)}`
        );
    }
    /**
     * Add new donation
     * @returns {Promise}
     */
    static addDonation(extraPaymentNotice) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraPayment/donation/`, {
            method: 'POST',
            body: JSON.stringify(extraPaymentNotice)
        });
    }
}
