import Auditable from "../core/Auditable";

class CardSituation extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.startDate = new Date(data.startDate);
            this.endDate = new Date(data.endDate);
            this.cardStatus = data.cardStatus;
            this.reasonForPenalty = data.reasonForPenalty;
        }
    }
}

export default CardSituation;