
class ClubDatabaseFilters {
    constructor(data = {}) {
        this.teamsScreenFilters = data.teamsScreenFilters;
        this.sportArenasScreenFilters = data.sportArenasScreenFilters;
        this.parentsScreenFilters = data.parentsScreenFilters;
        this.staffScreenFilters = data.staffScreenFilters;
        this.athletesScreenFilters = data.athletesScreenFilters;
    }
}

export default ClubDatabaseFilters;
