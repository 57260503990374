import i18n from "../../../i18n";
import {EExtraNotificationResponseCodes} from "../../shared/enums/ERespondeCodes";
import extraNotificationService from "../../shared/services/extraNotificationService";
export const GET_EXTRA_NOTIFICATION_LIST = "GET_EXTRA_NOTIFICATION_LIST";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const EXTRA_NOTIFICATION_LOADING = "EXTRA_NOTIFICATION_LOADING";

export function getExtraNotifications(query) {
    return dispatch => {
        return extraNotificationService
            .getExtraNotifications(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EXTRA_NOTIFICATION_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}


export function addExtraNotification(extraNotification) {
    return dispatch => {
        dispatch({
            type: EXTRA_NOTIFICATION_LOADING
        });

        return extraNotificationService
            .addExtraNotification(extraNotification)
            .then((res) => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EExtraNotificationResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EExtraNotificationResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}


export function updateExtraNotification(extraNotification) {
    return dispatch => {
        dispatch({
            type: EXTRA_NOTIFICATION_LOADING
        });
        return extraNotificationService
            .updateExtraNotification(extraNotification)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EExtraNotificationResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EExtraNotificationResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}
export function deleteExtraNotification(extraNotificationId) {
    return dispatch => {
        dispatch({
            type: EXTRA_NOTIFICATION_LOADING
        });
        return extraNotificationService
            .deleteExtraNotification(extraNotificationId)
            .then(res => {
                dispatch({
                    type: DELETE_SUCCESS,
                    data: i18n.t(EExtraNotificationResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: DELETE_ERROR,
                    data: i18n.t(EExtraNotificationResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function initializeList() {
    return dispatch => {
        dispatch({
            type: GET_EXTRA_NOTIFICATION_LIST,
            data: []
        });
    };
}

export function eraseExtraNotificationMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
