export const ESubscriptionTypeStatus = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    CANCELED: 'CANCELED'
};

export const ESubscriptionTypeTranslated = {
    ACTIVE: 'status.active',
    PAUSED: 'status.paused',
    CANCELED: 'status.canceled'
};
