import React, { Component } from "react";
import { EgretLayouts } from "./index";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { matchRoutes } from "react-router-config";
import { connect } from "react-redux";
import AppContext from "../appContext";
import {
  setLayoutSettings,
  setDefaultSettings
} from "../redux/actions/LayoutActions";
import { isEqual, merge } from "lodash";
import { isMdScreen, getQueryParam } from "../../utils";
import CustomizedSnackbar from "../shared/components/CustomizedSnackbar";
import {eraseAdminMessage} from "../redux/actions/AdminActions";
import {eraseAthleteMessage} from "../redux/actions/AthleteActions";
import {eraseCoachMessage} from "../redux/actions/CoachActions";
import {eraseParentMessage} from "../redux/actions/ParentActions";
import {erasePresidentMessage} from "../redux/actions/PresidentActions";

class EgretLayout extends Component {
  constructor(props, context) {
    super(props);
    this.appContext = context;
    this.updateSettingsFromRouter();

    // Set settings from query (Only for demo purpose)
    this.setLayoutFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateSettingsFromRouter();
    }
  }

  componentWillMount() {
    if (window) {
      // LISTEN WINDOW RESIZE
      window.addEventListener("resize", this.listenWindowResize);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("resize", this.listenWindowResize);
    }
  }

  setLayoutFromQuery = () => {
    try {
      let settingsFromQuery = getQueryParam("settings");
      settingsFromQuery = settingsFromQuery
        ? JSON.parse(settingsFromQuery)
        : {};
      let { settings, setLayoutSettings, setDefaultSettings } = this.props;
      let updatedSettings = merge({}, settings, settingsFromQuery);

      setLayoutSettings(updatedSettings);
      setDefaultSettings(updatedSettings);
    } catch (e) {
      // console.log("Error! Set settings from query param", e);
    }
  };

  listenWindowResize = () => {
    let { settings, setLayoutSettings } = this.props;

    if (settings.layout1Settings.leftSidebar.show) {
      let mode = isMdScreen() ? "close" : "full";
      setLayoutSettings(
        merge({}, settings, { layout1Settings: { leftSidebar: { mode } } })
      );
    }
  };

  updateSettingsFromRouter() {
    const { routes } = this.appContext;
    const matched = matchRoutes(routes, this.props.location.pathname)[0];
    let { defaultSettings, settings, setLayoutSettings } = this.props;

    if (matched && matched.route.settings) {
      // ROUTE HAS SETTINGS
      const updatedSettings = merge({}, settings, matched.route.settings);
      if (!isEqual(settings, updatedSettings)) {
        setLayoutSettings(updatedSettings);
        // console.log('Route has settings');
      }
    } else if (!isEqual(settings, defaultSettings)) {
      setLayoutSettings(defaultSettings);
      // console.log('reset settings', defaultSettings);
    }
  }
  getSuccessMessage() {
    const {admin, president, coach, parent, athlete} = this.props;
    return admin.success || president.success || coach.success || parent.success || athlete.success;
  }

  onCloseSnackbar() {
    const {admin, president, coach, parent, athlete} = this.props;
    if (admin.success) {
      this.props.eraseAdminMessage();
    }
    if (president.success) {
      this.props.erasePresidentMessage();
    }
    if (coach.success) {
      this.props.eraseCoachMessage();
    }
    if (parent.success) {
      this.props.eraseParentMessage();
    }
    if (athlete.success) {
      this.props.eraseAthleteMessage();
    }

  }

  render() {
    const { settings } = this.props;
    const Layout = EgretLayouts[settings.activeLayout];

    return (
      <span>
        <Layout {...this.props} />
        <CustomizedSnackbar message={this.getSuccessMessage()} severity="success" onClose={this.onCloseSnackbar.bind(this)}/>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
  defaultSettings: state.layout.defaultSettings,
  admin: state.admin,
  president: state.president,
  coach: state.coach,
  parent: state.parent,
  athlete: state.athlete
});

EgretLayout.contextType = AppContext;

export default withRouter(
  connect(
    mapStateToProps,
    { setLayoutSettings, setDefaultSettings, eraseAdminMessage, erasePresidentMessage, eraseAthleteMessage, eraseCoachMessage, eraseParentMessage }
  )(EgretLayout)
);
