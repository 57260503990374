import {clubRepository} from "../repositories/clubRepository";

class ClubService {

    getClubs = (query) => {
        return clubRepository.getClubs(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getClubById = (id) => {
        return clubRepository.getClubById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getClubByCode = (code) => {
        return clubRepository.getClubByCode(code)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPresidentByClubId = (id) => {
        return clubRepository.getPresidentByClubId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSportsByClubId = (id) => {
        return clubRepository.getSportsByClubId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addClub = (club) => {
        return clubRepository.addClub(club);
    };

    updateClub = (club) => {
        return clubRepository.updateClub(club);
    };

    deleteClub = (club) => {
        return clubRepository.deleteClub(club);
    };

    addModifyLogo = (file, clubId) => {
        return clubRepository.addModifyLogo(file, clubId);
    };

    addModifyContract = (file, clubId) => {
        return clubRepository.addModifyContract(file, clubId);
    };

    getContract = (clubId) => {
        return clubRepository.getContract(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    addModifyStamp = (file, clubId) => {
        return clubRepository.addModifyStamp(file, clubId);
    };

    getStamp = (clubId) => {
        return clubRepository.getStamp(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getLogo = (clubId) => {
        return clubRepository.getLogo(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };


    generateInvoices = (params) => {
        return clubRepository.generateInvoices(params);
    };

    getNextClubSubscriptionType = (id) => {
        return clubRepository.getNextClubSubscriptionType(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addBonusClub = (clubId, financialHistory) => {
        return clubRepository.addBonusClub(clubId, financialHistory);
    };

    deleteBonusClub = (clubId, financialHistory) => {
        return clubRepository.deleteBonusClub(clubId, financialHistory);
    };
    getClubsTotalActiveUsers = (query) => {
        return clubRepository.getClubsTotalActiveUsers(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export default new ClubService();
