import eventPaymentService from "../../shared/services/eventPaymentService";
import history from "../../../history";
import i18n from "../../../i18n";
import {EPaymentResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_EVENT_PAYMENT_LIST = "GET_EVENT_PAYMENT_LIST";
export const GET_EVENT_PAYMENT_LIST_TIMESTAMP = "GET_EVENT_PAYMENT_LIST_TIMESTAMP";
export const GET_EVENT_PAYMENTS_COUNT = "GET_EVENT_PAYMENTS_COUNT";
export const GET_EVENT_PAYMENTS_COUNT_TIMESTAMP = "GET_EVENT_PAYMENTS_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const EVENT_PAYMENT_LOADING = "EVENT_PAYMENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getEventPayments(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EVENT_PAYMENT_LIST_TIMESTAMP,
            data: currentTime
        });
        return eventPaymentService
            .getEventPayments(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EVENT_PAYMENT_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getEventPaymentsCount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EVENT_PAYMENTS_COUNT_TIMESTAMP,
            data: currentTime
        });
        return eventPaymentService
            .getEventPaymentsCount(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EVENT_PAYMENTS_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateEventPayment(eventPayment) {
    return dispatch => {
        dispatch({
            type: EVENT_PAYMENT_LOADING
        });
        return eventPaymentService
            .updateEventPayment(eventPayment)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addEventPayment(eventPayment) {
    return dispatch => {
        dispatch({
            type: EVENT_PAYMENT_LOADING
        });
        return eventPaymentService
            .addEventPayment(eventPayment)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
