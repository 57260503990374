import {
  ERASE_MESSAGE,
  TRAINING_EVENT_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/TrainingEventActions";

const initialState = {
  loading: false,
  success: null,
  error: null
};

const TrainingEventReducer = function(state = initialState, action) {
  switch (action.type) {
    case TRAINING_EVENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default TrainingEventReducer;
