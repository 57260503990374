import {
  ERASE_MESSAGE,
  GET_ATHLETE_PAYMENT_LIST,
  GET_ATHLETE_PAYMENT_LIST_TIMESTAMP,
  GET_ATHLETE_PAYMENTS_COUNT_TIMESTAMP,
  GET_ATHLETE_PAYMENTS_COUNT,
  ATHLETE_PAYMENT_LOADING,
  SAVE_ERROR,
  SAVE_SUCCESS,
  GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT,
  GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT_TIMESTAMP
} from "../actions/AthletePaymentActions";

const initialState = {
  athletePaymentList: [],
  loading: false,
  success: null,
  error: null,
  getAthletePaymentsTimestamp: null,
  count: 0,
  getAthletePaymentsCountTimestamp: null,
  getAthletePaymentsTotalAmountTimestamp: null
};

const AthletePaymentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATHLETE_PAYMENT_LIST_TIMESTAMP: {
      return {
        ...state,
        getAthletePaymentsTimestamp: action.data
      };
    }
    case GET_ATHLETE_PAYMENT_LIST: {
      if (action.data.time === state.getAthletePaymentsTimestamp) {
        return {
          ...state,
          athletePaymentList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_ATHLETE_PAYMENTS_COUNT_TIMESTAMP: {
      return {
        ...state,
        getAthletePaymentsCountTimestamp: action.data
      };
    }
    case GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT_TIMESTAMP: {
      return {
        ...state,
        getAthletePaymentsTotalAmountTimestamp: action.data
      };
    }
    case GET_ATHLETE_PAYMENTS_COUNT: {
      if (action.data.time === state.getAthletePaymentsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT: {
      if (action.data.time === state.getAthletePaymentsTotalAmountTimestamp) {
        return {
          ...state,
          totalAmount: action.data.count
        };
      } else {
        return state;
      }
    }
    case ATHLETE_PAYMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default AthletePaymentReducer;
