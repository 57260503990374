import {competitionEventRepository} from "../repositories/competitionEventRepository";

class CompetitionEventService {

    addCompetitionEvent = (competitionEvent) => {
        return competitionEventRepository.addCompetitionEvent(competitionEvent);
    };

    updateCompetitionEvent = (competitionEvent) => {
        return competitionEventRepository.updateCompetitionEvent(competitionEvent);
    };

    deleteCompetitionEvent = (competitionEvent) => {
        return competitionEventRepository.deleteCompetitionEvent(competitionEvent);
    };

}

export default new CompetitionEventService();
