import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class clubRepository {

    /**
     * Generate invoices
     * @returns {Promise}
     */
    static generateInvoices(params) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/generate`, {
            method: 'POST',
            body: JSON.stringify(params)
        });
    }


    /**
     * Get club
     * @returns {Promise}
     * @param query
     */
    static getClubs(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/${helperService.serialize(query)}`
        );
    }

    /**
     * Get club by id
     * @returns {Promise}
     * @param id
     */
    static getClubById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/${id}`
        );
    }

    /**
     * Get club by code
     * @returns {Promise}
     * @param code
     */
    static getClubByCode(code) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/code/${code}`
        );
    }

    /**
     * Get president by club id
     * @returns {Promise}
     * @param id
     */
    static getPresidentByClubId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/president/${id}`
        );
    }

    /**
     * Get sports by club id
     * @returns {Promise}
     * @param id
     */
    static getSportsByClubId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/sport/${id}`
        );
    }

    /**
     * Add new club
     * @returns {Promise}
     */
    static addClub(club) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/`, {
            method: 'POST',
            body: JSON.stringify(club)
        });
    }

    /**
     * Update club
     * @returns {Promise}
     */
    static updateClub(club) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/`, {
            method: 'PUT',
            body: JSON.stringify(club)
        });
    }

    /**
     * Delete club
     * @returns {Promise}
     */
    static deleteClub(club) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/`, {
            method: 'DELETE',
            body: JSON.stringify(club)
        });
    }

    /**
     * Add logo
     * @returns {Promise}
     * @param file
     * @param clubId
     */
    static addModifyLogo (file, clubId) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/addLogo?clubId=${clubId}`, {
            method: 'POST',
            body: formData
        }, true);
    };

    /**
     * Add contract
     * @returns {Promise}
     * @param file
     * @param clubId
     */
    static addModifyContract (file, clubId) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/addContract?clubId=${clubId}`, {
            method: 'POST',
            body: formData
        }, true);
    };

    /**
     * Get contract by club id
     * @returns {Promise}
     * @param clubId
     */
    static getContract(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/getContract?clubId=${clubId}`,{
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true
        );
    }

    /**
     * Add stamp
     * @returns {Promise}
     * @param file
     * @param clubId
     */
    static addModifyStamp (file, clubId) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/addStamp?clubId=${clubId}`, {
            method: 'POST',
            body: formData
        }, true);
    };

    /**
     * Get stamp by club id
     * @returns {Promise}
     * @param clubId
     */
    static getStamp(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/getStamp?clubId=${clubId}`,{
                headers: {
                    'Accept': 'image/jpeg'
                }
            }, true, true
        );
    }

    /**
     * Get logo by club id
     * @returns {Promise}
     * @param clubId
     */
    static getLogo(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/getLogo?clubId=${clubId}`,{
                headers: {
                    'Accept': 'image/jpeg'
                }
            }, true, true
        );
    }

    /**
     * Get next club subscription type
     * @returns {Promise}
     * @param clubId
     */
    static getNextClubSubscriptionType(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/nextClubSubscriptionType/${clubId}`
        );
    }

    /**
     * Get club financial history
     * @returns {Promise}
     * @param id
     */
    static getNextClubFinancialHistory(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/financialHistory/${id}`
        );
    }

    /**
     * Add bonus for club
     * @returns {Promise}
     */
    static addBonusClub(clubId, financialHistory) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/addBonus/${clubId}`, {
            method: 'PUT',
            body: JSON.stringify(financialHistory)
        });
    }

    /**
     * Delete bonus for club
     * @returns {Promise}
     */
    static deleteBonusClub(clubId, financialHistory) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/club/deleteBonus/${clubId}`, {
            method: 'PUT',
            body: JSON.stringify(financialHistory)
        });
    }
    /**
     * Get clubs total active users
     * @returns {Promise}
     */
    static getClubsTotalActiveUsers(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/club/totalAmount/${helperService.serialize(query)}`
        );
    }

}
