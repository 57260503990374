import Auditable from "../core/Auditable";

class HealthCondition extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.startDate = new Date(data.startDate);
            this.endDate = new Date(data.endDate);
            this.healthCondStatus = data.healthCondStatus;
            this.detailedInformation = data.detailedInformation;
        }
    }
}

export default HealthCondition;

