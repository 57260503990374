import subscriptionTypeService from "../../shared/services/subscriptionTypeService";
import history from "../../../history";
import i18n from "../../../i18n";
import {ESubscriptionTypeResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_SUBSCRIPTIONTYPE_LIST = "GET_SUBSCRIPTIONTYPE_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const SUBSCRIPTIONTYPE_LOADING = "SUBSCRIPTIONTYPE_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getSubscriptionType(query) {
    return dispatch => {
        return subscriptionTypeService
            .getSubscriptionType(query)
            .then(( data ) => {
                dispatch({
                    type: GET_SUBSCRIPTIONTYPE_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateSubscriptionType(subscriptionType) {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTIONTYPE_LOADING
        });
        return subscriptionTypeService
            .updateSubscriptionType(subscriptionType)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ESubscriptionTypeResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ESubscriptionTypeResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addSubscriptionType(subscriptionType) {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTIONTYPE_LOADING
        });
        return subscriptionTypeService
            .addSubscriptionType(subscriptionType)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ESubscriptionTypeResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ESubscriptionTypeResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
