import {extraNotificationRepository} from "../repositories/extraNotificationRepository";
import ExtraNotification from "../models/club/ExtraNotification";

class ExtraNotificationService {

    addExtraNotification = (extraNotification) => {
        return extraNotificationRepository.addExtraNotification(extraNotification);
    };
    addExtraNotificationFile = (file) => {
        return extraNotificationRepository.addExtraNotificationFile(file);
    };

    deleteExtraNotification = (id) => {
        return extraNotificationRepository.deleteExtraNotification(id);
    };

    getExtraNotifications = (query) => {
        return extraNotificationRepository.getExtraNotifications(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new ExtraNotification(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateExtraNotification = (extraNotification) => {
        return extraNotificationRepository.updateExtraNotification(extraNotification);
    };
    deleteExtraNotifications = (idList) => {
        return extraNotificationRepository.deleteExtraNotifications(idList);
    }

}

export default new ExtraNotificationService();
