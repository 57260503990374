class Competition {
    constructor(data) {
        if (data) {
            this.id = data.id;
            this.competitionEvent = data.competitionEvent;
            this.athletesStats = data.athletesStats;
            this.comments = data.comments;
            this.title = data.title;
            this.finalScore = data.finalScore;

            this.start = this.getDate(this.competitionEvent.timeStart);
            this.end = this.getDate(this.competitionEvent.timeStop);
        }
    }

    getDate(time) {
        const date = new Date(new Date(this.competitionEvent.eventDate).getTime());
        let hours = parseInt(time.split(":")[0]);
        const minutes = parseInt(time.split(":")[1]);
        if (time.includes('pm')){
            hours+=12;
        }
        date.setHours(hours, minutes);
        return date;
    }
}

export default Competition;
