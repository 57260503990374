import Popover from "@material-ui/core/Popover";
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Icon, IconButton} from "@material-ui/core";
import {helperService} from "../services/helperService";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    content: {
        padding: '8px',
    },
    info: {
        color: 'white'
    },
    icon: {
        position: 'relative',
        top: '7px',
        paddingRight: '5px'
    }
}));

function DateModifiedDetails(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const {auditableDetails, t} = props;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick} className={classes.info}>
                <Icon>info</Icon>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.content}>
                    <div className="p-8">
                        <Icon className={classes.icon}>account_circle</Icon>
                        <span>
                            <span>{t('calendar.event.createdBy')} </span>
                            <a className="text-primary">
                                {auditableDetails.createdBy? auditableDetails.createdBy.userFullName : ''}
                            </a>
                            <span> {t('calendar.event.atDate')} {auditableDetails.created ? helperService.formatDateTime(new Date(auditableDetails.created)) : ''}</span>
                        </span>
                    </div>

                    <div className="p-8">
                        <Icon className={classes.icon}>restore</Icon>
                        <span>
                            <span>{t('calendar.event.modifiedBy')} </span>
                            <a className="text-primary">
                                {auditableDetails.lastModifiedBy ? auditableDetails.lastModifiedBy.userFullName : ''}
                            </a>
                            <span> {t('calendar.event.atDate')} {auditableDetails.lastModified ? helperService.formatDateTime(new Date(auditableDetails.lastModified)) : ''}</span>
                        </span>
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default (withTranslation()(DateModifiedDetails)
);
