import {publicRepository} from "../repositories/publicRepository";

class PublicService {

    getSports = (query) => {
        return publicRepository.getSports(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addClub = (club) => {
        return publicRepository.addClub(club)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new PublicService();
