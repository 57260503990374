import {
    CLUB_LOADING, SAVE_ERROR, SAVE_SUCCESS, ERASE_MESSAGE
} from "../actions/PublicActions";

const initialState = {
    clubList: [],
    loading: false,
    success: null,
    error: null
};

const PublicReducer = function(state = initialState, action) {
    switch (action.type) {
        case CLUB_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case SAVE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case SAVE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case ERASE_MESSAGE: {
            return {
                ...state,
                error: null,
                success: null
            };
        }
        default: {
            return state;
        }
    }
};

export default PublicReducer;
