import {trainingEventRepository} from "../repositories/trainingEventRepository";

class TrainingEventService {

    addTrainingEvent = (trainingEvent) => {
        return trainingEventRepository.addTrainingEvent(trainingEvent);
    };

    updateTrainingEvent = (trainingEvent) => {
        return trainingEventRepository.updateTrainingEvent(trainingEvent);
    };

    deleteTrainingEvent = (trainingEvent) => {
        return trainingEventRepository.deleteTrainingEvent(trainingEvent);
    };

}

export default new TrainingEventService();
