import {trainingRepository} from "../repositories/trainingRepository";
import Training from "../models/club/Training";
import AttendanceSituation from "../models/club/AttendanceSituation";
import * as moment from "moment";

const weekday = ['SU','MO', 'TU', 'WE','TH', 'FR', 'SA', 'SU'];

class TrainingService {

    getTrainings = (start, end, sportId, teamId, athleteId) => {
        return trainingRepository.getTrainings(start, end, sportId, teamId, athleteId)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new Training(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getNextTraining = (params) => {
        return trainingRepository.getNextTraining(params)
            .then((res) => {
                if (res) {
                    return new Training(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getLastTraining = (params) => {
        return trainingRepository.getLastTraining(params)
            .then((res) => {
                if (res) {
                    return new Training(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAttendanceSituation = (athleteId, teamId, month, year) => {
        return trainingRepository.getAttendanceSituation(athleteId, teamId, month, year)
            .then((res) => {
                if (res) {
                    return new AttendanceSituation(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamAttendanceSituationForLastTraining = (teamId) => {
        return trainingRepository.getTeamAttendanceSituationForLastTraining(teamId)
            .then((res) => {
                if (res) {
                    return new AttendanceSituation(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamAttendanceMonthlySituation = (teamId, month, year) => {
        return trainingRepository.getTeamAttendanceMonthlySituation(teamId, month, year)
            .then((res) => {
                if (res) {
                    return new AttendanceSituation(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamsAttendanceSituationForLastTraining = (sportId, coachId) => {
        return trainingRepository.getTeamsAttendanceSituationForLastTraining(sportId, coachId)
            .then((res) => {
                if (res) {
                    return res;
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getMonthlyAttendanceSituation = (year, coachId, sportId) => {
        return trainingRepository.getMonthlyAttendanceSituation(year, coachId, sportId)
            .then((res) => {
                if (res) {
                    return res;
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getMonthlyAverageGradeForTrainings = (params) => {
        return trainingRepository.getMonthlyAverageGradeForTrainings(params);
    };

    getTrainingById = (id) => {
        return trainingRepository.getTrainingById(id)
            .then((res) => {
                return new Training(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateTraining = (training) => {
        return trainingRepository.updateTraining(training);
    };

    deleteTraining = (training) => {
        return trainingRepository.deleteTraining(training);
    };

    deleteUpcomingTrainings = (training) => {
        return trainingRepository.deleteUpcomingTrainings(training);
    };

    updateTrainingProperties = (trainingRequest) => {
        return trainingRepository.updateTrainingProperties(trainingRequest);
    };

    updateUpcomingTrainingsProperties = (trainingRequest) => {
        return trainingRepository.updateUpcomingTrainingsProperties(trainingRequest);
    };


    selectedDaysOfWeekExistsBetweenDates = (daysOfWeek, startDate, endDate) => {
        if (daysOfWeek.length === 0) {
            return true;
        }
        if (daysOfWeek.includes(weekday[startDate.getDay()])) {
            return true;
        }
        if (daysOfWeek.includes(weekday[endDate.getDay()])) {
            return true;
        }
        const start = moment(startDate).startOf('day');
        const end = moment(endDate).startOf('day');
        while(start.add(1, 'days').diff(end) < 0) {
            if (daysOfWeek.includes(weekday[start.day()])) {
                return true;
            }
        }
        return false;
    };

}

export default new TrainingService();
