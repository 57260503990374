import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_LOADING,
  ERASE_MESSAGE
} from "../actions/RegisterActions";

const initialState = {
  loading: false,
  success: null,
  error: null
};

const RegisterReducer = function(state = initialState, action) {
  switch (action.type) {
    case REGISTER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case REGISTER_ERROR: {
      return {
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default RegisterReducer;
