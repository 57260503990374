import React, { Component } from "react";

class Presentation extends Component {

    constructor(props) {
        super(props);
        this.state = {height: props.height};
    }

    componentWillMount(){
        this.setState({height: window.innerHeight + 'px'});
    }

    render() {
        switch(this.props.match.params.language) {
            case 'en':
                return (
                    <iframe src="https://escdataprod.blob.core.windows.net/public/en/INTRO ESC ENG.pdf" width="100%" style={ { height: `${ this.state.height }` } } allowFullScreen={true}/>
                );
            case 'ro':
                return (
                    <iframe src="https://escdataprod.blob.core.windows.net/public/ro/INTRO ESC RO.pdf" width="100%" style={ { height: `${ this.state.height }` } } allowFullScreen={true}/>
                );
            default:
                return (
                    <iframe src="https://escdataprod.blob.core.windows.net/public/ro/INTRO ESC RO.pdf" width="100%" style={ { height: `${ this.state.height }` } } allowFullScreen={true}/>
                );
        }
    }
}

export default Presentation;
