import {
  ERASE_MESSAGE, GET_EVENT_PAYMENT_LIST, GET_EVENT_PAYMENT_LIST_TIMESTAMP, GET_EVENT_PAYMENTS_COUNT,
    GET_EVENT_PAYMENTS_COUNT_TIMESTAMP, EVENT_PAYMENT_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/EventPaymentActions";

const initialState = {
  eventPaymentList: [],
  loading: false,
  success: null,
  error: null,
  getEventPaymentsTimestamp: null,
  count: 0,
  getEventPaymentsCountTimestamp: null
};

const EventPaymentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_PAYMENT_LIST_TIMESTAMP: {
      return {
        ...state,
        getEventPaymentsTimestamp: action.data
      };
    }
    case GET_EVENT_PAYMENT_LIST: {
      if (action.data.time === state.getEventPaymentsTimestamp) {
        return {
          ...state,
          eventPaymentList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_EVENT_PAYMENTS_COUNT_TIMESTAMP: {
      return {
        ...state,
        getEventPaymentsCountTimestamp: action.data
      };
    }
    case GET_EVENT_PAYMENTS_COUNT: {
      if (action.data.time === state.getEventPaymentsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case EVENT_PAYMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default EventPaymentReducer;
