import {userUtilsService} from "../services/userUtilsService";
import Coach from "./club/Coach";
import Athlete from "./club/Athlete";
import Parent from "./club/Parent";

class CurrentUser {
    constructor(data) {
        if (data) {
            this.id = data.id;
            this.email = data.email;
            this.role = data.role;
            this.club = data.club;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.fullName = data.fullName;
            this.userDetailsId = data.userDetailsId;
            this.sport = data.sport;
            this.activationDate = data.activationDate;
            this.receiveNotifications = data.receiveNotifications;
            this.isCoach = data.isCoach;
            this.helperHidden = data.helperHidden;
            this.hideFinancial = data.hideFinancial;
            this.lastSportSelectedFromCalendar = data.lastSportSelectedFromCalendar;
        }
    }

    getRole() {
        return this.role;
    }

    hasRole(role) {
        return this.role === role;
    }

    isClubStaffRole() {
        return userUtilsService.getClubStaffRoles().includes(this.role);
    }

    isClubInviteRole() {
        return userUtilsService.getClubInviteRoles().includes(this.role);
    }

    isClubSportRole() {
        return userUtilsService.getClubSportRoles().includes(this.role);
    }

    isClubCoachRole() {
        return userUtilsService.getClubCoachRoles().includes(this.role);
    }

    isClubCoachTeamRole() {
        return userUtilsService.getClubCoachTeamRoles().includes(this.role);
    }

    isClubAllCoachRole() {
        return userUtilsService.getClubAllCoachRoles().includes(this.role);
    }

    isClubMainCoachRole() {
        return userUtilsService.getClubMainCoachRoles().includes(this.role);
    }

    isClubPresidentManagerRole() {
        return userUtilsService.getClubPresidentManagerRoles().includes(this.role);
    }

    isAdminRole(){
        return userUtilsService.getAdminRole().includes(this.role);
    }

    isClubPresidentRole() {
        return userUtilsService.getClubPresidentRole().includes(this.role);
    }

    isClubManagerRole() {
        return userUtilsService.getClubManagerRole().includes(this.role);
    }

    isClubAthleteParentRole() {
        return userUtilsService.getClubAthleteParentRoles().includes(this.role);
    }

    isClubAllExceptAthleteRole() {
        return userUtilsService.getClubAllExceptAthleteRoles().includes(this.role);
    }

    isClubAllRoles() {
        return userUtilsService.getAllClubRoles().includes(this.role);
    }

    isClubPhysicalTrainer(){
        return userUtilsService.getClubPhysicalTrainer().includes(this.role);
    }

    isUserStatusEnabled(userDetails){
        if(this.isClubPresidentManagerRole())
            return true;
        if( (this.isClubMainCoachRole() && ((!userDetails.clubManager && userDetails instanceof Coach) || userDetails instanceof Athlete || userDetails instanceof Parent) )
            || (this.isClubStaffRole() && (userDetails instanceof Athlete || userDetails instanceof Parent) ) )
            return true;
        return false;
    }

    // isCoachSupervisor(userDetails){
    //     if(userDetails && userDetails instanceof Coach && userDetails.accessUser.role.name)
    // }

    isSameUser(id) {
        if(this.id === id)
            return true;
        else
            return false;
    }
}

export default CurrentUser;
