import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class subscriptionRepository {

    /**
     * Get subscription
     * @returns {Promise}
     * @param query
     */
    static getSubscription(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscription/${helperService.serialize(query)}`
        );
    }

    /**
     * Get subscription by id
     * @returns {Promise}
     * @param id
     */
    static getSubscriptionById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscription/${id}`
        );
    }


    /**
     * Delete subscription type
     * @returns {Promise}
     */
    static deleteSubscription(subscription) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/subscription/`, {
            method: 'DELETE',
            body: JSON.stringify(subscription)
        });
    }

    /**
     * Get subscription count
     * @returns {Promise}
     */
    static getSubscriptionCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscription/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Send payment reminder
     * @param subscriptionId
     * @returns {Promise<unknown>}
     */
    static sendPaymentReminder(subscriptionId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/notification/subscription/payment-reminder/${subscriptionId}`, {
            method: 'POST'
        });
    }

    /**
     * Cancel subscription
     * @returns {Promise}
     */
    static cancelSubscription(subscriptionId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/subscription/cancel/${subscriptionId}`, {
            method: 'PUT'
        });
    }
    /**
     * Get subscriptions total amount
     * @returns {Promise}
     */
    static getSubscriptionsTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscription/totalAmount/${helperService.serialize(query)}`
        );
    }
}
