import React, {Component} from "react";
import {Button, Checkbox, Dialog, withStyles} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import {withTranslation} from "react-i18next";
import {themeColors} from "../../EgretLayout/EgretTheme/themeColors";
import Loader from "react-loader-spinner";

const styles = () => ({
    option:{
        fontSize: '16px'
    },
    disabledButton: {
        background: 'lightgray !important'
    }
});

class PaymentDialog extends Component {

    state = {
        paymentConfirmation: true
    };

    handleAgreementChange = event => {
        event.persist();
        this.setState({
            "paymentConfirmation": !this.state.paymentConfirmation
        });
    };

    render () {
        let { open, handleClose, t, type, displaySpinner, classes } = this.props;
        return (
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={open}
                onClose={handleClose}
            >
                <div className="pt-24 px-20 pb-16">
                    {type === t('dashboard.donate.donationLabel') ?
                        (<h4 className="capitalize">{t('dashboard.donate.donationPayment')}</h4>) :
                        (<h4 className="capitalize">{t('athletePayment.action.payFee', {type: type})}</h4>)
                    }
                    {!displaySpinner ? (
                        <div>
                            <FormControlLabel
                                className="mb-16"
                                name="paymentConfirmation"
                                onChange={this.handleAgreementChange}
                                control={<Checkbox checked={this.state.paymentConfirmation}/>}
                                label={t('athletePayment.action.agree')}
                            />
                            <div className="flex flex-space-between flex-middle">
                                <Button onClick={this.props.handlePaymentConfirmation}
                                        variant="contained" color="primary" type="submit"
                                        style={{background: "#417505", color: "white"}}
                                        classes={{disabled: classes.disabledButton}}
                                        disabled={!this.state.paymentConfirmation}
                                >
                                    {t('actions.continue')}
                                </Button>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    style={{background: "#D0021B", color: "white"}}
                                >
                                    {t('actions.cancel')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Loader
                                className = "dashboard__loader"
                                type="Oval"
                                color={themeColors.purple1.palette.primary.main}
                                height={50}
                                width={50}
                            />
                        </div>
                    )}
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
        (withTranslation()(PaymentDialog))
);

