import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Clubs = EgretLoadable({
  loader: () => import("./Clubs")
});

const ClubDetails = EgretLoadable({
  loader: () => import("./ClubDetails")
});

const Invitation = EgretLoadable({
  loader: () => import("./Invitation")
});

const clubRoutes = [
  {
    path: "/club/list",
    exact: true,
    component: Clubs,
    auth: [authRoles.admin]
  },
  {
    path: "/club/:id/invite",
    component: Invitation,
    auth: userUtilsService.getClubInviteRoles()
  },
  {
    path: "/club/:id",
    component: ClubDetails,
    auth: [authRoles.admin]
  }
];

export default clubRoutes;
