import React, { Component } from "react";
import {
  Card,
  Grid,
  Button, CircularProgress, withStyles
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import {eraseMessage, sendForgotPasswordEmail} from "../../redux/actions/EmailActions";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {withTranslation} from "react-i18next";
import * as ReactDOM from "react-dom";
import ReactFlagsSelect from 'react-flags-select';

const styles = () => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  logo: {
    height: "250px"
  }
});

class ForgotPassword extends Component {
  state = {
    email: "",
    selectedLanguage: ''
  };

  constructor(props) {
    super(props);
    this.setInputFocus = this.setInputFocus.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      selectedLanguage: this.props.i18n.language === 'en' ? 'GB' : this.props.i18n.language.toUpperCase()
    });
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.props.sendForgotPasswordEmail({ email: this.state.email, language: this.props.i18n.language });
  };

  setInputFocus() {
    let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( this.state.email === "" || !emailFormat.test(this.state.email) ) {
      ReactDOM.findDOMNode(this.emailRef).childNodes[1].childNodes[0].focus();
    }
  }

  handleLanguageClick = (lng) => {
    const {i18n} = this.props;
    i18n.changeLanguage(lng).then();
  };

  renderLanguage = () => {
    return (
        <ReactFlagsSelect
            countries={["RO", "GB"]}
            showOptionLabel={false}
            showSelectedLabel={false}
            defaultCountry="RO"
            selected={this.state.selectedLanguage}
            selectedSize={30}
            optionsSize={30}
            onSelect={this.onSelectFlag}
            selectButtonClassName="language-signup"
        />
    );
  };

  onSelectFlag = (countryCode) => {
    switch(countryCode) {
      case 'RO':
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
      case 'GB':
        this.handleLanguageClick('en');
        this.setState({
          ...this.state,
          selectedLanguage: 'GB'
        });
        break;
      default:
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
    }
  };

  render() {
    let { email } = this.state;
    let { classes, emailSender, t } = this.props;

    return (
        <div>
          <div style={{float: 'right !important', position: 'absolute', right: '7%', top:'21%'}}>
            {this.renderLanguage()}
          </div>
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 bg-light-gray h-100 text-center">
                  <div className="uppercase" style={{position:'relative', top:'4px', whiteSpace: 'pre-line'}}>
                    <h3>{t('signIn.title')}</h3>
                  </div>
                  <img src="/assets/logo/Logo-Expert-Sport-Club-Outlined-02.svg"
                       alt="" className={classes.logo}/>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100">
                  <div className="mb-16 w-100"><h3>{t('forgotPassword.title')}</h3></div>
                  <div className="mb-16 w-100"><h6>{t('forgotPassword.subtitle')}</h6></div>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label={t('form.email')}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t('validation.error.required'),
                        t('validation.error.email.invalid')
                      ]}
                      ref={c => (this.emailRef = c)}
                      autoFocus
                    />
                    <div className="flex flex-middle">
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={emailSender.loading}
                          onClick={this.setInputFocus}
                      >
                        {t('actions.resetPassword')}
                        {emailSender.loading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                      </Button>
                      <span className="ml-16 mr-8">{t('forgotPassword.or')}</span>
                      <Button
                        className="capitalize"
                        onClick={() =>
                          this.props.history.push("/session/signin")
                        }
                      >
                        {t('actions.signIn')}
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
          <CustomizedSnackbar message={emailSender.error} severity="error" onClose={this.props.eraseMessage}/>
          <CustomizedSnackbar message={emailSender.success} severity="success" onClose={this.props.eraseMessage}/>
        </div>
      </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: PropTypes.func.isRequired,
  emailSender: state.emailSender
});
export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
          mapStateToProps,
          { sendForgotPasswordEmail, eraseMessage }
        )(withTranslation()(ForgotPassword))
    )
);
