import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";
import {userUtilsService} from "../../shared/services/userUtilsService";

const SportsArena = EgretLoadable({
  loader: () => import("./SportsArena")
});

const SportsArenaDetails = EgretLoadable({
  loader: () => import("./SportsArenaDetails")
});


const sportsArenaRoutes = [
  {
    path: "/sportsArena/list",
    exact: true,
    component: SportsArena,
    auth: userUtilsService.getClubStaffRoles()
  }
  ,
  {
    path: "/SportsArena/:id",
    component: SportsArenaDetails,
    auth: userUtilsService.getClubStaffRoles()
  }
];

export default sportsArenaRoutes;
