import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n.js";

import * as serviceWorker from "./serviceWorker";
import App from './app/App.js';

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
