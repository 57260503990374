import axios from "axios";
import {APP_CONFIG} from "../../appConfig";
import {SessionService} from "../../shared/services/sessionService";
import i18n from "../../../i18n";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION";

export const getNotification = () => dispatch => {
  return axios.get(APP_CONFIG.URL.club + '/notification?token=' + SessionService.getToken() + '&language=' + i18n.language,{
    headers: {
      'Authorization': "Bearer " + SessionService.getToken()
    }
  }).then(res => {
    dispatch({
      type: GET_NOTIFICATION,
      payload: res.data
    });
    return res;
  });
};

export const deleteNotification = (id) => dispatch => {
  return axios.delete(APP_CONFIG.URL.club + '/notification?id=' + id, {
    headers: {
      'Authorization': "Bearer " + SessionService.getToken()
    }
  }).then(res => {
    dispatch({
      type: DELETE_NOTIFICATION,
      payload: res.data
    });
  });
};

export const deleteAllNotification = () => dispatch => {
  axios.delete(APP_CONFIG.URL.club + '/notification/all?token=' + SessionService.getToken(), {
    headers: {
      'Authorization': "Bearer " + SessionService.getToken()
    }
  }).then(res => {
    dispatch({
      type: DELETE_ALL_NOTIFICATION,
      payload: res.data
    });
  });
};

export const createNotification = notification => dispatch => {
  axios.post("/api/notification/add", { notification }).then(res => {
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: res.data
    });
  });
};
