import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class eventExpensesRepositoryMobile {

    /**
     * Get eventExpenses mobile models
     * @returns {Promise}
     * @param query
     */
    static getEventExpenses(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/eventExpenses/mobile/${helperService.serialize(query)}`
        );
    }

}
