import {clubPaymentRepository} from "../repositories/clubPaymentRepository";
import ClubPayment from "../models/root/ClubPayment";

class ClubPaymentService {

    getClubPayments = (query) => {
        return clubPaymentRepository.getClubPayments(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getClubPaymentById = (id) => {
        return clubPaymentRepository.getClubPaymentById(id)
            .then((res) => {
                return new ClubPayment(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addClubPayment = (clubPayment) => {
        return clubPaymentRepository.addClubPayment(clubPayment);
    };

    updateClubPayment = (clubPayment) => {
        return clubPaymentRepository.updateClubPayment(clubPayment);
    };

    collectClubInvoicePayment = (clubPayment) => {
        return clubPaymentRepository.collectClubInvoicePayment(clubPayment);
    };

    deleteClubPayment = (clubPayment) => {
        return clubPaymentRepository.deleteClubPayment(clubPayment);
    };

}

export default new ClubPaymentService();
