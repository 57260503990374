import {
  ERASE_MESSAGE, ADMIN_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/AdminActions";

const initialState = {
  loading: false,
  success: null,
  error: null
};

const AdminReducer = function(state = initialState, action) {
  switch (action.type) {
    case ADMIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default AdminReducer;
