import { SessionService } from '../services/sessionService';
import {StorageAdapter} from "./storageAdapter";
import history from "../../../history";
import {base64} from "rfc4648";

const defaults = {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
};

const AUTH_HEADER = 'Authorization';

export class FetchAdapter {

    static request(url, options = {}, isFile = false, decode = false) {
        let requestOptions = {...defaults, ...options};

        const token = SessionService.getToken();
        if (!requestOptions.headers[AUTH_HEADER] && token) {
            requestOptions = {...requestOptions, headers:
                    {...requestOptions.headers, [AUTH_HEADER]: `Bearer ${token}`}};
        }

        if (isFile) {
            delete requestOptions.headers['Content-Type'];
        }
        return window
            .fetch(url, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                if (isFile && requestOptions.method === 'GET') {
                    if (!decode) {
                        return res.blob();
                    } else {
                        return res.text();
                    }
                } else {
                    return res.text();
                }
            })
            .then((data) => {
                if (data) {
                    if (isFile && decode && requestOptions.method === 'GET') {
                        const decoded = base64.parse(data.toString());
                        return new Blob([new Int8Array(decoded).buffer]);
                    }
                    try {
                        return JSON.parse(data.toString());
                    } catch (err) {
                        //console.log('# FetchAdapter.request.error: JSON.parse', data);
                        // return Promise.reject(err);
                        return data;
                    }
                }
                return '';
            })
            .catch((err) => {
                console.log("Err: ", err)
                if (err && err.status === 401) {
                    SessionService.deleteToken();
                    StorageAdapter.delete("auth_user");

                    history.push({
                        pathname: "/session/signin"
                    });
                }
                if (err.text) {
                    return err.text().then((errorMessage) => {
                        let error;
                        try {
                            error = JSON.parse(errorMessage);
                        } catch (err) {
                            error = err;
                        }
                        return Promise.reject(error);
                    });
                }
                return Promise.reject(err);
            });
    }

}
