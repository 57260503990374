export const EGameEquipmentStatus = {
    COMPLETE: 'COMPLETE',
    INCOMPLETE: 'INCOMPLETE',
    NONEXISTENT: 'NONEXISTENT',
    RETURNED: 'RETURNED'
};


export const EGameEquipmentStatusTranslated = {
    COMPLETE: 'athleteFile.gameEquipment.status.complete',
    INCOMPLETE: 'athleteFile.gameEquipment.status.incomplete',
    NONEXISTENT: 'athleteFile.gameEquipment.status.nonExistent',
    RETURNED: 'athleteFile.gameEquipment.status.returned'
};
