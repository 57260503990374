import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import PaymentNoticeDetails from "./PaymentNoticeDetails";
import DonationDetails from "./DonationDetails";

const PaymentNotice = EgretLoadable({
  loader: () => import("./PaymentNotice")
});


const paymentNoticeRoutes = [
  {
    path: "/paymentNotice/list",
    exact: true,
    component: PaymentNotice,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/paymentNotice/:id",
    component: PaymentNoticeDetails,
    auth: userUtilsService.getClubInviteRoles()
},
  {
    path: "/donation/:id",
    component: DonationDetails,
    auth: userUtilsService.getClubInviteRoles()
  }
];

export default paymentNoticeRoutes;
