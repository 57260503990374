import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Teams = EgretLoadable({
  loader: () => import("./Teams")
});

const TeamDetails = EgretLoadable({
  loader: () => import("./TeamDetails")
});

const teamRoutes = [
  {
    path: "/team/list",
    exact: true,
    component: Teams,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/team/:id",
    component: TeamDetails,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default teamRoutes;
