import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button, withStyles, CircularProgress, IconButton
} from "@material-ui/core";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { connect } from "react-redux";
import {PropTypes} from "prop-types";
import { eraseRegisterMessage, registerNewUser } from "../../redux/actions/RegisterActions";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {SessionService as sessionService} from "../../shared/services/sessionService";
import {withTranslation} from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';
import i18n from "../../../i18n";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const styles = theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SignUp extends Component {
  state = {
      email: "",
      password: "",
      repeatPassword: "",
      token: "",
      agreement: false,
      showPassword: false,
      selectedLanguage: ''
  };

  componentDidMount() {
    if (this.props.match.params.token) {
      this.setState({
        ...this.state,
        token: this.props.match.params.token,
        selectedLanguage: this.props.match.params.language === 'EN' ? 'GB' : this.props.i18n.language.toUpperCase()
      }, () => this.getEmailFromToken());
    }
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value === this.state.password;
    });
    this.handleLanguageClick(this.props.match.params.language.toLowerCase());
  }

  getEmailFromToken () {
    const {sub} = sessionService.decodeToken(this.props.match.params.token);
    this.setState({
      ...this.state,
      email: sub
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleAgreementChange = event => {
    event.persist();
    this.setState({
      "agreement": !this.state.agreement
    });
  };

  handleFormSubmit = event => {
    this.props.registerNewUser({ ...this.state });
  };

  handleLanguageClick = (lng) => {
    const {i18n} = this.props;
    i18n.changeLanguage(lng).then();
  };

  togglePasswordVisibility = () => {
    const showPassword = (!this.state.showPassword);
    this.setState({
      ...this.state,
      showPassword
    });
  };

  renderLanguage = () => {
    return (
        <ReactFlagsSelect
            countries={["RO", "GB"]}
            showOptionLabel={false}
            showSelectedLabel={false}
            defaultCountry="RO"
            selected={this.state.selectedLanguage}
            selectedSize={30}
            optionsSize={30}
            onSelect={this.onSelectFlag}
            selectButtonClassName="language-signup"
        />
    );
  };

  onSelectFlag = (countryCode) => {
    switch(countryCode) {
      case 'RO':
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
      case 'GB':
        this.handleLanguageClick('en');
        this.setState({
          ...this.state,
          selectedLanguage: 'GB'
        });
        break;
      default:
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
    }
  };

  render() {
    let { email, password, repeatPassword, agreement, showPassword } = this.state;
    let { classes, t, register} = this.props;

    return (
        <div>
          <div style={{float: 'right !important', position: 'absolute', right: '5%', top:'12.7%'}}>
            {this.renderLanguage()}
          </div>
          <div className="signup flex flex-center w-100 h-100vh">
            <div className="p-8">
              <Card className="signup-card position-relative y-center">
                <Grid container>
                  <Grid item lg={5} md={5} sm={5} xs={12}>
                    <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                      <img src="/assets/logo/Logo-Expert-Sport-Club-Outlined-02.svg" alt=""/>
                    </div>
                  </Grid>
                  <Grid item lg={7} md={7} sm={7} xs={12}>
                    <div className="p-36 h-100">
                      <div className="mb-24 w-100"><h3>{t('signUp.title')}</h3></div>
                      <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                        <TextValidator
                          className="mb-24 w-100"
                          variant="outlined"
                          label={t('form.email')}
                          type="text"
                          name="email"
                          value={email ? email : ''}
                          disabled={true}
                        />
                        <div className="flex flex-middle mb-8">
                          <TextValidator
                            className="mb-24 w-100"
                            label={t('form.password')}
                            variant="outlined"
                            onChange={this.handleChange}
                            name="password"
                            type={showPassword === true ? "text" :"password"}
                            value={password}
                            validators={["required", "minStringLength:6"]}
                            errorMessages={[
                              t('validation.error.required'),
                              t('validation.error.password.tooShort')
                            ]}
                          />
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                color="primary"
                                onClick={this.togglePasswordVisibility}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        </div>
                        <div className="flex flex-middle mb-8">
                          <TextValidator
                            className="mb-16 w-100"
                            label={t('form.repeatPassword')}
                            variant="outlined"
                            onChange={this.handleChange}
                            name="repeatPassword"
                            type={showPassword === true ? "text" :"password"}
                            value={repeatPassword}
                            validators={["isPasswordMatch", "required"]}
                            errorMessages={[
                              t('validation.error.password.mismatch'),
                              t('validation.error.required')
                            ]}
                          />
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                color="primary"
                                onClick={this.togglePasswordVisibility}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        </div>
                        <Grid>
                          <FormControlLabel
                              name="conf"
                              onChange={this.handleAgreementChange}
                              control={<Checkbox disabled = {false}/>}
                              checked={this.state.agreement}
                              label=""/>
                          <a
                              href={"/session/terms/" + i18n.language}
                              target="_blank"
                              style={{'color':'#941bcc'}}>
                            {t('signUp.agreement')}
                          </a>
                        </Grid>
                        <div className="flex flex-middle">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={register.loading || !agreement}
                          >
                            {t('actions.register')}
                            {register.loading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                          </Button>
                        </div>
                      </ValidatorForm>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <CustomizedSnackbar message={register.error} severity="error" onClose={this.props.eraseRegisterMessage}/>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  registerNewUser: PropTypes.func.isRequired,
  register: state.register
});

export default withStyles(styles, { withTheme: true })(
    connect(
        mapStateToProps,
        { registerNewUser, eraseRegisterMessage }
    )(withTranslation()(SignUp))
);
