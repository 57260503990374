import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const ExtraPayments = EgretLoadable({
  loader: () => import("./ExtraPayments")
});

const ExtraPaymentDetails = EgretLoadable({
  loader: () => import("./ExtraPaymentDetails")
});

const extraPaymentRoutes = [
  {
    path: "/extraPayment/list",
    exact: true,
    component: ExtraPayments,
    auth: userUtilsService.getClubInviteRoles()

},
  {
    path: "/extraPayment/:id",
    component: ExtraPaymentDetails,
    auth: userUtilsService.getClubInviteRoles()
  }
];

export default extraPaymentRoutes;
