import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const ActivityResults = EgretLoadable({
  loader: () => import("./ActivityResults")
});

const ActivityResultsDetails = EgretLoadable({
  loader: () => import("./ActivityResultsDetails")
});

const activityResultsRoutes = [
  {
    path: "/sportfiles/results",
    exact: true,
    component: ActivityResults,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/sportfiles/results/details",
    exact: true,
    component: ActivityResultsDetails,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default activityResultsRoutes;
