import {pdfReportRepository} from "../repositories/pdfReportRepository";

class PdfReportService {

    getUserLanguage = () => {
        return pdfReportRepository.getUserLanguage()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadAthletesList = (query) => {
        return pdfReportRepository.downloadAthletesList(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadTestsResultsAthletesList = (query) => {
        return pdfReportRepository.downloadTestsResultsAthletesList(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadActivityResultsAthletesList = (query) => {
        return pdfReportRepository.downloadActivityResultsAthletesList(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadMonthlyAttendancesPdfReport = (teamId, month, year) => {
        return pdfReportRepository.downloadMonthlyAttendancesPdfReport(teamId, month, year)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    downloadStaffList = (query) => {
        return pdfReportRepository.downloadStaffList(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadGameAttendanceList = (id) => {
        return pdfReportRepository.downloadGameAttendanceList(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadGameEquipmentsAthleteList = (id) => {
        return pdfReportRepository.downloadGameEquipmentsAthleteList(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadGeneralDataAthlete = (id) => {
        return pdfReportRepository.downloadGeneralDataAthlete(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadBiometricDataAthlete = (id) => {
        return pdfReportRepository.downloadBiometricDataAthlete(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadInvoiceData = (id) => {
        return pdfReportRepository.downloadInvoiceData(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadTeamInfo = (id) => {
        return pdfReportRepository.downloadTeamInfo(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadCertificateAthlete = (id) => {
            return pdfReportRepository.downloadCertificateAthlete(id)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        };
    downloadAthleteRegisterFile = (id) => {
        return pdfReportRepository.downloadAthleteRegisterFile(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadClubInvoice = (id) => {
        return pdfReportRepository.downloadClubInvoice(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadClubExtraInvoices = (query) => {
        return pdfReportRepository.downloadClubExtraInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadClubExtraNoticeInvoices = (query) => {
        return pdfReportRepository.downloadClubExtraNoticeInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadAthletePaymentInvoice = (id) => {
        return pdfReportRepository.downloadAthletePaymentInvoice(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadAthletePaymentInvoices = (query) => {
        return pdfReportRepository.downloadAthletePaymentInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadAthleteSubscriptionInvoices = (query) => {
        return pdfReportRepository.downloadAthleteSubscriptionInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadEventPaymentInvoice = (id) => {
        return pdfReportRepository.downloadEventPaymentInvoice(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadEventPaymentInvoices = (query) => {
        return pdfReportRepository.downloadEventPaymentInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadEventExpensesInvoices = (query) => {
        return pdfReportRepository.downloadEventExpensesInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    downloadMonthlyCoachActivityPdfReport = (teamId, coachId, month, year) => {
        return pdfReportRepository.downloadMonthlyCoachActivityPdfReport(teamId, coachId, month, year)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }
    downloadCardSituationAthlete = () => {
        return pdfReportRepository.downloadCardSituationAthlete()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }
    downloadTestResultsAthlete = (athleteId, testPlanId) => {
        return pdfReportRepository.downloadTestResultsAthlete(athleteId, testPlanId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }
}

export default new PdfReportService();
