import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";

const Invoices = EgretLoadable({
  loader: () => import("./Invoices")
});

const InvoiceDetails = EgretLoadable({
  loader: () => import("./InvoiceDetails")
});


const invoiceRoutes = [
  {
    path: "/invoice/list",
    exact: true,
    component: Invoices,
    auth: [authRoles.admin]
  }
  ,
  {
    path: "/invoice/:id",
    component: InvoiceDetails,
    auth: [authRoles.admin]
  }
];

export default invoiceRoutes;
