import {teamRepository} from "../repositories/teamRepository";

class TeamService {

    getTeams = (query) => {
        return teamRepository.getTeams(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamsBySport = (query) => {
        return teamRepository.getTeamsBySport(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamById = (id) => {
        return teamRepository.getTeamById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamsByClubId = (id) => {
        return teamRepository.getTeamsByClubId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTeamByCodeAndClubId = (code, clubId) => {
        return teamRepository.getTeamByCodeAndClubId(code,clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    checkIfTeamsExistInClub = clubId => {
        return teamRepository.checkIfTeamsExistInClub(clubId);
    };

    checkUserPermissionOnTeam = teamId => {
        return teamRepository.checkUserPermissionOnTeam(teamId);
    };

    addTeam = (team) => {
        return teamRepository.addTeam(team);
    };

    updateTeam = (team) => {
        return teamRepository.updateTeam(team);
    };

    deleteTeam = (team) => {
        return teamRepository.deleteTeam(team);
    };

    addModifyLogo = (file, teamId) => {
        return teamRepository.addModifyLogo(file, teamId);
    };

    getLogo = (teamId) => {
        return teamRepository.getLogo(teamId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    checkForEvents = (id) => {
        return teamRepository.checkForEvents(id);
    };

    getAthletesPerTeamSituation = (sportId) => {
        return teamRepository.getAthletesPerTeamSituation(sportId);
    };

    getTeamsCount = (query) => {
        return teamRepository.getTeamsCount(query);
    };

    updateTeamProperties = (teamRequest) => {
        return teamRepository.updateTeamProperties(teamRequest).catch(error => {
            console.log(error);
            // window.location.reload();
        });
    };
    getMonthlyCoachActivity = (teamId, coachId, month, year) => {
        return teamRepository.getMonthlyCoachActivity(teamId, coachId, month, year)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export default new TeamService();
