import React, {Component} from "react";
import {APP_CONFIG} from "../../appConfig";

import {FetchAdapter} from "../../shared/adapters/fetchAdapter";

class TrainingPlanPdfReader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: props.height
        };
    }

    componentDidMount(){
        this.setState({height: window.innerHeight + 'px'});
        if(this.props.match.params.id) {
            const url = `${APP_CONFIG.URL.club}/trainingPlan/${this.props.match.params.id}`;
            FetchAdapter.request(
                url, {}, true
            ).then(blob => {
                let src = URL.createObjectURL(blob);
                const iframe = document.getElementById('embeddedPage');
                iframe.contentWindow.location.replace(src);
            });

        }
    }

    render() {
        return (
            <iframe id="embeddedPage" width="100%" style={ { height: `${ this.state.height }` } } allowFullScreen={true}/>
            );
    }
}

export default TrainingPlanPdfReader;
