import extraPaymentService from "../../shared/services/extraPaymentService";
import i18n from "../../../i18n";
import {EExtraPaymentNoticeResponseCodes, ESubscriptionTypeResponseCodes} from "../../shared/enums/ERespondeCodes";
import history from "../../../history";

export const GET_EXTRAPAYMENTNOTICES_LIST = "GET_EXTRAPAYMENTNOTICES_LIST";
export const GET_EXTRAPAYMENTNOTICES_LIST_TIMESTAMP = "GET_EXTRAPAYMENTNOTICES_LIST_TIMESTAMP";
export const GET_EXTRAPAYMENTNOTICES_COUNT = "GET_EXTRAPAYMENTNOTICES_COUNT";
export const GET_EXTRAPAYMENTNOTICES_COUNT_TIMESTAMP = "GET_EXTRAPAYMENTNOTICES_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const EXTRAPAYMENTNOTICES_LOADING = "EXTRAPAYMENTNOTICES_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getExtraPaymentNotices(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EXTRAPAYMENTNOTICES_LIST_TIMESTAMP,
            data: currentTime
        });
        return extraPaymentService
            .getExtraPaymentNotices(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EXTRAPAYMENTNOTICES_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getExtraPaymentNoticesCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EXTRAPAYMENTNOTICES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return extraPaymentService
            .getExtraPaymentNoticesCount(countMap)
            .then(( data ) => {
                dispatch({
                    type: GET_EXTRAPAYMENTNOTICES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function addExtraPaymentNotice(extraPaymentNotice, athleteIds) {
    return dispatch => {
        dispatch({
            type: EXTRAPAYMENTNOTICES_LOADING
        });
        extraPaymentNotice.athleteIds = athleteIds;
        return extraPaymentService
            .addExtraPaymentNotice(extraPaymentNotice, athleteIds)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EExtraPaymentNoticeResponseCodes['REGISTER_NOTICE_SUCCESS'])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ESubscriptionTypeResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
