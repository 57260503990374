import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class teamRepository {

    /**
     * Get teams
     * @returns {Promise}
     * @param query
     */
    static getTeams(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/${helperService.serialize(query)}`
        );
    }

    /**
     * Get teams by club&sport
     * @returns {Promise}
     * @param query
     */
    static getTeamsBySport(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/sport${helperService.serialize(query)}`
        );
    }

    /**
     * Get team by id
     * @returns {Promise}
     * @param id
     */
    static getTeamById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/${id}`
        );
    }

    /**
     * Get teams by club id
     * @returns {Promise}
     * @param id
     */
    static getTeamsByClubId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/club/${id}`
        );
    }

    /**
     * Get team by code
     * @returns {Promise}
     * @param code
     */
    static getTeamByCodeAndClubId(code, clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/code/${clubId}/${code}`
        );
    }

    /**
     * Check if any team exists in specific club
     * @returns {Promise}
     * @param clubId
     */
    static checkIfTeamsExistInClub(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/exists/${clubId}`
        );
    }

    /**
     * Check if the current user has permission on the team
     * @returns {Promise}
     * @param teamId
     */
    static checkUserPermissionOnTeam(teamId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/userPermission/${teamId}`
        );
    }

    /**
     * Add new team
     * @returns {Promise}
     */
    static addTeam(team) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/team/`, {
            method: 'POST',
            body: JSON.stringify(team)
        });
    }

    /**
     * Update team
     * @returns {Promise}
     */
    static updateTeam(team) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/team/`, {
            method: 'PUT',
            body: JSON.stringify(team)
        });
    }

    /**
     * Delete team
     * @returns {Promise}
     */
    static deleteTeam(team) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/team/`, {
            method: 'DELETE',
            body: JSON.stringify(team)
        });
    }

    /**
     * Add logo
     * @returns {Promise}
     * @param file
     * @param teamId
     */
    static addModifyLogo (file, teamId) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/team/addLogo?teamId=${teamId}`, {
            method: 'POST',
            body: formData
        }, true);
    };

    /**
     * Get logo by team id
     * @returns {Promise}
     * @param teamId
     */
    static getLogo(teamId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/getLogo?teamId=${teamId}`,{
                headers: {
                    'Accept': 'image/jpeg'
                }
            }, true
        );
    }

    /**
     * Check if team is assigned to any event
     * @returns {Promise}
     * @param id
     */
    static checkForEvents(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/checkForEvents?id=${id}`
        );
    }

    /**
     * Get athletes per team situation(total athletes, girls number and boys number)
     * @returns {Promise}
     * @param sportId
     */
    static getAthletesPerTeamSituation(sportId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/athletes-situation?sportId=${sportId}`
        );
    }

    /**
     * Get team count
     * @returns {Promise}
     * @param query
     * @param token
     */
    static getTeamsCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Update team properties
     * @returns {Promise}
     */
    static updateTeamProperties(teamRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/team/`, {
            method: 'PATCH',
            body: JSON.stringify(teamRequest)
        });
    }
    /**
     * Get team count
     * @returns {Promise}
     * @param teamId
     * @param coachId
     * @param month
     * @param year
     */
    static getMonthlyCoachActivity(teamId, coachId, month, year) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/team/monthly-coach-activity/${teamId}/${coachId}?month=${month}&year=${year}`
        );
    }
}
