import User from "../core/User";
import {helperService} from "../../services/helperService";

class Coach extends User {
    constructor(data) {
        super(data);
        this.mainCoach = data.mainCoach;
        this.clubManager = data.clubManager;
        this.hideFinancial = data.hideFinancial;
        this.teams = data.teams;
        this.sport = data.sport;
        this.salaryCost = data.salaryCost;
        this.currency = data.currency;
        if (data.birthDate) {
            this.birthDate = helperService.formatDateMongo(data.birthDate);
        }
        if (data.enrollmentDate) {
            this.enrollmentDate = helperService.formatDateMongo(data.enrollmentDate);
        }
    }
}

export default Coach;
