import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athletePaymentRepository {

    /**
     * Get athlete Payments
     * @returns {Promise}
     * @param query
     */
    static getAthletePayments(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athlete payment by id
     * @returns {Promise}
     * @param id
     */
    static getAthletePaymentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/${id}`
        );
    }

    /**
     * Check if athlete paid monthly fee
     * @returns {Promise}
     * @param athleteId
     */
    static isCurrentMonthPaid(athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/payment-situation/${athleteId}`
        );
    }

    /**
     * Get debtors situation for a specific team (nr of users who paid or not the fee for current month)
     * @param teamId
     * @param type
     * @returns {Promise<unknown>}
     */
    static getDebtorsSituation(teamId, type) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/debtors-situation?teamId=${teamId}&type=${type}`
        );
    }

    /**
     * Add new athlete payment
     * @returns {Promise}
     */
    static addAthletePayment(athletePayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.payment}/athlete/`, {
            method: 'POST',
            body: JSON.stringify(athletePayment)
        });
    }

    /**
     * Update athlete payment
     * @returns {Promise}
     */
    static updateAthletePayment(athletePayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athletePayment/`, {
            method: 'PUT',
            body: JSON.stringify(athletePayment)
        });
    }

    /**
     * Cancel athlete payment
     * @returns {Promise}
     */
    static cancelAthletePayment(athletePaymentId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athletePayment/cancel/${athletePaymentId}`, {
            method: 'PUT'
        });
    }

    /**
     * Delete team
     * @returns {Promise}
     */
    static deleteAthletePayment(athletePayment) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athletePayment/`, {
            method: 'DELETE',
            body: JSON.stringify(athletePayment)
        });
    }

    /**
     * Get athlete payments count
     * @returns {Promise}
     */
    static getAthletePaymentsCount(query) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athlete payments total amount
     * @returns {Promise}
     */
    static getAthletePaymentsTotalAmount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/totalAmount/${helperService.serialize(query)}`
        );
    }


    /**
     * Download receipt
     * @returns {Promise}
     */
    static downloadAthletePaymentReceipt(athletePaymentId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/athlete/receipt/generation/${athletePaymentId}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Athlete Payments
     * @returns {Promise}
     * @param query
     */
    static downloadAllAthletePaymentReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/athlete/receipt/generation/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Athlete Payments
     * @returns {Promise}
     * @param query
     */
    static downloadAllAthleteSubscriptionReceipt(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/athlete/receipt/subscription/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Get monthly debtors situation for each team from a given year
     * @returns {Promise<unknown>}
     */
    static getMonthlyDebtorsPerTeam(year, sportId, type) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/monthly-debtors-per-team?year=${year}&sportId=${sportId}&type=${type}`
        );
    }

    /**
     * Get monthly gross situation for each team from a given year
     * @returns {Promise<unknown>}
     */
    static getMonthlyGrossPerTeam(year, sportId, type) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athletePayment/monthly-gross-per-team?year=${year}&sportId=${sportId}&type=${type}`
        );
    }

}
