import {
  ERASE_MESSAGE, GET_CLUB_LIST,
  CLUB_LOADING, SAVE_ERROR, SAVE_SUCCESS, CLUB_NOT_LOADING,
} from "../actions/ClubActions";

const initialState = {
  clubList: [],
  loading: false,
  success: null,
  error: null
};

const ClubReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_CLUB_LIST: {
      return {
        ...state,
        clubList: action.data
      };
    }
    case CLUB_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    case CLUB_NOT_LOADING: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default ClubReducer;
