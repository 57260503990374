import Payment from "../core/Payment";

class EventPayment extends Payment{
  constructor(data) {
    super(data);
    this.eventExpenses = data.eventExpenses;
    this.eventType = data.eventType;
    this.eventDate = data.eventExpenses.date;
    this.canceled = data.canceled;
  }
}

export default EventPayment;
