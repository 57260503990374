import React from "react";
import {Redirect} from "react-router-dom";
import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import clubRoutes from "./views/club/ClubRoutes";
import presidentRoutes from "./views/president/PresidentRoutes";
import feedbackRoutes from "./views/feedback/FeedbackRoutes";
import profileRoutes from "./views/profile/ProfileRoutes";
import coachRoutes from "./views/coach/CoachRoutes";
import athletesRoutes from "./views/athlete/AthletesRoutes";
import parentRoutes from "./views/parent/ParentRoutes";
import sportsArenaRoutes from "./views/sportsArena/SportsArenaRoutes";
import sportRoutes from "./views/sport/SportRoutes";
import teamRoutes from "./views/team/TeamRoutes";
import calendarRoute from "./views/calendars/CalendarRoutes";
import subscriptionTypeRoutes from "./views/subscriptionType/SubscriptionTypeRoutes";
import subscriptionRoutes from "./views/subscription/SubscriptionRoutes";
import paymentRoutes from "./views/payment/PaymentRoutes";
import clubSubscriptionTypeRoutes from "./views/clubSubscriptionType/ClubSubscriptionTypeRoutes";
import invoiceRoutes from "./views/invoice/InvoiceRoutes";
import clubPaymentRoutes from "./views/clubPayment/ClubPaymentRoutes";
import eventExpensesRoutes from "./views/eventExpenses/EventExpensesRoutes";
import eventPaymentRoutes from "./views/eventPayment/EventPaymentRoutes";
import athleteFilesRoutes from "./views/athleteFile/AthleteFilesRoutes";
import athleteRegisterFilesRoutes from "./views/athleteRegisterFile/AthleteRegisterFilesRoutes";
import plansAndProgramsRoutes from "./views/trainingPlan/PlansAndProgramsRoutes";
import testPlanRoutes from "./views/testPlan/TestPlanRoutes";
import testResultsRoutes from "./views/testResults/TestResultsRoutes";
import activityResultsRoutes from "./views/activityResults/ActivityResultsRoutes";
import paymentNoticeRoutes from "./views/paymentNotice/PaymentNoticeRoutes";
import extraPaymentRoutes from "./views/extraPayment/ExtraPaymentRoutes";
import clubFinancialHistoryRoutes from "./views/clubFinancialHistory/ClubFinancialHistoryRoutes";
import documentsRoutes from "./views/document/DocumentRoutes";
import extraNotificationsRoutes from "./views/officeNotification/ExtraNotificationsRoutes";

const redirectRoute = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard/activities"/>
    }
];

const errorRoute = [
    {
        component: () => <Redirect to="/session/404"/>
    }
];

const routes = [
    ...sessionRoutes,
    ...dashboardRoutes,
    ...sportRoutes,
    ...profileRoutes,
    ...presidentRoutes,
    ...coachRoutes,
    ...parentRoutes,
    ...athletesRoutes,
    ...clubFinancialHistoryRoutes,
    ...clubRoutes,
    ...athleteFilesRoutes,
    ...plansAndProgramsRoutes,
    ...documentsRoutes,
    ...extraNotificationsRoutes,
    ...testPlanRoutes,
    ...testResultsRoutes,
    ...activityResultsRoutes,
    ...sportsArenaRoutes,
    ...teamRoutes,
    ...calendarRoute,
    ...subscriptionTypeRoutes,
    ...subscriptionRoutes,
    ...paymentRoutes,
    ...redirectRoute,
    ...clubSubscriptionTypeRoutes,
    ...invoiceRoutes,
    ...clubPaymentRoutes,
    ...eventExpensesRoutes,
    ...paymentNoticeRoutes,
    ...extraPaymentRoutes,
    ...eventPaymentRoutes,
    ...feedbackRoutes,
    ...athleteRegisterFilesRoutes,
    ...errorRoute
];

export default routes;
