import {eventPaymentRepository} from "../repositories/eventPaymentRepository";
import EventPayment from "../models/club/EventPayment";
import {athletePaymentRepository} from "../repositories/athletePaymentRepository";

class EventPaymentService {

    getEventPayments = (query) => {
        return eventPaymentRepository.getEventPayments(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getEventPaymentById = (id) => {
        return eventPaymentRepository.getEventPaymentById(id)
            .then((res) => {
                return new EventPayment(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addEventPayment = (eventPayment) => {
        return eventPaymentRepository.addEventPayment(eventPayment);
    };

    updateEventPayment = (eventPayment) => {
        return eventPaymentRepository.updateEventPayment(eventPayment);
    };

    deleteEventPayment = (eventPayment) => {
        return eventPaymentRepository.deleteEventPayment(eventPayment);
    };

    downloadEventPaymentReceipt = (eventPaymentId) => {
        return eventPaymentRepository.downloadEventPaymentReceipt(eventPaymentId);
    };

    getEventPaymentsCount = (query) => {
        return eventPaymentRepository.getEventPaymentsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    /**
     * Check if a given event has no costs
     * @returns {boolean}
     */
    isNoPaymentEvent = (event, club) => {
        return club.stateFunded || !(event.cost > 0);
    };
    downloadAllEventPaymentReceipt = (query) => {
        return eventPaymentRepository.downloadAllEventPaymentReceipt(query);
    };
    getEventPaymentsTotalAmount = (query) => {
        return eventPaymentRepository.getEventPaymentsTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new EventPaymentService();
