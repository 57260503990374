export const ECardSituationStatus = {
    RED_CARD: 'RED_CARD',
    BLUE_CARD: 'BLUE_CARD',
    YELLOW_CARD: 'YELLOW_CARD',
    ELIGIBLE: 'ELIGIBLE'
};

export const ECardSituationStatusTranslated = {
    RED_CARD: 'athleteFile.cardsSituationHistory.status.redCard',
    BLUE_CARD: 'athleteFile.cardsSituationHistory.status.blueCard',
    YELLOW_CARD : 'athleteFile.cardsSituationHistory.status.yellowCard',
    ELIGIBLE: 'athleteFile.cardsSituationHistory.eligible'
};
