import {parentRepository} from "../repositories/parentRepository";

class ParentService {
    getParents = (query) => {
        return parentRepository.getParents(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getParentsCount = (query) => {
        return parentRepository.getParentsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getParentById = (id) => {
        return parentRepository.getParentById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getParentByAccessUserId = (id) => {
        return parentRepository.getParentByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateParent = (parent) => {
        return parentRepository.updateParent(parent);
    };

    deleteParent = (parent) => {
        return parentRepository.deleteParent(parent);
    };

    updateParentProperties = (parentRequest) => {
        return parentRepository.updateParentProperties(parentRequest);
    };

}

export default new ParentService();
