import Auditable from "../core/Auditable";

class TrainingPlan extends Auditable {
    constructor(data) {
        super(data);
        this.id = data.id;
        this.fileName = data.fileName;
        this.extension = data.extension;
        this.clubId = data.clubId;
        this.creatorRole = data.creatorRole;
        this.visibleForSportIds = data.visibleForSportIds;
    }
}

export default TrainingPlan;
