export const EAthleteStatus = {
    CONFIRMED: 'CONFIRMED',
    DENIED: 'DENIED',
    PENDING: 'PENDING'
};

export const EAthleteStatusTranslated = {
    CONFIRMED: 'athlete.status.confirmed',
    DENIED: 'athlete.status.denied',
    PENDING: 'athlete.status.pending'
};
