import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Parents = EgretLoadable({
  loader: () => import("./Parents")
});

const ParentDetails = EgretLoadable({
   loader: () => import("../profile/ParentDetails")
});

const parentRoutes = [
  {
    path: "/parent/list",
    exact: true,
    component: Parents,
    auth:userUtilsService.getClubStaffRoles()
  },
  {
    path: "/parent/:id",
    exact: true,
    component: ParentDetails,
    auth: userUtilsService.getAllRoles()
  }
];

export default parentRoutes;
