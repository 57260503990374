import React, { Component } from "react";
import {Dialog, IconButton, Button, Icon, withStyles, Card} from "@material-ui/core";
import "date-fns";
import i18n from "../../../i18n";
import Loader from "react-loader-spinner";
import {themeColors} from "../../EgretLayout/EgretTheme/themeColors";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import ScrollBar from "react-perfect-scrollbar";
import Radio from "@material-ui/core/Radio";
import {ERoleTranslated} from "../enums/ERole";

const styles = () => ({
    loader: {
        marginTop: "5vh",
        marginBottom: "5vh"
    },
    scrollableList: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: '1',
        overflowY: 'auto',
        height: '120px',
        content: {
            position: 'relative'
        }
    },
    disabledButton: {
        backgroundColor: "lightgray !important"
    },
});

class SelectDialog extends Component {
    state = {
        selectedItem: null
    };

    componentDidMount() {
        const {getList, selected} = this.props;
        this.setState({
            ...this.state,
            selectedItem: selected
        });
        if (getList) {
            getList();
        }
    }

    handleModifyAction = () => {
        if (this.state.selectedItem) {
            this.props.handleModifyAction(this.state.selectedItem);
        }
    };

    handleChange = item => {
        this.setState({
            ...this.state,
            selectedItem: item
        });
    };

    getItemValue = (item, field)  => {
        if (field === 'fullName') {
            return (item['firstName'] || '') + ' ' + (item['lastName'] || '');
        } else if (field === 'roleName') {
            return i18n.t(ERoleTranslated[item[field]]);
        } else {
            return item[field];
        }
    };

    render() {
        let { selectedItem } = this.state;
        let { open, handleClose, classes, list, listRetrieved, title, label, small} = this.props;

        return (
            <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth={true}>
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                    <h4 className="m-0 text-white" style={{lineHeight: '1.4'}}>{title || ''}</h4>
                    <IconButton onClick={handleClose}>
                        <Icon className="text-white">clear</Icon>
                    </IconButton>
                </div>

                <div className="p-16">
                    {listRetrieved ? (
                        <ScrollBar className={classes.scrollableList}>
                            {list.map(item => (
                                <div className="content" key={item.id}>
                                    <div className="flex flex-middle flex-space-between">
                                        <FormControlLabel
                                            className="flex-grow-1"
                                            name={item.id}
                                            onChange={() => this.handleChange(item)}
                                            control={<Radio checked={selectedItem && selectedItem.id === item.id} />}
                                            label={this.getItemValue(item,label)}
                                        />
                                       <small className="badge bg-light-primary text-primary mr-16">
                                           {this.getItemValue(item,small)}
                                       </small>
                                    </div>
                                </div>
                            ))}
                        </ScrollBar>
                        ) : (
                        <Card elevation={3} style={{innerHeight:'35vw'}} className="position-relative p-16 mb-16 text-center">
                            <Loader
                                className = {classes.loader}
                                type="Oval"
                                color={themeColors.purple1.palette.primary.main}
                                height={100}
                                width={100}
                            />
                        </Card>
                        )
                    }
                    <div className="flex flex-space-between flex-middle pt-16" >
                        {listRetrieved && list.length > 0 &&
                        (
                            <div className="flex flex-space-between flex-middle pt-16" style={{ width: "410px", display: "flex", justifyContent: "space-around"}}>
                                <Button onClick={this.handleModifyAction} variant="contained" type="submit"
                                        style={{background: "#417505", color: "white"}}
                                        classes={{disabled: classes.disabledButton}}
                                        disabled={this.state.selectedItem === null}>
                                    {i18n.t('actions.select')}
                                </Button>

                                <Button onClick={handleClose} variant="contained"
                                        style={{background: "#D0021B", color: "white"}}>
                                    {i18n.t('actions.letGo')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    (SelectDialog)
);
