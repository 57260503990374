class BiometricData {
    weight = null;
    height = null;
    armSpan = null;
    shoeSize = null;
    date = null;
    calfCircumference = null;
    thighCircumference = null;
    chestCircumference = null;
    bodyFatIndex = null;
    constructor(data) {
        if (data) {
            this.weight = data.weight;
            this.height = data.height;
            this.armSpan = data.armSpan;
            this.shoeSize = data.shoeSize;
            this.calfCircumference = data.calfCircumference;
            this.thighCircumference = data.thighCircumference;
            this.chestCircumference = data.chestCircumference;
            this.bodyFatIndex = data.bodyFatIndex;
            this.date = data.date;
        }
    }
}

export default BiometricData;

