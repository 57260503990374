import {STORAGE_KEY, StorageAdapter} from '../adapters/storageAdapter';
import Token from "../models/Token";

export class SessionService {

    static AUTH_HEADER = 'Authorization';

    /**
     * Gets raw token from the storage
     * @returns {String}
     */
    static getToken() {
        return StorageAdapter.getItem(STORAGE_KEY.TOKEN);
    }

    /**
     * Save token to the localStorage
     * @param {Object} token
     */
    static saveToken(token) {
        StorageAdapter.setItem(STORAGE_KEY.TOKEN, token);
    }

    /**
     * Deletes token from storage
     * @returns {Boolean}
     */
    static deleteToken() {
        StorageAdapter.delete(STORAGE_KEY.TOKEN);
    }

    /**
     * Deletes token from storage
     * @returns {Boolean}
     */
    static deleteHelperDisplayed() {
        StorageAdapter.delete(STORAGE_KEY.HELPER_DISPLAYED);
    }

    /**
     * Parses middle part of the token which contains payload
     * @param {string} tokenOrigin
     * @param {Number} [part=1] - part of token, default token's payload
     * @returns {Object} token's part
     */
    static decodeToken(tokenOrigin, part = 1) {
        if (!tokenOrigin) {
            return new Token();
        }

        let token;

        try {
            token = new Token(JSON.parse(window.atob(tokenOrigin.split('.')[part])));
        } catch (err) {
            throw new Error('Invalid token string: ' + err.message);
        }

        return token;
    }

    /**
     * Return time difference between now and expiration time
     * @returns {number}
     * @private
     */
    static _getTimeDiff() {
        const tokenHash = this.getToken();
        if (!tokenHash) {
            return 0;
        }
        const token = this.decodeToken(tokenHash);
        return (token.exp - token.iat) * 1000;
    }

    /**
     * Set Authorization cookie
     * @param {String} token
     */
    static setCookie(token) {
        StorageAdapter.setCookie(this.AUTH_HEADER, token, this._getTimeDiff());
        return this;
    }

    /**
     * Remove Authorization cookie
     */
    static removeCookie() {
        StorageAdapter.removeCookie(this.AUTH_HEADER);
    }

}
