import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Feedback = EgretLoadable({
    loader: () => import("./Feedback")
});

const feedbackRoutes = [
    {
        path: "/feedback/",
        exact: true,
        component: Feedback,
        auth: userUtilsService.getAllRoles()
    }
];

export default feedbackRoutes;
