import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athleteRepositoryMobile {

    /**
     * Get athlete by id
     * @returns {Promise}
     * @param id
     */
    static getAthleteModelId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/mobile/${id}`
        );
    }

    /**
     * Get athlete entity by accessUser id that contains profile information
     * @returns {Promise}
     * @param id
     * @param token
     */
    static getAthleteByAccessUserId(id, token) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/access-user/mobile/${id}`, {}, false
        );
    }

    /**
     * Get athletes
     * @returns {Promise}
     * @param query
     */
    static getAthletes(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/mobile/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athletes
     * @returns {Promise}
     * @param query
     */
    static getActiveAthletes() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/allActive`
        );
    }
}
