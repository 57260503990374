import athletePaymentService from "../../shared/services/athletePaymentService";
import history from "../../../history";
import i18n from "../../../i18n";
import {EPaymentResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_ATHLETE_PAYMENT_LIST = "GET_ATHLETE_PAYMENT_LIST";
export const GET_ATHLETE_PAYMENT_LIST_TIMESTAMP = "GET_ATHLETE_PAYMENT_LIST_TIMESTAMP";
export const GET_ATHLETE_PAYMENTS_COUNT = "GET_ATHLETE_PAYMENTS_COUNT";
export const GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT = "GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT";
export const GET_ATHLETE_PAYMENTS_COUNT_TIMESTAMP = "GET_ATHLETE_PAYMENTS_COUNT_TIMESTAMP";
export const GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT_TIMESTAMP = "GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const ATHLETE_PAYMENT_LOADING = "ATHLETE_PAYMENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getAthletePayments(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_PAYMENT_LIST_TIMESTAMP,
            data: currentTime
        });
        return athletePaymentService
            .getAthletePayments(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_PAYMENT_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}


export function getAthletePaymentsCount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_PAYMENTS_COUNT_TIMESTAMP,
            data: currentTime
        });
        return athletePaymentService
            .getAthletePaymentsCount(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_PAYMENTS_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}
export function getAthletePaymentsTotalAmount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT_TIMESTAMP,
            data: currentTime
        });
        return athletePaymentService
            .getAthletePaymentsTotalAmount(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_PAYMENTS_TOTAL_AMOUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateAthletePayment(athletePayment) {
    return dispatch => {
        dispatch({
            type: ATHLETE_PAYMENT_LOADING
        });
        return athletePaymentService
            .updateAthletePayment(athletePayment)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addAthletePayment(athletePayment) {
    return dispatch => {
        dispatch({
            type: ATHLETE_PAYMENT_LOADING
        });
        return athletePaymentService
            .addAthletePayment(athletePayment)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
