export const GET_PLAYER_LIST = "GET_PLAYER_LIST";

export const getPlayerList = () => dispatch => {
  fetch('https://react-native-online-store.firebaseio.com/players.json')
      .then(res => res.json())
      .then((data) => {
        dispatch({
          type: GET_PLAYER_LIST,
          payload : data
        });
      });
};
