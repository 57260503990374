import {
  ERASE_MESSAGE, COACH_LOADING, SAVE_ERROR, SAVE_SUCCESS, GET_COACH_LIST, GET_COACH_LIST_TIMESTAMP,
  GET_COACHES_COUNT, GET_COACHES_COUNT_TIMESTAMP
} from "../actions/CoachActions";

const initialState = {
  loading: false,
  success: null,
  error: null,
  coachList: [],
  getCoachesTimestamp: null,
  count: 0,
  getCoachesCountTimestamp: null
};

const CoachReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_COACH_LIST_TIMESTAMP: {
      return {
        ...state,
        getCoachesTimestamp: action.data
      };
    }
    case GET_COACH_LIST: {
      if (action.data.time === state.getCoachesTimestamp) {
        return {
          ...state,
          coachList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_COACHES_COUNT_TIMESTAMP: {
      return {
        ...state,
        getCoachesCountTimestamp: action.data
      };
    }
    case GET_COACHES_COUNT: {
      if (action.data.time === state.getCoachesCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case COACH_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default CoachReducer;
