import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import React from "react";

export function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
        >
            <Paper {...props} />
        </Draggable>
    );
}
