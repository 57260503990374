import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class invoiceRepository {

    /**
     * Generate invoices
     * @returns {Promise}
     */
    static generateInvoices(params) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/generate`, {
            method: 'POST',
            body: JSON.stringify(params)
        });
    }


    /**
     * Get invoices
     * @returns {Promise}
     * @param query
     */
    static getInvoices(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/${helperService.serialize(query)}`
        );
    }

    /**
     * Get invoice by id
     * @returns {Promise}
     * @param id
     */
    static getInvoiceById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/${id}`
        );
    }

    /**
     * Delete invoice
     * @returns {Promise}
     */
    static deleteInvoice(invoice) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/`, {
            method: 'DELETE',
            body: JSON.stringify(invoice)
        });
    }

    /**
     * Download Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadAllInvoices(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/report/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

        /**
         * Get invoices count
         * @returns {Promise}
         */
        static getInvoicesCount(query = {}) {
            return FetchAdapter.request(
                `${APP_CONFIG.URL.root}/invoice/count/${helperService.serialize(query)}`
            );
        }

    /**
     * Get invoices count
     * @returns {Promise}
     */
    static getInvoicesStats(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/stats/${helperService.serialize(query)}`
        );
    }
    /**
     * Get invoice language
     * @returns {Promise}
     * @param clubId
     */
    static getInvoiceLanguage(clubId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/language/?clubId=${clubId}`
        );
    }
    /**
     * Delete invoice
     * @returns {Promise}
     */
    static cancelInvoice(invoice) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/cancel`, {
            method: 'PUT',
            body: JSON.stringify(invoice)
        });
    }
    /**
     * Generate E-Factura
     * @returns {Promise}
     */
    static generateEFactura(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/efactura/validate/${id}`, {
            method: 'POST'
        });
    }
    /**
     * Upload E-Factura
     * @returns {Promise}
     */
    static uploadEFactura(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/efactura/upload/${id}`, {
            method: 'POST'
        });
    }
    /**
     * Get Stare Mesaj E-Factura
     * @returns {Promise}
     */
    static getStareMesajEFactura(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/efactura/stareMesaj/${id}`);
    }
    /**
     * Delete E-Factura
     * @returns {Promise}
     */
    static deleteEFactura(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/invoice/efactura/delete/${id}`, {
            method: 'DELETE'
        });
    }
}
