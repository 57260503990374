import {athleteRepositoryMobile} from "../repositories/athleteRepositoryMobile";

class AthleteServiceMobile {

    getAthleteModelById = (id) => {
        return athleteRepositoryMobile.getAthleteModelId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteByAccessUserId = (id, token) => {
        return athleteRepositoryMobile.getAthleteByAccessUserId(id, token)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthletes = (query) => {
        return athleteRepositoryMobile.getAthletes(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getClubActiveAthletes = () => {
        return athleteRepositoryMobile.getActiveAthletes()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new AthleteServiceMobile();
