export const EVisibleForUsers = {
    STAFF: 'STAFF',
    ATHLETES_PARENTS: 'ATHLETES_PARENTS',
    ALL: 'ALL'
};

export const EVisibleForUsersTranslated = {
    STAFF: 'users.staff',
    ATHLETES_PARENTS: 'users.athletesParents',
    ALL: 'users.all'
};
