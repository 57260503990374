import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class trainingEventRepository {

    /**
     * Add new training event
     * @returns {Promise}
     */
    static addTrainingEvent(trainingEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/event/`, {
            method: 'POST',
            body: JSON.stringify(trainingEvent)
        });
    }

    /**
     * Update training event
     * @returns {Promise}
     */
    static updateTrainingEvent(trainingEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/trainingEvent/`, {
            method: 'PUT',
            body: JSON.stringify(trainingEvent)
        });
    }

    /**
     * Delete training event
     * @returns {Promise}
     */
    static deleteTrainingEvent(trainingEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/event/`, {
            method: 'DELETE',
            body: JSON.stringify(trainingEvent)
        });
    }

}
