import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class trainingRepository {

    /**
     * Get trainings
     * @returns {Promise}
     */
    static getTrainings(start, end, sportId, teamId, athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training?start=${helperService.formatDate(start)}&end=${helperService.formatDate(end)}` +
            `${sportId != null ? '&sportId=' + sportId : ''}${teamId != null ? '&teamId=' + teamId : ''}${athleteId != null ? '&athleteId=' + athleteId : ''}`
        );
    }

    /**
     * Get training by id
     * @returns {Promise}
     * @param id
     */
    static getTrainingById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/${id}`
        );
    }

    /**
     * Get next training
     * @returns {Promise}
     */
    static getNextTraining(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/next/${helperService.serialize(params)}`
        );
    }

    /**
     * Get last training
     * @returns {Promise}
     */
    static getLastTraining(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/last/${helperService.serialize(params)}`
        );
    }

    /**
     * Get attendance situation
     * @returns {Promise}
     */
    static getAttendanceSituation(athleteId, teamId, month, year) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/monthly-athlete-attendance-situation/${athleteId}${teamId ? '?teamId='+teamId : ''}&month=${month}&year=${year}`
        );
    }

    /**
     * Get attendance situation for a specific team from last training
     * @param teamId
     * @returns {Promise<unknown>}
     */
    static getTeamAttendanceSituationForLastTraining(teamId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/last-team-attendance-situation/${teamId}`
        );
    }


    /**
     * Get attendance situation for a specific team for all trainings from specific month of the year
     * @param teamId
     * @param month
     * @param year
     * @returns {Promise<unknown>}
     */
    static getTeamAttendanceMonthlySituation(teamId, month, year) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/monthly-team-attendance-situation/${teamId}?month=${month}&year=${year}`
        );
    }

    /**
     * Get attendance situation from last training for each team
     * @returns {Promise<unknown>}
     */
    static getTeamsAttendanceSituationForLastTraining(sportId, coachId){
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/last-attendance-situation/${coachId ? '?coachId=' + coachId : ''}${sportId ? '&sportId=' + sportId : ''}`
        );
    }

    /**
     * Get monthly attendance situation
     * @returns {Promise}
     */
    static getMonthlyAttendanceSituation(year, coachId, sportId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/monthly-attendance-situation?year=${year}${coachId ? '&coachId=' + coachId : ''}${sportId ? '&sportId=' + sportId : ''}`
        );
    }

    /**
     * Get monthly average grade
     * @returns {Promise}
     */
    static getMonthlyAverageGradeForTrainings(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/average-grade/${helperService.serialize(params)}`
        );
    }

    /**
     * Update training
     * @returns {Promise}
     */
    static updateTraining(training) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/training/`, {
            method: 'PUT',
            body: JSON.stringify(training)
        });
    }

    /**
     * Delete training
     * @returns {Promise}
     */
    static deleteTraining(training) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/training/`, {
            method: 'DELETE',
            body: JSON.stringify(training)
        });
    }

    /**
     * Delete current training and all upcoming events
     * @returns {Promise}
     */
    static deleteUpcomingTrainings (training) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/upcomingTrainings`, {
            method: 'DELETE',
            body: JSON.stringify(training)
        });
    }

    /**
     * Update training properties
     * @returns {Promise}
     */
    static updateTrainingProperties(trainingRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/update`, {
            method: 'PATCH',
            body: JSON.stringify(trainingRequest)
        });
    }

    /**
     * Update properties for all upcoming trainings
     * @returns {Promise}
     */
    static updateUpcomingTrainingsProperties(trainingRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/updateUpcomingTrainings`, {
            method: 'PATCH',
            body: JSON.stringify(trainingRequest)
        });
    }

}
