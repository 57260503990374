import {competitionRepository} from "../repositories/competitionRepository";
import Competition from "../models/club/Competition";
import MonthlyAverageGradeResponse from "../models/club/MonthlyAverageGradeResponse";

class CompetitionService {

    getCompetitions = (start, end, sportId, teamId, athleteId) => {
        return competitionRepository.getCompetitions(start, end, sportId, teamId, athleteId)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new Competition(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getNextCompetition = (params) => {
        return competitionRepository.getNextCompetition(params)
            .then((res) => {
                if (res) {
                    return new Competition(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getMonthlyAverageGradeForCompetitions = (params) => {
        return competitionRepository.getMonthlyAverageGradeForCompetitions(params)
            .then((res) => {
                if (res) {
                    return new MonthlyAverageGradeResponse(res);
                }
                return Promise.reject();
            }).catch((err) => {
                return Promise.reject(err);
            });
    };

    getCompetitionById = (id) => {
        return competitionRepository.getCompetitionById(id)
            .then((res) => {
                return new Competition(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateCompetition = (competition) => {
        return competitionRepository.updateCompetition(competition);
    };

    deleteCompetition = (competition) => {
        return competitionRepository.deleteCompetition(competition);
    };

    updateCompetitionProperties = (competitionRequest) => {
        return competitionRepository.updateCompetitionProperties(competitionRequest);
    };

}

export default new CompetitionService();
