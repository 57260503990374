import {eventExpensesRepositoryMobile} from "../repositories/eventExpensesRepositoryMobile";

class EventExpensesServiceMobile {

    getEventExpenses = (query) => {
        return eventExpensesRepositoryMobile.getEventExpenses(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new EventExpensesServiceMobile();
