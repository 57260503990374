import React, { Component } from "react";
import {
  Card,
  Grid,
  Button, withStyles, IconButton
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {eraseResetPasswordMessage, forgotPassword} from "../../redux/actions/ResetPasswordActions";
import {withTranslation} from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import ReactFlagsSelect from "react-flags-select";

const styles = theme => ({
  logo: {
    height: "250px"
  }
});

class ResetPassword extends Component {
  state = {
    password: "",
    repeatPassword: "",
    showPassword: false,
    token: "",
    selectedLanguage: ""
  };

  componentDidMount() {
    if (this.props.match.params.token) {
      this.setState({
        ...this.state,
        token: this.props.match.params.token,
        selectedLanguage: this.props.i18n.language === 'en' ? 'GB' : this.props.i18n.language.toUpperCase()
      });
    }
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value === this.state.password;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = () => {
    this.props.forgotPassword({"token": this.state.token, "password": this.state.password});
  };

  togglePasswordVisibility = () => {
    const showPassword = (!this.state.showPassword);
    this.setState({
      ...this.state,
      showPassword
    });
  };

  handleLanguageClick = (lng) => {
    const {i18n} = this.props;
    i18n.changeLanguage(lng).then();
  };

  renderLanguage = () => {
    return (
        <ReactFlagsSelect
            countries={["RO", "GB"]}
            showOptionLabel={false}
            showSelectedLabel={false}
            defaultCountry="RO"
            selected={this.state.selectedLanguage}
            selectedSize={18}
            optionsSize={18}
            onSelect={this.onSelectFlag}
            selectButtonClassName="language"
        />
    );

  };

  onSelectFlag = (countryCode) => {
    switch(countryCode) {
      case 'RO':
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
      case 'GB':
        this.handleLanguageClick('en');
        this.setState({
          ...this.state,
          selectedLanguage: 'GB'
        });
        break;
      default:
        this.handleLanguageClick('ro');
        this.setState({
          ...this.state,
          selectedLanguage: 'RO'
        });
        break;
    }
  };

  render() {
    let { password, repeatPassword, showPassword } = this.state;
    let {classes, t, resetPassword} = this.props;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                  <img src="/assets/logo/Logo-Expert-Sport-Club-Outlined-02.svg" alt="" className={classes.logo}/>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100">
                  <div className="px-16 mb-20 flex flex-middle flex-space-between"><h3>{t('changePassword.title')}</h3>
                    <div style={{float: 'right !important'}}>
                      {this.renderLanguage()}
                    </div>
                  </div>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <div className="flex flex-middle mb-8">
                      <TextValidator
                          className="mb-16 w-100"
                          label={t('form.password')}
                          variant="outlined"
                          onChange={this.handleChange}
                          name="password"
                          type={showPassword === true ? "text" :"password"}
                          value={password}
                          validators={["required", "minStringLength:6"]}
                          errorMessages={[
                            t('validation.error.required'),
                            t('validation.error.password.tooShort')
                          ]}
                      />
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            color="primary"
                            onClick={this.togglePasswordVisibility}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                    <div className="flex flex-middle mb-8">
                      <TextValidator
                          className="mb-16 w-100"
                          label={t('form.repeatPassword')}
                          variant="outlined"
                          onChange={this.handleChange}
                          name="repeatPassword"
                          type={showPassword === true ? "text" :"password"}
                          value={repeatPassword}
                          validators={["isPasswordMatch", "required"]}
                          errorMessages={[
                            t('validation.error.password.mismatch'),
                            t('validation.error.required')
                          ]}
                      />
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            color="primary"
                            onClick={this.togglePasswordVisibility}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                    <div className="flex flex-middle">
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        {t('actions.resetPassword')}
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
          <CustomizedSnackbar message={resetPassword.error} severity="error" onClose={this.props.eraseResetPasswordMessage}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: state.resetPassword
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            { forgotPassword, eraseResetPasswordMessage}
        )(withTranslation()(ResetPassword))
    )
);
