export const EUserStatus = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    CANCELED: 'CANCELED',
    PENDING: 'PENDING'
};

export const EUserStatusTranslated = {
    ACTIVE: 'status.active',
    PAUSED: 'status.paused',
    CANCELED: 'status.canceled',
    PENDING: 'status.pending'
};

export const EUserStatusEdit = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    CANCELED: 'CANCELED'
};
