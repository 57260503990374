import Auditable from "../core/Auditable";

class DisciplinarySituation extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.startDate = new Date(data.startDate);
            this.endDate = new Date(data.endDate);
            this.disciplinaryStatus = data.disciplinaryStatus;
            this.reasonForSuspension = data.reasonForSuspension;
        }
    }
}

export default DisciplinarySituation;

