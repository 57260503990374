import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import TestResultsDetails from "./TestResultsDetails";

const TestResults = EgretLoadable({
  loader: () => import("./TestResults")
});

const testResultsRoutes = [
  {
    path: "/sportfiles/tests",
    exact: true,
    component: TestResults,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/sportfiles/tests/:id",
    exact: true,
    component: TestResultsDetails,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default testResultsRoutes;
