class ContactInfo {
    constructor(data) {
        if (data) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.name = data.name;
            this.phone = data.phone;
            this.email = data.email;
            this.webSite = data.webSite;
        }
    }
}

export default ContactInfo;
