import React, {Component} from "react";
import {
    Button,
    Card,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    Icon,
    IconButton,
    MenuItem,
    withStyles,
} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {addExtraPaymentNotice, eraseMessage} from "../../redux/actions/ExtraPaymentNoticeActions";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import CurrentUser from "../../shared/models/CurrentUser";
import Loader from "react-loader-spinner";
import {Breadcrumb, ConfirmationDialog} from "../../../egret";
import {withTranslation} from "react-i18next";
import {ECurrency} from "../../shared/enums/ECurrency";
import {themeColors} from "../../EgretLayout/EgretTheme/themeColors";
import Typography from "@material-ui/core/Typography";
import DateModifiedDetails from "../../shared/components/DateModifiedDetails";
import HelpInfo from "../../shared/components/HelpInfo";
import Auditable from "../../shared/models/core/Auditable";
import i18n from "../../../i18n";
import MandatoryLabel from "../../shared/components/MandatoryLabel";
import {classList} from "../../../utils";
import extraPaymentService from "../../shared/services/extraPaymentService";
import ExtraPaymentNotice from "../../shared/models/club/ExtraPaymentNotice";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {enGB, ro} from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FragmentList from "../../shared/components/FragmentList";
import AddDialog from "../../shared/components/AddDialog";
import athleteServiceMobile from "../../shared/services/athleteServiceMobile";
import {EUserStatus} from "../../shared/enums/EUserStatus";
import {PaperComponent} from "../../shared/components/PaperComponent";
import PaymentDialog from "../../shared/components/PaymentDialog";
import paymentService from "../../shared/services/paymentService";

const styles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    wrapper: {
        marginTop: 50,
        position: "relative",
        textAlign: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    loader: {
        position:"absolute",
        marginTop: "30vh",
        marginLeft:"37vw"
    }
});

const columns = ['form.lastName', 'form.firstName', 'form.email'];
const topFields = ["lastName", "firstName"];
const bottomFields = ["email"];


class PaymentNoticeDetails extends Component {
    _isMounted = false;
    state = {
        extraPaymentNotice: {
            fee: 0,
            currency: ECurrency.RON,
            date: new Date(),
            deadline: new Date()
        },
        loading: true,
        currentUser: null,
        club: null,
        shouldOpenWarningDialog: false,
        shouldOpenPaymentDialog: false,
        displaySpinner: false,
        athletesRetrieved: false,
        user: []
    };

    componentDidMount() {
        this._isMounted = true;
        if (!this.isNewExtraPaymentNotice()) {
            extraPaymentService.getExtraPaymentNoticeById(this.props.match.params.id).then(res => {
                const extraPaymentNotice = new ExtraPaymentNotice(res);
                this.setState({
                    ...this.state,
                    extraPaymentNotice,
                    currentUser: new CurrentUser(this.props.user),
                    loading: false
                });
            });
        }
        else {
            this.setState({
                ...this.state,
                currentUser: new CurrentUser(this.props.user),
                loading: false,
                user: [new CurrentUser(this.props.user)]
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    isNewExtraPaymentNotice() {
        return this.props.match.params.id === "add";
    };

    handleChange = event => {
        event.persist();
        this.setState({
            ...this.state,
            extraPaymentNotice: {...this.state.extraPaymentNotice, [event.target.name]: event.target.value }
        });
    };

    handleDeadlineChange = date => {
        const extraPaymentNotice = this.state.extraPaymentNotice;
        if(date >= extraPaymentNotice.date) {
            extraPaymentNotice.deadline = date;
            this.setState({
                ...this.state,
                extraPaymentNotice
            });
        }
    };

    handleSubmit = () => {
        if (this.state.currentUser.club && !this.state.currentUser.club.onlinePaymentsActive)
            this.setState({
                shouldOpenWarningDialog: true
            });
        else {
            this.setState({
                    ...this.state,
                    shouldOpenPaymentDialog: true
                }
            )}
    };

    payDonation = () => {
        this.setState({
            ...this.state,
            displaySpinner: true
        }, () => {
            extraPaymentService.addDonation(this.state.extraPaymentNotice).then(link => {
                this.setState({
                    ...this.state,
                    displaySpinner: false,
                    shouldOpenPaymentDialog: false
                }, () => {
                    window.location.assign(link);
                });
            });
        });
    };

    handleValidationErrors = errors => {
        document.getElementById(errors[0].props.name).focus();
    };

    isDisabled = user => {
       // return user ? !(user.isClubPresidentManagerRole() || user.isClubMainCoachRole() || user.isClubCoachTeamRole()) : true;
        return user ? false : true;
    };

    isSaveDisabled = () => {
        const {extraPaymentNotice} = this.state;
        return  extraPaymentNotice && extraPaymentNotice.description && extraPaymentNotice.fee > 0 ? false : true;
    }

    getHelpDetails = () => {
        return {
            content: i18n.t("help.extraPaymentNotice")
        };
    };

    getTitle() {
        const {t} = this.props;
        return  t('dashboard.donate.title') ;
    }

    goBack(){
        this.props.location.state ? this.props.history.push({pathname: this.props.location.state.path, state: {query: this.props.location.state.query}}) : this.props.history.goBack();
    }

    handleViewClick = (id) => {
        this.props.history.push(`/athlete/${id}`);
    };
    render() {

        let {
            description,
            fee,
            currency,
            date,
            deadline
        } = this.state.extraPaymentNotice;

        const {currentUser, extraPaymentNotice, loading, shouldOpenWarningDialog, shouldOpenPaymentDialog, user  } = this.state;

        let {classes, t} = this.props;

        return (
            !this.isNewExtraPaymentNotice() && extraPaymentNotice.id === undefined ? (
                <Loader
                    className = {classes.loader}
                    type="Oval"a fos
                    color={themeColors.purple1.palette.primary.main}
                    height={100}
                    width={100}
                />
            ) : (
                <div className="m-sm-30">
                    <Card elevation={6}>
                        <div className="invoice-viewer py-16">
                            <div className="viewer_actions flex flex-space-between alignCenter pl-16 pr-8 py-8 bg-primary fixed-content" id="header">
                                <IconButton onClick={() => this.goBack()}>
                                    <Icon  className="text-white">arrow_back</Icon>
                                </IconButton>
                                <h4 className="mb-16 m-auto text-white">{this.getTitle()}</h4>
                            </div>
                            <br></br>

                            <div id="print-area">
                                <div className="viewer__order-info px-16 mb-32">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <Grid container spacing={6}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div style={{display: "flex"}}>
                                                    {(description === undefined ||
                                                            description.replace(/\s/g, '') === '' ) &&
                                                        (<MandatoryLabel />)}

                                                    <TextValidator
                                                        className="mb-16 w-100"
                                                        label={this.isDisabled(currentUser) ? <Typography className={"ui-state-disabled"}>{t("dashboard.donate.descriptionLabel")}</Typography>
                                                            : t("dashboard.donate.descriptionLabel")}
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name="description"
                                                        value={description || ''}
                                                        validators={["required"]}
                                                        errorMessages={[t('validation.error.required')]}
                                                        id="name"
                                                        disabled={this.isDisabled(currentUser)}
                                                        InputProps={{ className: "ui-state-disabled" }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={6}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextValidator
                                                    className="mb-16 w-100"
                                                    label={t('extraPaymentNotice.fee')}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                    name="fee"
                                                    value={fee}
                                                    validators={[
                                                        "required",
                                                        "matchRegexp:^(?:[1-9]\\d*|0)?(?:\\.\\d+)?$"
                                                    ]}
                                                    errorMessages={[t('validation.error.required'), t('validation.error.double')]}
                                                    id="fee"
                                                    disabled={this.isDisabled(currentUser)}
                                                    InputProps={{ className: "ui-state-disabled" }}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextValidator
                                                    className="mb-16 w-100"
                                                    label={t('extraPaymentNotice.currency')}
                                                    select
                                                    name="currency"
                                                    value={currency || ''}
                                                    validators={["required"]}
                                                    errorMessages={[t('validation.error.required')]}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled= {this.isDisabled(currentUser)}
                                                    InputProps={{ className: "ui-state-disabled" }}
                                                >
                                                    {Object.entries(ECurrency).map(([key,value],i) => (
                                                        <MenuItem key={key} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </TextValidator>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={6}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider locale={i18n.language === 'en' ? enGB : ro}
                                                                         utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        required={false}
                                                        className="w-100"
                                                        margin="none"
                                                        id="mui-pickers-date"
                                                        label={ <Typography
                                                            className={"ui-state-disabled"}>{t("extraPaymentNotice.date")}</Typography>
                                                        }
                                                        inputVariant="standard"
                                                        type="text"
                                                        autoOk={true}
                                                        value={date || null}
                                                        format="dd-MM-yyyy"
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date"
                                                        }}
                                                        disabled={true}
                                                        InputProps={{className: "ui-state-disabled"}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider locale={i18n.language === 'en' ? enGB : ro}
                                                                         utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        required={true}
                                                        className="w-100"
                                                        margin="none"
                                                        id="mui-pickers-date"
                                                        label={this.isDisabled(currentUser) ? <Typography
                                                                className={"ui-state-disabled"}>{t("extraPaymentNotice.deadline")}</Typography>
                                                            : t("extraPaymentNotice.deadline")}
                                                        inputVariant="standard"
                                                        type="text"
                                                        autoOk={true}
                                                        value={deadline || null}
                                                        onChange={this.handleDeadlineChange}
                                                        format="dd-MM-yyyy"
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date"
                                                        }}
                                                        disabled={true}
                                                        // disabled={this.isDisabled(currentUser)}
                                                        InputProps={{className: "ui-state-disabled"}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                        <div className="py-20">
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        {t("dashboard.donate.donor")}:
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{display:"block"}} onClick={(event) => event.stopPropagation()}>
                                                    <FragmentList
                                                        list={user}
                                                        columns = {columns}
                                                        topFields = {topFields}
                                                        bottomFields = {bottomFields }
                                                        showCheckbox = {false}
                                                        showActions = {false}
                                                        showActionColumn = {false}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>

                                        { loading &&  (<div className={classes.wrapper}><CircularProgress /> </div>)}
                                        {!this.isDisabled(currentUser) && !loading && (
                                            <div className={classes.wrapper}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.isSaveDisabled()}
                                                    type="submit"
                                                >
                                                    <span className="capitalize">{t('dashboard.donate.button')}</span>
                                                    {(loading) && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                        <CustomizedSnackbar message={extraPaymentNotice.error} severity="error" onClose={this.props.eraseMessage}/>
                        {shouldOpenWarningDialog && (
                            <Dialog
                                open={shouldOpenWarningDialog}
                                onClose={() => this.setState({ ...this.state, shouldOpenWarningDialog: false})}
                                PaperComponent={PaperComponent}
                                aria-labelledby="draggable-dialog-title"
                            >
                                <DialogContent>
                                    <DialogContentText style={{marginTop: '16px', marginBottom: '0px', fontSize: '15px'}}>
                                        {t('dashboard.donate.warning')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={() => this.setState({ ...this.state, shouldOpenWarningDialog: false})} color="primary">
                                        {t('actions.ok')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )}
                        {shouldOpenPaymentDialog && (
                            <PaymentDialog
                                type={t('dashboard.donate.donationLabel')}
                                open={this.state.shouldOpenPaymentDialog}
                                handleClose={() => {
                                    this.setState({
                                            ...this.state,
                                            shouldOpenPaymentDialog: false
                                        }
                                    )}}
                                handlePaymentConfirmation={this.payDonation}
                                displaySpinner={this.state.displaySpinner}
                            />
                        )}
                    </Card>
                </div>
            )
        );
    }
}

const mapStateToProps = state => ({
    saveExtraPaymentNotice: PropTypes.func.isRequired,
    extraPaymentNotice: state.extraPaymentNotice,
    user: state.user
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            {  addExtraPaymentNotice, eraseMessage}
        )(withTranslation()(PaymentNoticeDetails)))
);
