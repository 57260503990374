class UserSummary {
    constructor(data) {
        if (data) {
            this.userId = data.userId;
            this.userEmail = data.userEmail;
            this.userFullName = data.userFullName;
            this.userClub = data.userClub;
        }
    }
}

export default UserSummary;
