import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class competitionRepository {

    /**
     * Get competitions
     * @returns {Promise}
     */
    static getCompetitions(start, end, sportId, teamId, athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/competition?start=${helperService.formatDate(start)}` +
            `&end=${helperService.formatDate(end)}${sportId != null ? '&sportId=' + sportId : ''}${teamId != null ? '&teamId=' + teamId : ''}` +
            `${athleteId != null ? '&athleteId=' + athleteId : ''}`
        );
    }

    /**
     * Get competition by id
     * @returns {Promise}
     * @param id
     */
    static getCompetitionById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/competition/${id}`
        );
    }

    /**
     * Get next competition
     * @returns {Promise}
     */
    static getNextCompetition(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/competition/next/${helperService.serialize(params)}`
        );
    }

    /**
     * Get monthly average grade
     * @returns {Promise}
     */
    static getMonthlyAverageGradeForCompetitions(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/competition/average-grade/${helperService.serialize(params)}`
        );
    }

    /**
     * Update competition
     * @returns {Promise}
     */
    static updateCompetition(competition) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/competition/`, {
            method: 'PUT',
            body: JSON.stringify(competition)
        });
    }

    /**
     * Delete competition
     * @returns {Promise}
     */
    static deleteCompetition(competition) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/competition/`, {
            method: 'DELETE',
            body: JSON.stringify(competition)
        });
    }

    /**
     * Update competition properties
     * @returns {Promise}
     */
    static updateCompetitionProperties(competitionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.competition}/update`, {
            method: 'PATCH',
            body: JSON.stringify(competitionRequest)
        });
    }

}
