import {userUtilsService} from "../../shared/services/userUtilsService";
import PdfReader from "../athleteFile/observations/PdfReader";
import {EgretLoadable} from "../../../egret";


const AthleteRegisterFiles = EgretLoadable({
    loader: () => import("./AthleteRegisterFiles")
});

const AthleteRegisterFileDetails = EgretLoadable({
    loader: () => import("./AthleteRegisterFileDetails")
});


const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const athleteRegisterFilesRoutes = [
    {
        path: "/officeTasks/athletes",
        exact: true,
        component: AthleteRegisterFiles,
        auth: userUtilsService.getAllClubRoles()
    },
    {
        path: "/officeTasks/athlete/:id",
        exact: true,
        component: AthleteRegisterFileDetails,
        auth: userUtilsService.getAllClubRoles()
    },
    // {
    //     path: "/pdf/:id",
    //     component: PdfReader,
    //     auth: userUtilsService.getAllClubRoles(),
    //     settings
    // }
];

export default athleteRegisterFilesRoutes;
