import Auditable from "../core/Auditable";

class AnnualFederationVisa extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.startDate = new Date(data.startDate);
            this.endDate = new Date(data.endDate);
            this.authorizedClub = data.authorizedClub;
            this.annualFederationVisaStatus = data.annualFederationVisaStatus;
        }
    }
}

export default AnnualFederationVisa;

