import {parentRepositoryMobile} from "../repositories/parentRepositoryMobile";

class ParentServiceMobile {

    getParentByAccessUserId = (id) => {
        return parentRepositoryMobile.getParentByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new ParentServiceMobile();
