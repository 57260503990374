import React, {Component} from "react";
import {Button, Card, Icon, TextField, withStyles} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import ScrollBar from "react-perfect-scrollbar";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {EUserType} from "../enums/EUserType";

const styles = () => ({
    scrollableList: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: '1',
        overflowY: 'auto',
        height: '120px',
        content: {
            position: 'relative'
        }
    }
});

class MultipleSelectList extends Component {

    state = {
        filteredValues: [],
        searchedValue: ''
    };

    componentDidMount() {
        this.setState({
            filteredValues: this.props.list
        });
    }

    isDisplayed = item => {
        const filteredId = this.state.filteredValues.map((item) => item.id);
        return filteredId.includes(item.id);
    };


    filterValues = event => {
        const {searchByField} = this.props;
        this.props.deselectValues();
        this.setState({
            searchedValue: event.target.value,
        });
        let filteredValues = [];
        if (event.target.value !== '') {
            this.props.list.forEach((item) => {
                if (this.getItemValue(item, searchByField).toLowerCase().includes(event.target.value.toLowerCase())) {
                    filteredValues.push(item);
                }
            });
        } else {
            filteredValues = [...this.props.list]
        }
        this.setState({
            filteredValues: filteredValues
        });
    };

    getItemValue = (item,field)  => {
        if (field === 'fullName') {
            return (item['lastName'] || '') + ' ' + (item['firstName'] || '');
        }
        if (field === 'teams') {
            return item.teams && item.teams.length > 0 ? item.teams[0].code : '';
        }
        if (!field.includes('.')){
            return item[field];
        } else {
            const fields = field.split('.');
            let value = item;
            fields.forEach((field) => {
                value = value[field];
            });
            return value;
        }
    };

    render () {
        const {title, buttonCaption, searchPlaceholder, searchedValue, list, selectedValues, label, small, emptyMessage, history, role, classes} = this.props;
        const cardHeight = list.length > 0 ?  240 : 150;
        return (
            <Card elevation={3} style={{height: cardHeight, innerHeight: '35vw'}} className="position-relative p-16 mb-16">
                <h5 className="m-0 mb-16">{title}</h5>
                {(list.length !== 0) ? (
                    <TextField
                        className="mb-8"
                        variant="outlined"
                        placeholder={searchPlaceholder}
                        name="searchedAthlete"
                        value={searchedValue}
                        onChange={this.filterValues}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize="small">search</Icon>
                                </InputAdornment>
                            )
                        }}
                        inputProps={{
                            style: {
                                padding: "10px"
                            }
                        }}
                        fullWidth
                    />
                ) : (
                    <div>
                        <div className="flex flex-middle py-8">
                            <div className="font-weight-500 text-error text-muted">{emptyMessage}</div>
                        </div>
                        {(role !== EUserType.parent) && history && (
                        <div className="mb-sm-16">
                            <Button
                                className="mb-16"
                                variant="contained"
                                color="secondary"
                                onClick={() => history.push(`/team/add`)}
                            >
                                {buttonCaption}
                            </Button>
                        </div>
                        )}
                    </div>

                )}

                <ScrollBar className={classes.scrollableList}>
                    {list.map(item => (
                        <div className="content" key={item.id}>
                            <div
                                style={{display: this.isDisplayed(item) ? '' : 'none' }}
                                className="flex flex-middle flex-space-between"
                            >
                                <FormControlLabel
                                    className="flex-grow-1"
                                    name={item.id}
                                    onChange={this.props.handleValueChange}
                                    control={<Checkbox checked={selectedValues.includes(item.id)} />}
                                    label={this.getItemValue(item,label)}
                                />
                                <small className="badge bg-light-primary text-primary mr-16">
                                    {this.getItemValue(item,small)}
                                </small>
                            </div>
                        </div>
                    ))}
                </ScrollBar>
            </Card>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    MultipleSelectList
);
