import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class extraNotificationRepository {

    /**
     * Add extra notification file
     * @returns {Promise}
     * @param file
     * @param clubId
     */
    static addExtraNotificationFile (file) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraNotification/file`, {
            method: 'POST',
            body: formData
        }, true);
    };
    static addExtraNotification (extraNotification) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraNotification`, {
            method: 'POST',
            body: JSON.stringify(extraNotification)
        });
    };


    /**
     * Delete extraNotification
     * @returns {Promise}
     */
    static deleteExtraNotification(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraNotification?id=` + id, {
            method: 'DELETE'
        });
    }
    /**
     * Delete extraNotifications
     * @returns {Promise}
     */
    static deleteExtraNotifications(idList) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraNotification/data`, {
            method: 'DELETE',
            body: JSON.stringify(idList)
        });
    }

    /**
     * Get extraNotifications
     * @returns {Promise}
     * @param query
     */
    static getExtraNotifications(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/extraNotification/${helperService.serialize(query)}`
        );
    }

    /**
     * Update extraNotification
     * @returns {Promise}
     */
    static updateExtraNotification(extraNotification) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/extraNotification`, {
            method: 'PATCH',
            body: JSON.stringify(extraNotification)
        });
    }

}
