export const authRoles = {
  parent: 'ROLE_PARENT',
  athlete: 'ROLE_ATHLETE',
  coach: 'ROLE_COACH',
  teamManager: 'ROLE_TEAMMANAGER',
  doctor: 'ROLE_DOCTOR',
  physicalTrainer: 'ROLE_PHYSICALTRAINER',
  mainCoach: 'ROLE_MAINCOACH',
  competitionManager: 'ROLE_COMPETITIONMANAGER',
  clubManager: 'ROLE_CLUBMANAGER',
  moderator: 'ROLE_MODERATOR',
  admin: 'ROLE_ADMIN'
};

