import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: true,
        preload: ['en', 'ro'],

        lng: 'ro',
        fallbackLng: 'ro',
        whitelist: ['en', 'ro'],

        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
        }

    });

export default i18n;
