import authService from "../../shared/services/authService";
import history from "../../../history.js";
import i18n from "../../../i18n";
import {EResponseCodes} from "../../shared/enums/ERespondeCodes";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function registerNewUser({ username, password, token }) {
    return dispatch => {
        dispatch({
            type: REGISTER_LOADING
        });

        const userProperties = {
            username: username,
            password: password,
            token: token
        };

        authService
            .register(userProperties)
            .then((res) => {

                history.push({
                    pathname: "/session/signin"
                });

                return dispatch({
                    type: REGISTER_SUCCESS,
                    data: i18n.t(EResponseCodes[res.message])
                });
            })
            .catch(error => {

                if (error.message === "NOT_PENDING_USER") {
                    history.push({
                        pathname: "/session/signin"
                    });
                }

                return dispatch({
                    type: REGISTER_ERROR,
                    data: i18n.t(EResponseCodes[error.message])
                });
            });
    };
}

export function eraseRegisterMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
