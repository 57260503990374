import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class adminRepository {

    /**
     * Get admin by id
     * @returns {Promise}
     * @param id
     */
    static getAdminById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/admin/${id}`
        );
    }

    /**
     * Get admin by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getAdminByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/admin/access-user/${id}`
        );
    }

    /**
     * Update admin
     * @returns {Promise}
     */
    static updateAdmin(admin) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/admin/`, {
            method: 'PUT',
            body: JSON.stringify(admin)
        });
    }

}
