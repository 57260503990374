import {testResultsRepository} from "../repositories/testResultsRepository";

class TestResultsService {

    getTestResults = (query) => {
        return testResultsRepository.getTestResults(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addTestResult = (testResult, clubId) => {
        return testResultsRepository.addTestResult(testResult, clubId);
    };

    countTestResults = (query) => {
        const countMap = Object.assign({}, query);
        delete countMap["pagination"];
        return testResultsRepository.countTestResults(countMap)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTestResultById = (accessUserId) => {
        return testResultsRepository.getTestResultById(accessUserId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new TestResultsService();
