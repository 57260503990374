import teamService from "../../shared/services/teamService";
import i18n from "../../../i18n";
import {ETeamResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const GET_TEAM_LIST_TIMESTAMP = "GET_TEAM_LIST_TIMESTAMP";
export const GET_TEAM_COUNT = "GET_TEAM_COUNT";
export const GET_TEAM_COUNT_TIMESTAMP = "GET_TEAM_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const TEAM_LOADING = "TEAM_LOADING";
export const TEAM_NOT_LOADING = "TEAM_NOT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getTeams(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_TEAM_LIST_TIMESTAMP,
            data: currentTime
        });
        return teamService
            .getTeams(query)
            .then(( data ) => {
                dispatch({
                    type: GET_TEAM_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getTeamsCount(countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_TEAM_COUNT_TIMESTAMP,
            data: currentTime
        });
        return teamService
            .getTeamsCount(countMap)
            .then(( data ) => {
                dispatch({
                    type: GET_TEAM_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateTeam(team) {
    return dispatch => {
        dispatch({
            type: TEAM_LOADING
        });
        return teamService
            .updateTeam(team)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ETeamResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETeamResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addTeam(team) {
    return dispatch => {
        dispatch({
            type: TEAM_LOADING
        });
        return teamService
            .addTeam(team)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ETeamResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETeamResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function updateTeamProperties(teamRequest, successMessage) {
    return dispatch => {
        dispatch({
            type: TEAM_LOADING
        });
        return teamService
            .updateTeamProperties(teamRequest)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: successMessage ? successMessage : i18n.t(ETeamResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETeamResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}


export function addModifyLogo(file, teamId) {
    return dispatch => {
        dispatch({
            type: TEAM_LOADING
        });
        teamService
            .addModifyLogo(file, teamId)
            .then(() => {
                return dispatch({
                    type: TEAM_NOT_LOADING
                });
            })
            .catch(error => {
                return dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETeamResponseCodes[error.message])
                });
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
