import {athleteRegisterFileRepository} from "../repositories/athleteRegisterFileRepository";
import AthleteRegisterFile from "../models/club/AthleteRegisterFile";
import {athleteFileRepository} from "../repositories/athleteFileRepository";

class AthleteRegisterFileService {
    getAthleteRegisterFiles = (query) => {
        return athleteRegisterFileRepository.getAthleteRegisterFiles(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new AthleteRegisterFile(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteRegisterFileById = (id) => {
        return athleteRegisterFileRepository.getAthleteRegisterFileById(id)
            .then((res) => {
                if (res) {
                    return new AthleteRegisterFile(res);
                }
                return null;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteRegisterFileByAthleteId = (athleteId) => {
        return athleteRegisterFileRepository.getAthleteRegisterFileByAthleteId(athleteId)
            .then((res) => {
                if (res) {
                    return new AthleteRegisterFile(res);
                }
                return null;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteRegisterFilesCount = (query) => {
        return athleteRegisterFileRepository.getAthleteRegisterFilesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateAthleteRegisterFile = (athleteRegisterFile) => {
        return athleteRegisterFileRepository.updateAthleteRegisterFile(athleteRegisterFile);
    };

}

export default new AthleteRegisterFileService();