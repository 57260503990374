import UserManagementService from "../../shared/services/umService";
import history from "../../../history.js";
import i18n from "../../../i18n";
import {EResponseCodes} from "../../shared/enums/ERespondeCodes";

export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function forgotPassword({ password, token }) {
    return dispatch => {
        dispatch({
            type: RESET_PASSWORD_LOADING
        });

        UserManagementService.forgotPassword({
            "password": password,
            "token": token
        }).then((res) => {
            history.push({
                pathname: "/session/signin"
            });

            return dispatch({
                type: RESET_PASSWORD_SUCCESS,
                data: i18n.t(EResponseCodes[res.message])
            });
        }).catch(error => {
            return dispatch({
                type: RESET_PASSWORD_ERROR,
                data: i18n.t(EResponseCodes[error.message])
            });
        });
    };
}

export function changePassword({ email, password, oldPassword }) {
    return dispatch => {
        dispatch({
            type: RESET_PASSWORD_LOADING
        });

        UserManagementService.changePassword({email, password, oldPassword})
            .then((res) => {
                return dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    data:i18n.t(EResponseCodes[res.message])
            });
            }).catch(error => {
                return dispatch({
                    type: RESET_PASSWORD_ERROR,
                    data: i18n.t(EResponseCodes[error.message])
                });
            });
    };
}

export function eraseResetPasswordMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
