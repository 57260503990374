import competitionService from "../../shared/services/competitionService";
import i18n from "../../../i18n";
import {ECompetitionResponseCodes} from "../../shared/enums/ERespondeCodes";

export const GET_COMPETITION_LIST = "GET_COMPETITION_LIST";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const COMPETITION_LOADING = "COMPETITION_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getCompetitions(start, end, sportId, teamId, athleteId) {
    return dispatch => {
        return competitionService
            .getCompetitions(start, end, sportId, teamId, athleteId)
            .then(( data ) => {
                dispatch({
                    type: GET_COMPETITION_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}

export function updateCompetitionProperties(competitionProperties, customSuccessMessage) {
    return dispatch => {
        dispatch({
            type: COMPETITION_LOADING
        });
        return competitionService
            .updateCompetitionProperties(competitionProperties)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: customSuccessMessage ? customSuccessMessage : i18n.t(ECompetitionResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ECompetitionResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseCompetitionMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}

export function initializeCompetitionList() {
    return dispatch => {
        dispatch({
            type: GET_COMPETITION_LIST,
            data: []
        });
    };
}
