import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class campRepository {

    /**
     * Get camps
     * @returns {Promise}
     */
    static getCamps(start, end, sportId, teamId, athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/camp?start=${helperService.formatDate(start)}&end=${helperService.formatDate(end)}` +
            `${sportId != null ? '&sportId=' + sportId : ''}${teamId != null ? '&teamId=' + teamId : ''}${athleteId != null ? '&athleteId=' + athleteId : ''}`
        );
    }

    /**
     * Get camp by id
     * @returns {Promise}
     * @param id
     */
    static getCampById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/camp/${id}`
        );
    }

    /**
     * Get next camp
     * @returns {Promise}
     */
    static getNextCamp(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/camp/next/${helperService.serialize(params)}`
        );
    }

    /**
     * Get monthly average grade
     * @returns {Promise}
     */
    static getMonthlyAverageGradeForCamps(params) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/camp/average-grade/${helperService.serialize(params)}`
        );
    }

    /**
     * Update camp
     * @returns {Promise}
     */
    static updateCamp(camp) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/camp/`, {
            method: 'PUT',
            body: JSON.stringify(camp)
        });
    }

    /**
     * Delete camp
     * @returns {Promise}
     */
    static deleteCamp(camp) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/camp/`, {
            method: 'DELETE',
            body: JSON.stringify(camp)
        });
    }

    /**
     * Update camp properties
     * @returns {Promise}
     */
    static updateCampProperties(campRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.camp}/update`, {
            method: 'PATCH',
            body: JSON.stringify(campRequest)
        });
    }

}
