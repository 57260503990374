import Payment from "../core/Payment";

class ClubPayment extends Payment{
  constructor(data) {
    super(data);
    if(data) {
      this.invoice = data.invoice;
      this.clubSubscription = data.clubSubscription;
      this.noInvoice = data.noInvoice;
      this.futurePayment = data.futurePayment;
      this.subscriptionName = data.subscriptionName;
      this.dateStart = data.dateStart;
      this.dateStop = data.dateStop;
      this.um = data.um;
      this.qty = data.qty;
    }
  }
}

export default ClubPayment;
