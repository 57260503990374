import {athletePaymentRepository} from "../repositories/athletePaymentRepository";
import AthletePayment from "../models/club/AthletePayment";
import {eventPaymentRepository} from "../repositories/eventPaymentRepository";

class AthletePaymentService {

    getAthletePayments = (query) => {
        return athletePaymentRepository.getAthletePayments(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthletePaymentById = (id) => {
        return athletePaymentRepository.getAthletePaymentById(id)
            .then((res) => {
                return new AthletePayment(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addAthletePayment = (athletePayment) => {
        return athletePaymentRepository.addAthletePayment(athletePayment);
    };

    updateAthletePayment = (athletePayment) => {
        return athletePaymentRepository.updateAthletePayment(athletePayment);
    };

    cancelAthletePayment = (athletePaymentId) => {
        return athletePaymentRepository.cancelAthletePayment(athletePaymentId);
    };

    deleteAthletePayment = (athletePayment) => {
        return athletePaymentRepository.deleteAthletePayment(athletePayment);
    };

    getAthletePaymentsCount = (query) => {
        return athletePaymentRepository.getAthletePaymentsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getAthletePaymentsTotalAmount = (query) => {
        return athletePaymentRepository.getAthletePaymentsTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadAthletePaymentReceipt = (athletePaymentId) => {
        return athletePaymentRepository.downloadAthletePaymentReceipt(athletePaymentId);
    };

    isCurrentMonthPaid = (athleteId) => {
        return athletePaymentRepository.isCurrentMonthPaid(athleteId);
    };

    getDebtorsSituation = (teamId, type) => {
        return athletePaymentRepository.getDebtorsSituation(teamId, type);
    };

    getMonthlyDebtorsPerTeam = (year, sportId, type) => {
        return athletePaymentRepository.getMonthlyDebtorsPerTeam(year, sportId, type);
    };

    getMonthlyGrossPerTeam = (year, sportId, type) => {
        return athletePaymentRepository.getMonthlyGrossPerTeam(year, sportId, type);
    };

}

export default new AthletePaymentService();
