import {
  ERASE_MESSAGE, GET_EVENT_EXPENSES_LIST, GET_EVENT_EXPENSES_LIST_TIMESTAMP, GET_EVENT_EXPENSES_COUNT,
  GET_EVENT_EXPENSES_COUNT_TIMESTAMP, EVENT_EXPENSES_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/EventExpensesActions";

const initialState = {
  eventExpensesList: [],
  loading: false,
  success: null,
  error: null,
  getEventExpensesTimestamp: null,
  count: 0,
  getEventExpensesCountTimestamp: null
};

const EventExpensesReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_EXPENSES_LIST_TIMESTAMP: {
      return {
        ...state,
        getEventExpensesTimestamp: action.data
      };
    }
    case GET_EVENT_EXPENSES_LIST: {
      if (action.data.time === state.getEventExpensesTimestamp) {
        return {
          ...state,
          eventExpensesList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_EVENT_EXPENSES_COUNT_TIMESTAMP: {
      return {
        ...state,
        getEventExpensesCountTimestamp: action.data
      };
    }
    case GET_EVENT_EXPENSES_COUNT: {
      if (action.data.time === state.getEventExpensesCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case EVENT_EXPENSES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default EventExpensesReducer;
