import Auditable from "./Auditable";

class Payment extends Auditable{
    constructor(data) {
        super(data);
        if (data) {
            this.id = data.id;
            this.club = data.club;
            this.paymentType = data.paymentType;
            this.date = data.date;
            this.amount = data.amount;
            this.currency = data.currency;
            this.docNumber = data.docNumber;
            this.docDetails = data.docDetails;
            this.receiptNumber = data.receiptNumber;
        }
    }
}

export default Payment;
