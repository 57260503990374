import teamServiceMobile from "../../shared/services/teamServiceMobile";
import {GET_TEAM_LIST_TIMESTAMP, GET_TEAM_LIST} from "./TeamActions";

export function getTeams(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_TEAM_LIST_TIMESTAMP,
            data: currentTime
        });
        return teamServiceMobile
            .getTeams(query)
            .then(( data ) => {
                dispatch({
                    type: GET_TEAM_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}
