import {sportRepository} from "../repositories/sportRepository";

class SportService {

    getSports = (query) => {
        return sportRepository.getSports(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSportById = (id) => {
        return sportRepository.getSportById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addSport = (sport) => {
        return sportRepository.addSport(sport);
    };

    updateSport = (sport) => {
        return sportRepository.updateSport(sport);
    };

    deleteSport = (sport) => {
        return sportRepository.deleteSport(sport);
    };

}

export default new SportService();
