import {campRepository} from "../repositories/campRepository";
import Camp from "../models/club/Camp";
import MonthlyAverageGradeResponse from "../models/club/MonthlyAverageGradeResponse";

class CampService {

    getCamps = (start, end, sportId, teamId, athleteId) => {
        return campRepository.getCamps(start, end, sportId, teamId, athleteId)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new Camp(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getNextCamp = (params) => {
        return campRepository.getNextCamp(params)
            .then((res) => {
                if (res) {
                    return new Camp(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getMonthlyAverageGradeForCamps = (params) => {
        return campRepository.getMonthlyAverageGradeForCamps(params)
            .then((res) => {
                if (res) {
                    return new MonthlyAverageGradeResponse(res);
                }
                return Promise.reject();
            }).catch((err) => {
                return Promise.reject(err);
            });
    };

    getCampById = (id) => {
        return campRepository.getCampById(id)
            .then((res) => {
                return new Camp(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateCamp = (camp) => {
        return campRepository.updateCamp(camp);
    };

    deleteCamp = (camp) => {
        return campRepository.deleteCamp(camp);
    };

    updateCampProperties = (campRequest) => {
        return campRepository.updateCampProperties(campRequest);
    };

}

export default new CampService();
