import Auditable from "../core/Auditable";

class AthleteRegisterFile extends Auditable {
    id = null;
    athlete= null;
    unreadObservationsCount = null;
    parents = [];
    contactInfoList = [];
    addressInfo = {};


    constructor(data) {
        super(data);
        this.id = data.id;
        this.athlete = data.athlete;
        this.unreadObservationsCount = data.unreadObservationsCount;
        this.parents = data.parents;
        this.contactInfoList = data.contactInfoList;
        this.lastEditStatus = data.lastEditStatus;
        this.addressInfo = data.addressInfo;
    }
}

export default AthleteRegisterFile;
