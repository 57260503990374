const BASE_URL = 'https://expertsportclub.com';
// const BASE_URL = 'http://localhost:8080';

export const APP_CONFIG = {
    URL: {
        auth: `${BASE_URL}/api/auth`,
        root: `${BASE_URL}/api/root`,
        club: `${BASE_URL}/api/club`,
        um: `${BASE_URL}/api/um`,
        email: `${BASE_URL}/api/email`,
        training: `${BASE_URL}/api/training`,
        competition: `${BASE_URL}/api/competition`,
        camp: `${BASE_URL}/api/camp`,
        payment: `${BASE_URL}/api/payment`,
        athlete: `${BASE_URL}/api/athlete`
    }
};
