import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class sportsArenaRepository {

    /**
     * Get sports arena
     * @returns {Promise}
     * @param query
     */
    static getSportsArena(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/sportsArena/${helperService.serialize(query)}`
        );
    }

    /**
     * Get sports arena by id
     * @returns {Promise}
     * @param id
     */
    static getSportsArenaById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/sportsArena/${id}`
        );
    }


    /**
     * Add new sports arena
     * @returns {Promise}
     */
    static addSportsArena(sportsArena) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/sportsArena/`, {
            method: 'POST',
            body: JSON.stringify(sportsArena)
        });
    }

    /**
     * Update sports arena
     * @returns {Promise}
     */
    static updateSportsArena(sportsArena) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/sportsArena/`, {
            method: 'PUT',
            body: JSON.stringify(sportsArena)
        });
    }

    /**
     * Delete sports arena
     * @returns {Promise}
     */
    static deleteSportsArena(sportsArena) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/sportsArena/`, {
            method: 'DELETE',
            body: JSON.stringify(sportsArena)
        });
    }

    /**
     * Check sports arena for delete
     * @returns {Promise}
     */
    static checkForEvents(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/sportsArena/checkForEvents?id=${id}`
        );
    }

}
