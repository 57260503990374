import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class parentRepositoryMobile {

    /**
     * Get parent entity by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getParentByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/parent/access-user/mobile/${id}`, {}, false
        );
    }

}
