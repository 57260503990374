import React, { Component } from "react";

class OnlinePaymentsTutorial extends Component {

    constructor(props) {
        super(props);
        this.state = {height: props.height};
    }

    componentWillMount(){
        this.setState({height: window.innerHeight + 'px'});
    }

    render() {
        return (
            <iframe src="https://escdataprod.blob.core.windows.net/public/ro/Tutorial PLATI ONLINE RO.pdf" width="100%" style={ { height: `${ this.state.height }` } } allowFullScreen={true}/>
        );
    }
}

export default OnlinePaymentsTutorial;
