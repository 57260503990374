export const EClubStatus = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    CANCELED: 'CANCELED',
    DEMO: 'DEMO'
};

export const EClubStatusRo = {
    ACTIVE: 'Activ',
    PAUSED: 'Suspendat',
    CANCELED: 'Anulat',
    DEMO: 'Demo'
};

export const EClubStatusTranslated = {
    ACTIVE: 'status.active',
    PAUSED: 'status.paused',
    CANCELED: 'status.canceled',
    DEMO: 'status.demo'
};

export const PAYMENT_DAYS_LIMIT=20;
