import {
  GET_PLAYER_LIST,
} from "../actions/PlayerActions";

const initialState = {
  playerList: null
};

const PlayerReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER_LIST: {
      return {
        ...state,
        playerList: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default PlayerReducer;
