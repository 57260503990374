import {
  ERASE_MESSAGE,
  GET_EXTRAPAYMENTNOTICES_LIST,
  GET_EXTRAPAYMENTNOTICES_LIST_TIMESTAMP,
  EXTRAPAYMENTNOTICES_LOADING,
  SAVE_ERROR,
  SAVE_SUCCESS,
  GET_EXTRAPAYMENTNOTICES_COUNT_TIMESTAMP, GET_EXTRAPAYMENTNOTICES_COUNT
} from "../actions/ExtraPaymentNoticeActions";

const initialState = {
  extraPaymentNoticeList: [],
  loading: false,
  success: null,
  error: null,
  getExtraPaymentNoticeTimestamp: null,
  count: 0,
  getExtraPaymentNoticeCountTimestamp: null
};

const ExtraPaymentNoticeReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXTRAPAYMENTNOTICES_LIST_TIMESTAMP: {
      return {
        ...state,
        getExtraPaymentNoticeTimestamp: action.data
      };
    }
    case GET_EXTRAPAYMENTNOTICES_LIST: {
      if (action.data.time === state.getExtraPaymentNoticeTimestamp) {
        return {
          ...state,
          extraPaymentNoticeList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_EXTRAPAYMENTNOTICES_COUNT_TIMESTAMP: {
      return {
        ...state,
        getExtraPaymentNoticeCountTimestamp: action.data
      };
    }
    case GET_EXTRAPAYMENTNOTICES_COUNT: {
      if (action.data.time === state.getExtraPaymentNoticeCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case EXTRAPAYMENTNOTICES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default ExtraPaymentNoticeReducer;
