import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

class UserManagementService {
    forgotPassword = async ({token, password}) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.um}/forgot-password`, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token, password})
        });
    };

    changePassword = async ({email, password, oldPassword}) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.um}/change-password`, {
            method: 'POST',
            body: JSON.stringify({email, password, oldPassword})
        });
    };

    activateUserManually = async (userIdToActivate) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.um}/manual-activation`, {
            method: 'POST',
            body: JSON.stringify({"userIdToActivate": userIdToActivate})
        });
    };
}

export default new UserManagementService();
