import Auditable from "../core/Auditable";
import MedicalVisa from "./MedicalVisa";
import BiometricData from "./BiometricData";
import GameEquipment from "./GameEquipment";
import Athlete from "./Athlete";
import HealthCondition from "./HealthCondition";
import DisciplinarySituation from "./DisciplinarySituation";
import AnnualFederationVisa from "./AnnualFederationVisa";
import CardSituation from "./CardSituation";

class AthleteFile extends Auditable {
    id = null;
    athlete= null;
    biometricDataHistory = [];
    medicalVisaHistory = [];
    gameEquipments = [];
    lastEditStatus = null;
    unreadObservationsCount = null;

    constructor(data) {
        super(data);
        this.id = data.id;
        this.athlete = data.athlete;
        if (data.biometricDataHistory && Array.isArray(data.biometricDataHistory)) {
            this.biometricDataHistory = data.biometricDataHistory.map(el => new BiometricData(el));
        }
        if (data.medicalVisaHistory && Array.isArray(data.medicalVisaHistory)) {
            this.medicalVisaHistory = data.medicalVisaHistory.map(el => new MedicalVisa(el));
        }
        if (data.healthConditionHistory && Array.isArray(data.healthConditionHistory)) {
            this.healthConditionHistory = data.healthConditionHistory.map(el => new HealthCondition(el));
        }
        if (data.disciplinarySituationHistory && Array.isArray(data.disciplinarySituationHistory)) {
            this.disciplinarySituationHistory = data.disciplinarySituationHistory.map(el => new DisciplinarySituation(el));
        }
        if (data.cardSituationHistory && Array.isArray(data.cardSituationHistory)) {
            this.cardSituationHistory = data.cardSituationHistory.map(el => new CardSituation(el));
        }
        if (data.annualFederationVisaHistory && Array.isArray(data.annualFederationVisaHistory)) {
            this.annualFederationVisaHistory = data.annualFederationVisaHistory.map(el => new AnnualFederationVisa(el));
        }
        if (data.gameEquipments && Array.isArray(data.gameEquipments)) {
            this.gameEquipments = data.gameEquipments.map(el => new GameEquipment(el));
        }
        this.lastEditStatus = data.lastEditStatus;
        this.unreadObservationsCount = data.unreadObservationsCount;
    }
}

export default AthleteFile;
