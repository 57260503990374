import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import DocumentPdfReader from "./DocumentPdfReader";
import Documents from "./Documents";

const Document = EgretLoadable({
    loader: () => import("./Documents")
});

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const documentsRoutes = [
    {
        path: "/office/documents",
        exact: true,
        component: Documents,
        auth: userUtilsService.getAllClubRoles()
    },
    {
        path: "/office/documents/pdf/:id",
        component: DocumentPdfReader,
        settings
    }
];

export default documentsRoutes;
