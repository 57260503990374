import {
  ERASE_MESSAGE, GET_INVOICE_LIST, GET_INVOICES_COUNT, GET_INVOICES_COUNT_TIMESTAMP,
  INVOICE_LOADING, GET_INVOICE_LIST_TIMESTAMP
} from "../actions/InvoiceActions";

const initialState = {
  invoiceList: [],
  count: 0,
  loading: false,
  success: null,
  error: null,
  getInvoicesTimestamp: null,
  getInvoicesCountTimestamp: null
};

const InvoiceReducer = function(state = initialState, action) {
  switch (action.type) {
      case GET_INVOICE_LIST_TIMESTAMP: {
          return {
              ...state,
              getInvoicesTimestamp: action.data
          };
      }
    case GET_INVOICE_LIST: {
     if (action.data.time === state.getInvoicesTimestamp) {
            return {
              ...state,
              invoiceList: action.data.list
            };
          } else {
            return state;
          }
        }
      case GET_INVOICES_COUNT_TIMESTAMP: {
          return {
              ...state,
              getInvoicesCountTimestamp: action.data
          };
      }
      case GET_INVOICES_COUNT: {
          if (action.data.time === state.getInvoicesCountTimestamp) {
              return {
                  ...state,
                  count: action.data.count
              };
          } else {
              return state;
          }
      }
    case INVOICE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }

    default: {
      return state;
    }
  }
};

export default InvoiceReducer;
