import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import React, {Component} from "react";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    alert: {
        "& .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action":{
            color: '#fff'
        }
    }
});


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CustomizedSnackbar extends Component {

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.onClose();
    };

    render() {
        const {message, classes, severity} = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={message != null}
                autoHideDuration={10000}
                onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={severity} className={classes.alert}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}

export default withStyles(styles, { withTheme: true })(CustomizedSnackbar);
