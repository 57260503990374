import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const AthletePayments = EgretLoadable({
  loader: () => import("./AthletePayments")
});

const AthletePaymentDetails = EgretLoadable({
  loader: () => import("./AthletePaymentDetails")
});

const paymentRoutes = [
  {
    path: "/payment/list",
    exact: true,
    component: AthletePayments,
    auth: userUtilsService.getClubInviteRoles()

},
  {
    path: "/payment/:id",
    component: AthletePaymentDetails,
    auth: userUtilsService.getClubInviteRoles()
  }
];

export default paymentRoutes;
