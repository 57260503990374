import trainingEventService from "../../shared/services/trainingEventService";
import i18n from "../../../i18n";
import {EDaysOfWeek} from "../../shared/enums/EDaysOfWeek";
import {ETrainingEventResponseCodes} from "../../shared/enums/ERespondeCodes";
import trainingService from "../../shared/services/trainingService";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const TRAINING_EVENT_LOADING = "TRAINING_EVENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function updateTrainingEvent(trainingEvent) {
    return dispatch => {
        dispatch({
            type: TRAINING_EVENT_LOADING
        });
        return trainingEventService
            .updateTrainingEvent(trainingEvent)
            .then(res => {
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ETrainingEventResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addTrainingEvent(trainingEvent) {
    return dispatch => {
        dispatch({
            type: TRAINING_EVENT_LOADING
        });

        if (!trainingEvent.team){
            dispatch({
                type: SAVE_ERROR,
                data: i18n.t('calendar.error.team.s')
            });
            return Promise.reject();
        }

        return trainingEventService
            .addTrainingEvent(trainingEvent)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ETrainingEventResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                if (error.status === 400 && error.errors && error.errors.length !== 0 ) {
                    dispatch({
                        type: SAVE_ERROR,
                        data: error.errors[0]['defaultMessage']
                    });
                } else {
                    dispatch({
                        type: SAVE_ERROR,
                        data: i18n.t(ETrainingEventResponseCodes[error.message])
                    });
                }
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
