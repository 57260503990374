import {
  ERASE_MESSAGE, GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_LIST_TIMESTAMP, GET_SUBSCRIPTIONS_COUNT_TIMESTAMP,
  GET_SUBSCRIPTIONS_COUNT, SUBSCRIPTION_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/SubscriptionActions";

const initialState = {
  subscriptionList: [],
  loading: false,
  success: null,
  error: null,
  getSubscriptionsTimestamp: null,
  count: 0,
  getSubscriptionsCountTimestamp: null
};

const SubscriptionReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST_TIMESTAMP: {
      return {
        ...state,
        getSubscriptionsTimestamp: action.data
      };
    }
    case GET_SUBSCRIPTION_LIST: {
      if (action.data.time === state.getSubscriptionsTimestamp) {
        return {
          ...state,
          subscriptionList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_SUBSCRIPTIONS_COUNT_TIMESTAMP: {
      return {
        ...state,
        getSubscriptionsCountTimestamp: action.data
      };
    }
    case GET_SUBSCRIPTIONS_COUNT: {
      if (action.data.time === state.getSubscriptionsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case SUBSCRIPTION_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default SubscriptionReducer;
