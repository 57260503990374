import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const Subscription = EgretLoadable({
  loader: () => import("./Subscription")
});


const subscriptionRoutes = [
  {
    path: "/subscription/list",
    exact: true,
    component: Subscription,
    auth: userUtilsService.getAllClubRoles()
  }
];

export default subscriptionRoutes;
