import {
  ERASE_MESSAGE, GET_EXTRAPAYMENT_LIST, GET_EXTRAPAYMENT_LIST_TIMESTAMP, GET_EXTRAPAYMENTS_COUNT,
    GET_EXTRAPAYMENTS_COUNT_TIMESTAMP, EXTRAPAYMENT_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/ExtraPaymentActions";

const initialState = {
  extraPaymentList: [],
  loading: false,
  success: null,
  error: null,
  getExtraPaymentsTimestamp: null,
  count: 0,
  getExtraPaymentsCountTimestamp: null
};

const ExtraPaymentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXTRAPAYMENT_LIST_TIMESTAMP: {
      return {
        ...state,
        getExtraPaymentsTimestamp: action.data
      };
    }
    case GET_EXTRAPAYMENT_LIST: {
      if (action.data.time === state.getExtraPaymentsTimestamp) {
        return {
          ...state,
          extraPaymentList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_EXTRAPAYMENTS_COUNT_TIMESTAMP: {
      return {
        ...state,
        getExtraPaymentsCountTimestamp: action.data
      };
    }
    case GET_EXTRAPAYMENTS_COUNT: {
      if (action.data.time === state.getExtraPaymentsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }
    case EXTRAPAYMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default ExtraPaymentReducer;
