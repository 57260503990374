import { EgretLoadable } from "../../../egret";
import {authRoles} from "../../auth/authRoles";

const ClubPayments = EgretLoadable({
  loader: () => import("./ClubPayments")
});

const ClubPaymentDetails = EgretLoadable({
  loader: () => import("./ClubPaymentDetails")
});

const clubPaymentRoutes = [
  {
    path: "/clubPayment/list",
    exact: true,
    component: ClubPayments,
    auth: [authRoles.admin]
  },
  {
    path: "/clubPayment/:id",
    component: ClubPaymentDetails,
    auth: [authRoles.admin]
  }
];

export default clubPaymentRoutes;
