import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class subscriptionTypeRepository {

    /**
     * Get subscription types
     * @returns {Promise}
     * @param query
     */
    static getSubscriptionType(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscriptionType/${helperService.serialize(query)}`
        );
    }

    /**
     * Get subscription type by id
     * @returns {Promise}
     * @param id
     */
    static getSubscriptionTypeById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscriptionType/${id}`
        );
    }

    /**
     * Add new subscription type
     * @returns {Promise}
     */
    static addSubscriptionType(subscriptionType) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/subscriptionType/`, {
            method: 'POST',
            body: JSON.stringify(subscriptionType)
        });
    }

    /**
     * Update subscription type
     * @returns {Promise}
     */
    static updateSubscriptionType(subscriptionType) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/subscriptionType/`, {
            method: 'PUT',
            body: JSON.stringify(subscriptionType)
        });
    }

    /**
     * Delete subscription type
     * @returns {Promise}
     */
    static deleteSubscriptionType(subscriptionType) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/subscriptionType/${subscriptionType.id}`, {
            method: 'DELETE'
        });
    }

    /**
     * Check if the current subscriptionType has active athletes
     * @returns {Promise}
     * @param subscriptionTypeId
     */
    static countSubscriptionTypeActiveUsers(subscriptionTypeId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/subscriptionType/subscriptionTypeActiveUsers/${subscriptionTypeId}`
        );
    }
}
