import extraPaymentService from "../../shared/services/extraPaymentService";
import history from "../../../history";
import i18n from "../../../i18n";
import {EPaymentResponseCodes} from "../../shared/enums/ERespondeCodes";
import eventPaymentService from "../../shared/services/eventPaymentService";
import {EVENT_PAYMENT_LOADING} from "./EventPaymentActions";

export const GET_EXTRAPAYMENT_LIST = "GET_EXTRAPAYMENT_LIST";
export const GET_EXTRAPAYMENT_LIST_TIMESTAMP = "GET_EXTRAPAYMENT_LIST_TIMESTAMP";
export const GET_EXTRAPAYMENTS_COUNT = "GET_EXTRAPAYMENTS_COUNT";
export const GET_EXTRAPAYMENTS_COUNT_TIMESTAMP = "GET_EXTRAPAYMENTS_COUNT_TIMESTAMP";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const EXTRAPAYMENT_LOADING = "EXTRAPAYMENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function getAthleteExtraPayments(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EXTRAPAYMENT_LIST_TIMESTAMP,
            data: currentTime
        });
        return extraPaymentService
            .getExtraPayments(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EXTRAPAYMENT_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}


export function getAthleteExtraPaymentsCount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_EXTRAPAYMENTS_COUNT_TIMESTAMP,
            data: currentTime
        });
        return extraPaymentService
            .getExtraPaymentsCount(query)
            .then(( data ) => {
                dispatch({
                    type: GET_EXTRAPAYMENTS_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}


export function addAthleteExtraPayment(athleteExtraPayment) {
    return dispatch => {
        dispatch({
            type: EXTRAPAYMENT_LOADING
        });
        return extraPaymentService
            .addExtraPayment(athleteExtraPayment)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function updateExtraPayment(athleteExtraPayment) {
    return dispatch => {
        dispatch({
            type: EXTRAPAYMENT_LOADING
        });
        return extraPaymentService
            .updateExtraPayment(athleteExtraPayment)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPaymentResponseCodes[res.message])
                });

                history.push({pathname: "/extraPayment/list"});

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPaymentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
