import { EgretLoadable } from "../../../egret";
import PdfReader from "./observations/PdfReader";
import {userUtilsService} from "../../shared/services/userUtilsService";

const AthleteFiles = EgretLoadable({
  loader: () => import("./AthleteFiles")
});

const AthleteFileDetails = EgretLoadable({
  loader: () => import("./AthleteFileDetails")
});


const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const athleteFilesRoutes = [
  {
    path: "/sportfiles/athletes",
    exact: true,
    component: AthleteFiles,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/athleteFile/athlete/:id",
    exact: true,
    component: AthleteFileDetails,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/sportfiles/athlete/:id",
    exact: true,
    component: AthleteFileDetails,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/pdf/:id",
    component: PdfReader,
    auth: userUtilsService.getAllClubRoles(),
    settings
  }
];

export default athleteFilesRoutes;
