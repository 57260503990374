import User from "../core/User";
import {helperService} from "../../services/helperService";

class Athlete extends User {
    constructor(data) {
        super(data);
        if (data.birthDate) {
            this.birthDate = helperService.formatDateMongo(data.birthDate);
        }
        if (data.enrollmentDate) {
            this.enrollmentDate = helperService.formatDateMongo(data.enrollmentDate);
        }
        this.parents = data.parents;
        this.teams = data.teams;
        this.sport = data.sport;
        this.subscriptionType = data.subscriptionType;
        this.lastPayment = data.lastPayment;
        this.courtPosition = data.courtPosition;
    }
}

export default Athlete;
