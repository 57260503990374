import {documentRepository} from "../repositories/documentRepository";
import Document from "../models/club/Document";

class DocumentService {

    addDocumentFile = (file, clubId) => {
        return documentRepository.addDocumentFile(file, clubId);
    };
    addDocumentComment = (document, clubId) => {
        return documentRepository.addDocumentComment(document, clubId);
    };

    deleteDocumentFile = (id) => {
        return documentRepository.deleteDocumentFile(id);
    };

    getDocuments = (query) => {
        return documentRepository.getDocuments(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new Document(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateDocument = (trainingPlan) => {
        return documentRepository.updateDocument(trainingPlan);
    };

}

export default new DocumentService();
