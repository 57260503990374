import feedbackService from "../../shared/services/feedbackService";
import i18n from "../../../i18n";
import {EClubResponseCodes, EResponseCodes} from "../../shared/enums/ERespondeCodes";

export const EMAIL_NOT_SENT = "EMAIL_NOT_SENT";
export const EMAIL_SENT = "EMAIL_SENT";
export const SEND_FEEDBACK = "SEND_FEEDBACK";

export function sendFeedback(userId, feedback) {
    return dispatch => {
        dispatch({
            type: SEND_FEEDBACK
        });
        return feedbackService
            .sendFeedback(userId, feedback)
            .then(res => {

                dispatch({
                    type: EMAIL_SENT,
                    data: i18n.t(EClubResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: EMAIL_NOT_SENT,
                    data: i18n.t(EResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}
