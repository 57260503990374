import {
  ERASE_MESSAGE, GET_CLUB_PAYMENT_LIST,
  CLUB_PAYMENT_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/ClubPaymentActions";

const initialState = {
  clubPaymentList: [],
  loading: false,
  success: null,
  error: null
};

const ClubPaymentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_CLUB_PAYMENT_LIST: {
      return {
        ...state,
        clubPaymentList: action.data
      };
    }
    case CLUB_PAYMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default ClubPaymentReducer;
