import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class campEventRepository {

    /**
     * Add new camp event
     * @returns {Promise}
     */
    static addCampEvent(campEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.camp}/event/`, {
            method: 'POST',
            body: JSON.stringify(campEvent)
        });
    }

    /**
     * Update camp event
     * @returns {Promise}
     */
    static updateCampEvent(campEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.camp}/event/`, {
            method: 'PUT',
            body: JSON.stringify(campEvent)
        });
    }

    /**
     * Delete camp event
     * @returns {Promise}
     */
    static deleteCampEvent(campEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.camp}/event/`, {
            method: 'DELETE',
            body: JSON.stringify(campEvent)
        });
    }

}
