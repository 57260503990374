import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import TrainingPlanPdfReader from "./TrainingPlanPdfReader";

const TrainingPlan = EgretLoadable({
  loader: () => import("./TrainingPlan")
});

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const plansAndProgramsRoutes = [
  {
    path: "/plan/program",
    exact: true,
    component: TrainingPlan,
    auth: userUtilsService.getAllClubRoles()
  },
  {
    path: "/plan/program/pdf/:id",
    component: TrainingPlanPdfReader,
    settings
  }
];

export default plansAndProgramsRoutes;
