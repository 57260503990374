import Auditable from "../core/Auditable";

class Notice extends Auditable {
    constructor(data) {
        super(data);
        if (data) {
            this.id = data.id;
            this.athlete = data.athlete;
            this.coach = data.coach;
            this.president = data.president;
            this.parent = data.parent;
            this.club = data.club;
            this.deadline = data.deadline ? new Date(data.deadline) : null;
            this.payment = data.payment;
            this.canceled = data.canceled;
            this.athleteFirstName = data.athleteFirstName;
            this.athleteLastName = data.athleteLastName;
            this.coach = data.coach;
            this.president = data.president;
            this.parent = data.parent;
        }
    }
}

export default Notice;
