import athleteFileService from "../../shared/services/athleteFileService";
import i18n from "../../../i18n";
import {EAthleteFileResponseCodes} from "../../shared/enums/ERespondeCodes";
export const GET_ATHLETE_FILE_LIST = "GET_ATHLETE_FILE_LIST";
export const GET_ATHLETE_FILE_LIST_TIMESTAMP = "GET_ATHLETE_FILE_LIST_TIMESTAMP";
export const GET_ATHLETE_FILES_COUNT= "GET_ATHLETE_FILES_COUNT";
export const GET_ATHLETE_FILES_COUNT_TIMESTAMP = "GET_ATHLETE_FILES_COUNT_TIMESTAMP";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const ATHLETE_FILE_LOADING = "ATHLETE_FILE_LOADING";

export function getAthleteFiles(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_FILE_LIST_TIMESTAMP,
            data: currentTime
        });
        return athleteFileService
            .getAthleteFiles(query)
            .then(( data ) => {
                dispatch({
                    type: GET_ATHLETE_FILE_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getAthleteFilesCount(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_ATHLETE_FILES_COUNT_TIMESTAMP,
            data: currentTime
        });
        return athleteFileService
            .getAthleteFilesCount(query)
            .then((data) => {
                dispatch({
                    type: GET_ATHLETE_FILES_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updateAthleteFile(athleteFile) {
    return dispatch => {
        dispatch({
            type: ATHLETE_FILE_LOADING
        });
        return athleteFileService
            .updateAthleteFile(athleteFile)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EAthleteFileResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EAthleteFileResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function eraseAthleteFileMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
