import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class testResultsRepository {

    /**
     * Get test results
     * @returns {Promise}
     * @param query
     */
    static getTestResults(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/testResults/${helperService.serialize(query)}`
        );
    }

    /**
     * Add test result
     * @returns {Promise}
     * @param testResult
     * @param clubId
     */
    static addTestResult(testResult, clubId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/testResults?clubId=` + clubId, {
            method: 'POST',
            body: JSON.stringify(testResult)
        });
    };

    /**
     * Count test results
     * @returns {Promise}
     * @param query
     */
    static countTestResults(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/testResults/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Get test result by id
     * @returns {Promise}
     */
    static getTestResultById(accessUserId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/testResults/byId?accessUserId=` + accessUserId
        );
    }

}
