import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class competitionEventRepository {

    /**
     * Add new competition event
     * @returns {Promise}
     */
    static addCompetitionEvent(competitionEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.competition}/event/`, {
            method: 'POST',
            body: JSON.stringify(competitionEvent)
        });
    }

    /**
     * Update competition event
     * @returns {Promise}
     */
    static updateCompetitionEvent(competitionEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.competition}/event/`, {
            method: 'PUT',
            body: JSON.stringify(competitionEvent)
        });
    }

    /**
     * Delete competition event
     * @returns {Promise}
     */
    static deleteCompetitionEvent(competitionEvent) {
        return FetchAdapter.request(`${APP_CONFIG.URL.competition}/event/`, {
            method: 'DELETE',
            body: JSON.stringify(competitionEvent)
        });
    }

}
