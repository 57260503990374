import campEventService from "../../shared/services/campEventService";
import i18n from "../../../i18n";
import {ECampEventResponseCodes} from "../../shared/enums/ERespondeCodes";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const CAMP_EVENT_LOADING = "CAMP_EVENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function updateCampEvent(campEvent) {
    return dispatch => {
        dispatch({
            type: CAMP_EVENT_LOADING
        });
        return campEventService
            .updateCampEvent(campEvent)
            .then(res => {
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(ECampEventResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function addCampEvent(campEvent) {
    return dispatch => {
        dispatch({
            type: CAMP_EVENT_LOADING
        });

        if (!campEvent.teams || campEvent.teams.length === 0){
            dispatch({
                type: SAVE_ERROR,
                data: i18n.t('calendar.error.team.p')
            });
            return Promise.reject();
        } else if (campEvent.dateStop.getTime() < campEvent.dateStart.getTime()) {
            dispatch({
                type: SAVE_ERROR,
                data: i18n.t('calendar.error.timeInterval')
            });
            return Promise.reject();
        }

        return campEventService
            .addCampEvent(campEvent)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(ECampEventResponseCodes[res.message])
                });

                return Promise.resolve();
            })
            .catch(error => {
                if (error.status === 400 && error.errors && error.errors.length !== 0 ) {
                    dispatch({
                        type: SAVE_ERROR,
                        data: error.errors[0]['defaultMessage']
                    });
                } else {
                    dispatch({
                        type: SAVE_ERROR,
                        data: i18n.t(ECampEventResponseCodes[error.message])
                    });
                }
                return Promise.reject();
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}
