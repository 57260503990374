import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class documentRepository {

    /**
     * Add document file
     * @returns {Promise}
     * @param file
     * @param clubId
     */
    static addDocumentFile (file, clubId) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/document?clubId=` + clubId, {
            method: 'POST',
            body: formData
        }, true);
    };
    static addDocumentComment (document, clubId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/document/comment?clubId=` + clubId, {
            method: 'POST',
            body: JSON.stringify(document)
        });
    };


    /**
     * Delete document
     * @returns {Promise}
     */
    static deleteDocumentFile(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/document?id=` + id, {
            method: 'DELETE'
        });
    }

    /**
     * Get documents
     * @returns {Promise}
     * @param query
     */
    static getDocuments(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/document/${helperService.serialize(query)}`
        );
    }

    /**
     * Update document
     * @returns {Promise}
     */
    static updateDocument(document) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/document`, {
            method: 'PATCH',
            body: JSON.stringify(document)
        });
    }

}
